import { palette, visualization } from 'modules/root/theme/visualization';

export const evaluationGraphColorMap = new Map([
  ['Cash', visualization.optionD.colorD],
  ['Fixed Income', visualization.optionC.colorB],
  ['Mutual Funds', visualization.optionA.colorA],
  ['Funds', visualization.optionD.colorB],
  ['Equity', visualization.optionC.colorD],
  ['ETFs', visualization.optionB.colorA],
  ['Other', visualization.optionA.colorC],
]);

export const getAssetColor = (key: string) => {
  return evaluationGraphColorMap.get(key) || palette.gray200;
};
