import React from 'react';

import clsx from 'clsx';

type Step = {
  id: string;
  label: string;
  active: boolean;
  enable: boolean;
};
interface StepperProps {
  steps: Step[];
  onChange: (id: string) => void;
  className: string;
}

export const Stepper: React.FC<StepperProps> = (props) => {
  return (
    <div className={clsx('stepper', props.className)}>
      {props.steps.map((step, index) => {
        const activeStepIndex = props.steps.findIndex((step) => step.active);
        const isPassedOrActive = index <= activeStepIndex;
        return (
          <div
            className={clsx('step-item', {
              'step-enabled': step.enable && !isPassedOrActive,
              'step-active': isPassedOrActive,
            })}
            key={step.id}
          >
            <button
              onClick={() => props.onChange(step.id)}
              className={clsx(
                'rounded-full transition duration-500 ease-in-out',
                'h-5 w-5 flex items-center justify-center p-3 text-sm text-white z-10 text-center',
                'focus:outline, focus:outline-2, focus:outline-offset-2',
                {
                  'bg-gray-500': step.enable && !isPassedOrActive,
                  'bg-primary-600': isPassedOrActive && step.enable,
                  'bg-gray-300': !step.enable,
                }
              )}
            >
              {index + 1}
            </button>
            <div
              className={clsx('top-0 text-center mt-1 text-sm whitespace-nowrap', {
                'text-gray-500': step.enable && !isPassedOrActive,
                'text-primary-600 font-bold': isPassedOrActive && step.enable,
                'text-gray-300': !step.enable,
              })}
            >
              {step.label}
            </div>
          </div>
        );
      })}
    </div>
  );
};
