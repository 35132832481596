import React from 'react';

import clsx from 'clsx';
import { useAtomValue, useSetAtom } from 'jotai';

import { Stepper } from 'components/Stepper/Stepper';

import { currentStepAtom, stepsAtom } from '../models/step';

interface EvaluationStepperProps {}

export const EvaluationStepper: React.FC<EvaluationStepperProps> = () => {
  const steps = useAtomValue(stepsAtom);
  const handleStepChange = useSetAtom(currentStepAtom);
  const handleChange = (id: string) => {
    const step = steps.find((step) => step.id === id);
    if (step) {
      handleStepChange(step);
    }
  };
  const className = clsx('w-[660px]', 'mx-auto');
  return <Stepper steps={steps} onChange={handleChange} className={className} />;
};
