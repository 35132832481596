import React from 'react';

import { LoanProposalRates } from 'generated/graphql';
import { useFormContext, useWatch } from 'react-hook-form';
import { getNumberValue } from 'utils';

import { useCustomisation } from 'modules/root/Settings';

import { getSpreadValue } from '../util';
import { TextboxController } from './controllers/TextboxController';
import { LoanProposalFormValues } from './types';

interface SpreadProps {
  showField?: boolean;
  ratesConfig: LoanProposalRates[];
}

export const Spread: React.FC<SpreadProps> = ({ ratesConfig, showField = true }) => {
  const { labels, helpText } = useCustomisation();
  const { control, setValue } = useFormContext<LoanProposalFormValues>();
  const loanAmount = useWatch({ control, name: 'loanAmount' });
  const proposedPricing = useWatch({ control, name: 'proposedPricing' });
  const interestRateType = useWatch({ control, name: 'interestRateType' });
  const loanType = useWatch({ control, name: 'loanType' });
  const loanTerm = useWatch({ control, name: 'loanTerm' });

  React.useEffect(() => {
    if (ratesConfig && loanType && loanAmount && interestRateType) {
      const spreadValue = getSpreadValue(
        ratesConfig,
        getNumberValue(loanAmount),
        loanTerm,
        loanType,
        proposedPricing,
        interestRateType
      );
      setValue('spread', spreadValue || '0');
    } else {
      setValue('spread', '0');
    }
  }, [
    ratesConfig,
    loanTerm,
    loanType,
    proposedPricing,
    loanAmount,
    interestRateType,
    setValue,
  ]);

  return (
    <TextboxController
      label={labels.spread}
      hidden={!showField}
      name={'spread'}
      data-testid={'spread'}
      suffixText={'BPS'}
      disabled={true}
      control={control}
      helpText={helpText.spread}
    />
  );
};
