import React from 'react';

export const getShortener =
  (countChars: number) => (value: string | null | undefined) => {
    if (value && value.length > countChars + 2) {
      const len = value.length;
      return `${value.substring(0, 2)}...${value.substring(len - countChars + 2, len)}`;
    }
    return value;
  };

export const useIsTouchDevice = () => {
  return (
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    (navigator as any).msMaxTouchPoints > 0
  );
};

export const notEmpty = <TValue>(value: TValue | null | undefined): value is TValue => {
  return value !== null && value !== undefined;
};

export const useScreenWidth = () => {
  const [screenWidth, setScreenWidth] = React.useState<number>(window.innerWidth);
  React.useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });
  return screenWidth;
};
