import React from 'react';

import clsx from 'clsx';

import { money } from 'utils/currency';

type Props = {
  value: number;
  label: string;
  bgColor: string;
};

const legendCirleStyle = clsx('w-2', 'h-2', 'mr-2', 'inline-block', 'rounded-full');

const legendLabelStyle = clsx(
  'uppercase',
  'text-primary-600',
  'text-xs',
  'font-semibold'
);

export const LegendItem: React.FC<Props> = ({ value, label, bgColor }) => {
  return (
    <div className="mb-5 text-sm">
      <div className={legendLabelStyle}>
        <>
          <span className={clsx(legendCirleStyle, bgColor)}></span> {label}
        </>
      </div>
      {money.format(value)}
    </div>
  );
};
