import React from 'react';

import { faXmark } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import {
  FontIcon,
  LoadingOver,
  Modal,
  Pagination,
  SearchQueryParams,
  useLimitedPagination,
  useSearchQueryParams,
} from 'components';
import {
  ActivityAuditType,
  CustomerAlertsSearchQuery,
  DataBarrierFunctionality,
  useCustomerAlertsSearchQuery,
} from 'generated/graphql';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';
import { useTranslation } from 'react-i18next';
import { ResourceAccessed, useSendAuditReport } from 'services/auditReport';
import { ArrayElement } from 'utils';

import { SearchBox } from 'modules/Customers/SearchBox/SearchBox';
import { DashboardList } from 'modules/Home/components/DashboardList';
import { QuerySuspense } from 'modules/common/QuerySuspense/QuerySuspense';
import { useAccess } from 'modules/root/auth/Authenticated/Permission/permission.context';
import { notEmpty } from 'utils/helpers';

import { Alert, ModalState } from './Alert';
import { AlertModal } from './AlertModal';

const DEFAULT_PAGE_SIZE = 5;
const QUERY_LIMIT = 10000;

type AlertItems = NonNullable<
  ArrayElement<CustomerAlertsSearchQuery['customerAlertsSearch']['items']>
>;

const useAlertsQuery = (queryParams: SearchQueryParams) => {
  const [total, setTotal] = React.useState(0);
  const [currentItems, setCurrentItems] = React.useState<null | AlertItems[]>(null);
  const { data, isLoading, error, isFetched, isFetching, refetch } = useQueryFetch(
    useCustomerAlertsSearchQuery,
    {
      queryHookParams: {
        ...queryParams,
        dataBarrierFunctionality: DataBarrierFunctionality.Home,
      },
      queryHookOptions: { enabled: false },
    }
  );
  React.useEffect(() => {
    if (refetch) {
      refetch();
    }
  }, [refetch, queryParams]);

  React.useEffect(() => {
    if (isFetched && data && !error) {
      const items = data?.customerAlertsSearch.items?.filter(notEmpty) || [];
      setCurrentItems(items);
      setTotal(data?.customerAlertsSearch.totalHits);
    }
  }, [data, error, isFetched, queryParams]);

  return {
    total,
    items: currentItems,
    isFetched,
    isFetching,
    isLoading: data === null && isLoading,
    error,
  };
};
export interface AllAlertsModalTitleProps {
  onClose: () => void;
  handleSearch: (value: string) => void;
}

export interface AllAlertsModalProps {
  onClose: () => void;
}

export const AllAlertsModalTitle: React.FC<AllAlertsModalTitleProps> = ({
  onClose,
  handleSearch,
}) => {
  const { t } = useTranslation();

  return (
    <div className="bg-gray-50 px-10 py-6 rounded-t-lg">
      <div className="flex justify-between gap-4">
        <div className="uppercase text-sm text-gray-500 cursor-pointer font-normal mb-2">
          {`${t('dashboard.alerts.title')}`}
        </div>
        <FontIcon className="cursor-pointer" onClick={onClose} icon={faXmark} />
      </div>
      <SearchBox
        placeholder={`${t('common.searchBox.find')} ${t('dashboard.alerts.title')}`}
        className="text-base w-full font-normal"
        onChange={handleSearch}
      />
    </div>
  );
};

export const AllAlertsModal: React.FC<AllAlertsModalProps> = ({ onClose }) => {
  useSendAuditReport({
    sendOnMount: true,
    audit: {
      activityType: ActivityAuditType.Read,
      resourceAccessed: ResourceAccessed.ViewAllCustomerAlerts,
    },
  });
  const { handlePageChange, pagination } = useLimitedPagination(
    DEFAULT_PAGE_SIZE,
    QUERY_LIMIT
  );
  const { t } = useTranslation();
  const { permissions } = useAccess();
  const [searchText, setSearchText] = React.useState<string>('');
  const handleSearch = React.useCallback(
    (value: string) => {
      setSearchText(value.trim());
      handlePageChange({ ...pagination, pageIndex: 0 });
    },
    [handlePageChange, pagination]
  );
  const queryParams = useSearchQueryParams({ pagination, searchText });
  const { items, total, isLoading, isFetched, isFetching, error } =
    useAlertsQuery(queryParams);
  const [modalState, setModalState] = React.useState<ModalState | null>(null);
  const sendAudit = useSendAuditReport();
  const showAlertModal = (state: ModalState) => () => {
    sendAudit({
      activityType: ActivityAuditType.Read,
      customerExternalId: state.customerExternalId,
      resourceAccessed: ResourceAccessed.ViewCustomerAlert,
    });
    setModalState(state);
  };

  if (modalState) {
    return (
      <AlertModal
        onClose={() => setModalState(null)}
        customerName={modalState.customerName}
        alert={modalState.alert}
        customerId={modalState.customerId}
        customerExternalId={modalState.customerExternalId}
        onBackToAll={() => setModalState(null)}
      />
    );
  }
  return (
    <Modal
      className="w-[750px] lg:w-[850px] xl:w-[1050px]"
      isOpen={true}
      onClose={onClose}
      title={<AllAlertsModalTitle onClose={onClose} handleSearch={handleSearch} />}
    >
      <div className="h-[500px]">
        {permissions.canViewCollateralAccounts ? (
          <QuerySuspense
            isLoading={isLoading}
            noData={isFetched && items?.length === 0}
            error={error}
            noDataMessage={searchText ? t('common.searchBox.noRecords') : undefined}
            className="h-full"
          >
            <LoadingOver show={isFetching}>
              <div className="py-6 px-10 h-[424px] overflow-y-auto">
                {searchText && total > QUERY_LIMIT && (
                  <div className={clsx('warning', 'my-4')}>
                    {t('common.searchBox.manyRecords')}
                  </div>
                )}
                <DashboardList>
                  {items &&
                    items.map(
                      (customerAlert) =>
                        customerAlert && (
                          <DashboardList.Item key={customerAlert?.id}>
                            <Alert data={customerAlert} showAlertModal={showAlertModal} />
                          </DashboardList.Item>
                        )
                    )}
                </DashboardList>
              </div>
              <Pagination
                className="absolute inset-x-0 bottom-0 px-10 py-6 bg-gray-50"
                currentPage={pagination.pageIndex + 1}
                pageRange={5}
                rowsPerPage={DEFAULT_PAGE_SIZE}
                totalRows={total}
                nextPage={() => {
                  handlePageChange({
                    ...pagination,
                    pageIndex: pagination.pageIndex + 1,
                  });
                }}
                previousPage={() => {
                  handlePageChange({
                    ...pagination,
                    pageIndex: pagination.pageIndex - 1,
                  });
                }}
                onChange={(page) => {
                  handlePageChange({ ...pagination, pageIndex: page - 1 });
                }}
              />
            </LoadingOver>
          </QuerySuspense>
        ) : (
          <div className="warning m-2" data-testid="no-permissions-message">
            {t('auth.disallow')}
          </div>
        )}
      </div>
    </Modal>
  );
};
