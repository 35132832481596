import React from 'react';

import { Badge } from 'components';

import { STATUS_BACKGROUND } from '../enums/statusBackground';

interface ActivityBadgeProps {
  status: string;
  size?: 'sm' | 'md';
  showEllipsis?: boolean;
}

export const ActivityBadge: React.FC<ActivityBadgeProps> = ({
  status,
  size = 'md',
  showEllipsis,
}) => {
  return (
    <Badge
      size={size}
      label={status}
      color={STATUS_BACKGROUND.PRIMARY}
      showEllipsis={showEllipsis}
    />
  );
};
