import React from 'react';

import { Button, LoadedButton } from 'components';
import {
  ActivityAuditType,
  GetEvaluationQuery,
  useAuditReportMutation,
} from 'generated/graphql';
import { graphqlClient, useAuthClient } from 'queries/apiFetch/graphqlClient';
import { useTranslation } from 'react-i18next';
import { useDownloadPdf } from 'services';
import { ResourceAccessed } from 'services/auditReport';

import { useAccess } from 'modules/root/auth/Authenticated/Permission/permission.context';
import { DATE_FORMAT_UNDERSCORE, formatDate } from 'utils/date';

import { SummaryTable } from './SummaryTable';

interface ExistingEvaluationSummaryProps {
  evaluationData: GetEvaluationQuery;
  customerExternalId?: string;
}

export const ExistingEvaluationSummary: React.FC<ExistingEvaluationSummaryProps> = ({
  evaluationData,
  customerExternalId,
}) => {
  const { t } = useTranslation();
  const { permissions } = useAccess();
  const {
    download,
    isError: pdfDownloadError,
    isLoading: pdfIsLoading,
  } = useDownloadPdf();
  const authClient = useAuthClient(graphqlClient);
  const { mutateAsync } = useAuditReportMutation(authClient);

  const handleDownloadPdf = (id: string) => {
    const fileName = `Evaluation-${id}-${formatDate(
      new Date().toUTCString(),
      DATE_FORMAT_UNDERSCORE
    )}`;
    const urlPath = `/schema/valuations/${id}/pdf`;
    download(urlPath, fileName)
      .then(() =>
        mutateAsync({
          activityType: ActivityAuditType.Read,
          customerExternalId,
          resourceAccessed: ResourceAccessed.DownloadEvaluationPdf,
          queryDetails: JSON.stringify({ evaluationId: id }),
        })
      )
      .catch(() => {
        mutateAsync({
          activityType: ActivityAuditType.Read,
          customerExternalId,
          resourceAccessed: ResourceAccessed.DownloadEvaluationPdf,
          queryDetails: JSON.stringify({ evaluationId: id, error: true }),
        });
      });
  };

  return (
    <div>
      <h2>{t('evaluations.summary.title')}</h2>
      {evaluationData?.evaluation && (
        <SummaryTable evaluation={evaluationData?.evaluation} />
      )}
      <div className="flex flex-row flex-nowrap my-6">
        <div className="basis-1/2">
          <Button className="mr-5" state="secondary" disabled={true}>
            {t('evaluations.summary.saveEvaluation')}
          </Button>
          {permissions.canAccessEvaluationPdf && (
            <LoadedButton
              onClick={() => handleDownloadPdf(evaluationData?.evaluation?.id || '')}
              state="secondary"
              isLoading={pdfIsLoading}
              disabled={!evaluationData?.evaluation?.id || pdfIsLoading}
            >
              {t('evaluations.summary.downloadPdf')}
            </LoadedButton>
          )}
        </div>
        {permissions.canCreateLoanApplication && (
          <div className="flex basis-1/2 justify-end">
            <Button disabled={true} data-testid="apply-for-loan-button">
              {t('evaluations.summary.applyForALoan')}
            </Button>
          </div>
        )}
      </div>
      {pdfDownloadError && (
        <div data-testid="download-failed-msg" className="alert">
          {t('common.error.unspecific')}
        </div>
      )}
    </div>
  );
};
