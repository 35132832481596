import React from 'react';

import { ActivityAuditType, useParseAssetCsvQuery } from 'generated/graphql';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';

type CsvFileUploadOptions = {
  systemAccountIds: string[];
  customerExternalId?: string;
  onSelectError?: (message: string) => void;
  onUploadError?: (message: string) => void;
  onUploadSuccess?: (data: unknown) => void;
};

export const useCsvFileUpload = ({
  systemAccountIds,
  customerExternalId,
  onSelectError,
  onUploadError,
  onUploadSuccess,
}: CsvFileUploadOptions) => {
  const [csvData, setCsvData] = React.useState('');
  const [selectFileError, setSelectFileError] = React.useState('');
  const {
    data: csvAccountData,
    isLoading: isLoadingCsvAccountData,
    isError: uploadFileError,
  } = useQueryFetch(useParseAssetCsvQuery, {
    queryHookOptions: {
      enabled: !!csvData,
      onError: () => {
        if (onUploadError) {
          onUploadError('common.error.unspecific');
        }
      },
      onSuccess: (data) => {
        if (onUploadSuccess) {
          onUploadSuccess(data);
        }
      },
    },
    queryHookParams: {
      csv: csvData,
      systemAccountIds: systemAccountIds,
    },
    extra: {
      auditReport: {
        activityType: ActivityAuditType.Read,
        customerExternalId,
      },
    },
  });

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      if (fileExtension !== 'csv') {
        setSelectFileError('common.file.selectCSV');
        onSelectError && onSelectError('common.file.selectCSV');
        e.target.value = '';
      } else if (fileExtension === 'csv') {
        const reader = new FileReader();
        reader.onload = (read) => {
          if (typeof read.target?.result === 'string') {
            setCsvData(read.target.result);
            setSelectFileError('');
          } else {
            setSelectFileError('common.file.invalidFileFormat');
            onSelectError && onSelectError('common.file.invalidFileFormat');
          }
        };
        reader.readAsText(file);
      }
    }
  };

  return {
    csvAccountData,
    isLoadingCsvAccountData,
    selectFileError,
    uploadFileError,
    handleUpload,
  };
};
