import React from 'react';

import { Button } from 'components';

import { useCustomisation } from 'modules/root/Settings/contexts/customisation';

import { AddAccountsModal } from './AddAccountsModal/AddAccountsModal';

interface AddAccountsProps {
  state: 'primary' | 'secondary';
  disabled?: boolean;
}

export const AddAccounts: React.FC<AddAccountsProps> = ({ state, disabled }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { labels } = useCustomisation();

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button
        data-testid="add-accounts-button"
        state={state}
        disabled={disabled}
        onClick={() => setIsOpen(!isOpen)}
      >
        {`Add ${labels.accounts}`}
      </Button>
      <AddAccountsModal isOpen={isOpen} onClose={handleClose} />
    </>
  );
};
