import { palette } from 'modules/root/theme/visualization';

type LoanMarginGraphOptionsProps = {
  eligible: number;
  sellOut: number;
  topUp: number;
  topUpCushion: number | null | undefined;
};

const getEligible = (
  eligibleValue: number,
  sellOutThreshold: number,
  topUpThreshold: number
): Highcharts.SeriesOptionsType => {
  const borderRadius =
    eligibleValue > sellOutThreshold && eligibleValue > topUpThreshold ? '50%' : '0';
  return {
    type: 'column',
    enableMouseTracking: false,
    index: 2,
    data: [eligibleValue],
    color: palette.primary600,
    borderRadiusBottomLeft: '50%',
    borderRadiusBottomRight: '50%',
    borderRadiusTopLeft: borderRadius,
    borderRadiusTopRight: borderRadius,
    states: {
      hover: {
        enabled: false,
      },
    },
  };
};

const getSellOut = (
  sellOut: number,
  eligible: number,
  sellOutValue: number
): Highcharts.SeriesOptionsType => {
  return {
    type: 'column',
    enableMouseTracking: false,
    states: {
      hover: {
        enabled: false,
      },
    },
    color: palette.red600,
    index: 1,
    data: [sellOut > eligible ? sellOutValue : 0],
  };
};

const getTopUp = (
  topUp: number,
  eligible: number,
  topUpValue: number
): Highcharts.SeriesOptionsType => {
  return {
    type: 'column',
    color: palette.yellow300,
    enableMouseTracking: false,
    borderRadiusTopLeft: '50%',
    borderRadiusTopRight: '50%',
    data: [topUp > eligible ? topUpValue : 0],
    index: 0,
    states: {
      hover: {
        enabled: false,
      },
    },
  };
};

const getTopUpCushion = (): Highcharts.SeriesOptionsType => {
  return {
    type: 'column',
    color: palette.primary400,
    enableMouseTracking: false,
    borderRadiusTopLeft: '50%',
    borderRadiusTopRight: '50%',
    data: [0],
    index: 0,
    states: {
      hover: {
        enabled: false,
      },
    },
  };
};

const getSellOutThreshold = (sellOutThreshold: number): Highcharts.SeriesOptionsType => {
  return {
    type: 'line',
    showInLegend: false,
    enableMouseTracking: false,
    zIndex: 504,
    pointStart: 0,
    color: palette.red600,
    marker: {
      enabled: true,
      symbol: 'threshold',
      lineColor: palette.red600,
      lineWidth: 1,
      radius: 10,
    },
    data: [sellOutThreshold],
  };
};

const getTopUpThreshold = (topUpThreshold: number): Highcharts.SeriesOptionsType => {
  return {
    type: 'line',
    showInLegend: false,
    enableMouseTracking: false,
    zIndex: 503,
    pointStart: 0,
    color: palette.yellow300,
    marker: {
      enabled: true,
      symbol: 'threshold',
      lineColor: palette.yellow300,
      lineWidth: 1,
      radius: 10,
    },
    data: [topUpThreshold],
  };
};

const getTopUpCushionThreshold = (
  topUpCushionThreshold: number | null
): Highcharts.SeriesOptionsType => {
  return {
    type: 'line',
    showInLegend: false,
    enableMouseTracking: false,
    zIndex: 502,
    pointStart: 0,
    color: palette.primary400,
    marker: {
      enabled: true,
      symbol: 'threshold',
      lineColor: palette.primary400,
      lineWidth: 1,
      radius: 10,
    },
    data: [topUpCushionThreshold],
  };
};

const getEligibleThreshold = (
  eligibleThreshold: number
): Highcharts.SeriesOptionsType => {
  return {
    type: 'line',
    showInLegend: false,
    enableMouseTracking: false,
    zIndex: 501,
    pointStart: 0,
    color: palette.primary600,
    marker: {
      enabled: true,
      symbol: 'threshold',
      lineColor: palette.primary600,
      lineWidth: 1,
      radius: 10,
    },
    data: [eligibleThreshold],
  };
};

export const getLoanMarginGraphOptions = ({
  eligible,
  sellOut,
  topUp,
  topUpCushion,
}: LoanMarginGraphOptionsProps): Highcharts.Options => {
  let eligibleValue = 0;
  let sellOutValue = 0;
  let topUpValue = 0;
  let eligibleThreshold = 0;
  let sellOutThreshold = 0;
  let topUpThreshold = 0;
  let topUpCushionThreshold = 0;

  if (sellOut >= eligible) {
    eligibleValue = (eligible / topUp) * 100;
    sellOutValue = (sellOut / topUp) * 100 - eligibleValue;
    topUpValue = (topUp / topUp) * 100 - eligibleValue - sellOutValue;
    eligibleThreshold = eligibleValue;
    sellOutThreshold = eligibleThreshold + sellOutValue;
    topUpThreshold = 100;
  } else if (topUp >= eligible) {
    eligibleValue = (eligible / topUp) * 100;
    sellOutValue = (sellOut / topUp) * 100;
    topUpValue = ((topUp - eligible) / topUp) * 100;
    eligibleThreshold = eligibleValue;
    sellOutThreshold = sellOutValue;
    topUpThreshold = 100;
  } else {
    eligibleValue = 100;
    eligibleThreshold = eligibleValue;
    sellOutThreshold = (sellOut / eligible) * 100;
    topUpThreshold = (topUp / eligible) * 100;
  }

  if (topUpCushion && topUpCushion < eligible) {
    topUpCushionThreshold = (topUpCushion / eligible) * 100;
  }
  const series = [
    getEligible(eligibleValue, sellOutThreshold, topUpThreshold),
    getTopUp(topUp, eligible, topUpValue),
    getSellOut(sellOut, eligible, sellOutValue),
    getEligibleThreshold(eligibleThreshold),
    getSellOutThreshold(sellOutThreshold),
    getTopUpThreshold(topUpThreshold),
  ];
  if (topUpCushion) {
    series.push(getTopUpCushion(), getTopUpCushionThreshold(topUpCushionThreshold));
  }

  const options: Highcharts.Options = {
    credits: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    chart: {
      type: 'column',
      height: 50,
      backgroundColor: 'transparent',
      inverted: true,
      marginLeft: 4,
      marginRight: 4,
      marginBottom: 10,
    },
    title: {
      text: '',
    },
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: false,
      min: 0,
      max: 100,
    },
    legend: { enabled: false },
    plotOptions: {
      column: {
        stacking: 'normal',
        borderWidth: 0,
        pointWidth: 10,
      },
      series: {
        animation: false,
        states: {
          inactive: {
            opacity: 1,
          },
          hover: {
            enabled: false,
          },
        },
        events: {
          legendItemClick: (e) => e.preventDefault(),
        },
        marker: {
          enabled: false,
        },
      },
    },
    series,
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
  };
  return options;
};
