import React from 'react';

import { Modal } from 'components';
import { CustomerLoansQuery, PartiesSection } from 'generated/graphql';

import { ArrayElement } from 'utils/genericType';

import { PartiesState } from '../Parties';
import { PartiesModalBody } from './PartiesModalBody';
import { PartiesModalTitle } from './PartiesModalTitle';

type Customer = CustomerLoansQuery['customer'];
export type CustomerLoan = NonNullable<ArrayElement<Customer['loans']>>;

interface PartiesModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  loan: CustomerLoan;
  title: string;
  parties: PartiesState;
  sections: PartiesSection[];
}

export const PartiesModal: React.FC<PartiesModalProps> = ({
  isOpen,
  setIsOpen,
  loan,
  title,
  parties,
  sections,
}) => {
  return (
    <Modal
      className="w-[750px] lg:w-[850px] xl:w-[1050px]"
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      title={<PartiesModalTitle setIsOpen={setIsOpen} loan={loan} title={title} />}
    >
      <PartiesModalBody parties={parties} sections={sections} />
    </Modal>
  );
};
