import React from 'react';

import { RunEvaluationQuery } from 'generated/graphql';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { useCustomisation } from 'modules/root/Settings';

import evaluationGraphColorMap from './evaluationGraphColorMap';
import evaluationGraphOptions from './evaluationGraphOptions';
import getSplineChart from './splineChart';

export type ChartSeriesData = {
  name: string;
  data: unknown[];
  color?: string;
  zIndex?: number;
  type?: string;
  dashStyle?: string;
  marker?: {
    lineColor: string;
    fillColor: string;
  };
};

type Props = {
  evaluationAssetSummaryValues: RunEvaluationQuery['runEvaluation']['evaluationAssetSummary'];
};

const EvaluationGraph: React.FC<Props> = ({ evaluationAssetSummaryValues }) => {
  const { labels } = useCustomisation();
  const categoriesArr = [labels.marketValue, labels.maxCommitmentAmount];
  const getColumnHeight = (value: number, totalColumnValue: number): number => {
    const twoPercentOfTotalColumnValue = totalColumnValue / 50;
    return value < twoPercentOfTotalColumnValue ? twoPercentOfTotalColumnValue : value;
  };

  const chartSeriesData: ChartSeriesData[] = [];
  const totalMarketValue = evaluationAssetSummaryValues
    .map((eas) => eas.marketValue)
    .reduce((p, c) => p + c, 0);

  let marketValueSum = 0;
  let collateralValueSum = 0;

  const evaluationAssetSummary = evaluationAssetSummaryValues || [];
  evaluationAssetSummary.forEach((evaluationAsset) => {
    marketValueSum += evaluationAsset.marketValue || 0;
    collateralValueSum += evaluationAsset.collateralValue || 0;
    chartSeriesData.unshift({
      name: evaluationAsset?.assetType || '',
      color: evaluationGraphColorMap.get(evaluationAsset?.assetType || ''),
      type: 'column',
      data: [
        {
          realY: evaluationAsset?.marketValue || 0,
          y: getColumnHeight(evaluationAsset?.marketValue || 0, totalMarketValue),
          x: 0,
        },
        {
          realY: evaluationAsset?.collateralValue || 0,
          y: getColumnHeight(evaluationAsset?.collateralValue || 0, totalMarketValue),
          x: 1,
        },
      ],
    });
    const splineChartSeriesData = getSplineChart(
      marketValueSum,
      collateralValueSum,
      evaluationAsset?.advanceRate || 0
    );
    chartSeriesData.push(splineChartSeriesData);
  });

  return (
    <figure className="mt-6" data-testid="eval-results-graph">
      <HighchartsReact
        highcharts={Highcharts}
        options={evaluationGraphOptions(chartSeriesData, categoriesArr)}
      />
    </figure>
  );
};

export default EvaluationGraph;
