import React from 'react';

type PaginationResult = {
  page: number;
  total: number;
  nextPage: () => void;
  previousPage: () => void;
  changePage: (page: number) => void;
  currentPageItems: unknown[];
};

export const useClientPagination = (
  items: unknown[],
  pageSize: number
): PaginationResult => {
  const [page, setPage] = React.useState(1);
  const total = items.length;

  const nextPage = () => {
    if (page * pageSize < total) {
      setPage(page + 1);
    }
  };

  const previousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const changePage = (page: number) => {
    if (page >= 1 && page <= Math.ceil(total / pageSize)) {
      setPage(page);
    }
  };

  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentPageItems = items.slice(startIndex, endIndex);

  return {
    page,
    total,
    nextPage,
    previousPage,
    changePage,
    currentPageItems,
  };
};
