import React from 'react';
import { useNavigate } from 'react-router';

import { useAtomValue } from 'jotai';

import { useEvaluationContext } from 'modules/EvaluationV2/EvaluationRoot';
import {
  EvaluationState,
  evaluationStateAtom,
} from 'modules/EvaluationV2/models/evaluation';
import { useBlockNavigation } from 'utils/blockNavigation';

import { LeaveEvaluationModal } from '../modals/LeaveEvaluationModal';

interface ILeaveBeforeSavingProps {}

export const LeaveBeforeSaving: React.FC<ILeaveBeforeSavingProps> = () => {
  const navigate = useNavigate();
  const { resetStore } = useEvaluationContext();
  const [showLeaveModal, setShowLeaveModal] = React.useState(false);
  const handleBlock = React.useCallback(() => setShowLeaveModal(true), []);
  const evalState = useAtomValue(evaluationStateAtom);
  const isWorkCanBeLost = [
    EvaluationState.Processing,
    EvaluationState.Ran,
    EvaluationState.SaveFailed,
  ].includes(evalState);
  const { unblock } = useBlockNavigation(isWorkCanBeLost, handleBlock);
  const handleLeaveEvaluationModalClose = (choose: 'cancel' | 'leave') => {
    if (choose === 'leave') {
      unblock().then((blockedPath) => {
        navigate(blockedPath);
        resetStore();
      });
    } else {
      setShowLeaveModal(false);
    }
  };
  return showLeaveModal ? (
    <LeaveEvaluationModal onClose={handleLeaveEvaluationModalClose} />
  ) : null;
};
