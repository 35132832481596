import React from 'react';

export const DashboardList: React.FC<React.PropsWithChildren<unknown>> & {
  Item: typeof DashboardListItem;
} = ({ children }) => {
  return <ul>{children}</ul>;
};

const DashboardListItem: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <li className="mb-4 last:mb-0 pb-3 last:pb-0 border-b last:border-b-0">{children}</li>
  );
};

DashboardList.Item = DashboardListItem;
