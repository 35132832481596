export class CommonError extends Error {
  /**
   * Throw this error for common cases
   * @param name provide a component or hook name with "Error" postfix (e.g. SomeComponentError or useSomeHookError)
   * @param message provide a human readable message
   */
  constructor(name: string, message: string) {
    super(message);
    this.name = name;
  }
}
