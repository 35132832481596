import React from 'react';

import { TextInput } from 'components';
import { EvaluationInput } from 'generated/graphql';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT_TIME, formatDate } from 'utils';

interface EvaluationInfoProps {
  readOnly: boolean;
}

export const EvaluationInfo: React.FC<EvaluationInfoProps> = ({ readOnly }) => {
  const { t } = useTranslation();
  const { control, getValues } = useFormContext<EvaluationInput>();

  const lastEvaluated = formatDate(getValues('lastEvaluated'), DATE_FORMAT_TIME);
  const labelValue = getValues('label');

  return (
    <div className="my-4 flex flex-row text-base lg:text-lg items-center">
      <div className="flex gap-4 basis-1/2 grow">
        {readOnly ? (
          <div>{`${t('evaluations.label')}: ${labelValue}`}</div>
        ) : (
          <Controller
            render={({ field, fieldState: { error } }) => {
              return (
                <TextInput
                  className="mr-4 grow items-center"
                  label={t('evaluations.label')}
                  text={field.value || undefined}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  maxLength={140}
                  errorMessage={error?.message}
                />
              );
            }}
            name="label"
            control={control}
          />
        )}
      </div>
      <div className="basis-1/2">{`${t(
        'evaluations.lastEvaluated'
      )}: ${lastEvaluated}`}</div>
    </div>
  );
};
