import { atom } from "jotai";

export enum NotificationType {
    error = 'ERROR',
    warning = 'WARNING',
    info = 'INFORMATION'
  }

type Notification = {type: NotificationType, message: string};

export const NotificationAtom = atom<Notification | null>(null);

