import React from 'react';

import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { Listbox } from '@headlessui/react';
import clsx from 'clsx';
import { FontIcon } from 'components';

import { SelectItem, SelectItemValueType } from './SelectItem';

interface SelectProps {
  options: SelectItemValueType[];
  value: SelectItemValueType;
  onChange: (value: SelectItemValueType) => void;
  className?: string;
  disabled?: boolean;
}

const selectButtonClass = clsx(
  'bg-white',
  'text-gray-700',
  'font-bold',
  'w-60',
  'border',
  'rounded',
  'box-border',
  'border-gray-200',
  'hover:bg-gray-50',
  'pl-4',
  'pr-3',
  'py-3',
  'flex',
  'disabled:border-gray-200',
  'disabled:text-gray-300'
);

const optionsClass = clsx(
  'bg-white',
  'shadow-md',
  'w-60',
  'border',
  'border-gray-100',
  'rounded',
  'absolute',
  'z-10'
);

const getIcon = (open: boolean) => (
  <FontIcon
    icon={open ? faSortUp : faSortDown}
    className={open ? 'flex-none pt-2' : 'flex-none'}
  />
);

export const Select: React.FC<SelectProps> = ({
  value,
  options,
  disabled = false,
  className,
  onChange,
}) => {
  return (
    <Listbox value={value} onChange={onChange} disabled={disabled}>
      {({ open }) => (
        <>
          <Listbox.Button className={clsx(className, selectButtonClass)}>
            <span className="flex-auto text-left">{value.name}</span>
            {getIcon(open)}
          </Listbox.Button>
          <Listbox.Options className={optionsClass}>
            {options.map((option) => {
              return <SelectItem key={option.id} value={option} selectedValue={value} />;
            })}
          </Listbox.Options>
        </>
      )}
    </Listbox>
  );
};
