import React from 'react';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';

import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { Card, FontIcon, ListItem } from 'components';
import { ActivityAuditType, LoanStatus, useSingleLoanQuery } from 'generated/graphql';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';
import { useTranslation } from 'react-i18next';

import { QuerySuspense } from 'modules/common/QuerySuspense/QuerySuspense';
import { useCustomisation, usePageTitle } from 'modules/root/Settings';

import { LoanActions } from '../LoanActions';
import { LoanField, LoanFieldsFormatterJSX } from '../LoanFields';
import { LoanSection } from '../LoanSection/LoanSection';
import { useLoansContext } from '../Loans';
import { LoanMarginGraph } from './LoanMarginGraph/LoanMarginGraph';
import { Parties } from './Parties';

interface SingleLoanProps {}

const loanColClass = clsx(
  'flex',
  'flex-col',
  'grow',
  'w-full lg:w-1/3 xl:w-1/4',
  'gap-4'
);

export const SingleLoan: React.FC<SingleLoanProps> = () => {
  const config = useCustomisation();

  const { customerId, loanId } = useParams();
  const { clientNumberOfLoans } = useLoansContext();
  const { t } = useTranslation();

  const location = useLocation();
  const highlightMarginAnalysis: boolean | undefined =
    location?.state?.highlightMarginAnalysis;
  const activeMarginElemRef = React.createRef<HTMLDivElement>();
  React.useLayoutEffect(() => {
    if (activeMarginElemRef.current && highlightMarginAnalysis) {
      activeMarginElemRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [activeMarginElemRef, highlightMarginAnalysis]);

  const { data, error, isLoading } = useQueryFetch(useSingleLoanQuery, {
    queryHookParams: { loanID: loanId!, customerID: customerId! },
    extra: {
      auditReport: { activityType: ActivityAuditType.Read },
    },
  });

  const {
    partiesV2: { show: showPartiesCard },
  } = config;

  const loan = data?.loan;
  const customer = data?.customer;

  usePageTitle([customer?.displayName || '', `${loan?.obligor}-${loan?.obligation}`]);

  const [loanDetailsfields, setLoanDetailsfields] = React.useState<LoanField[]>([]);
  const [opportunityFields, setOpportunityFields] = React.useState<LoanField[]>([]);
  const [keyInformationFields, setKeyInformationFields] = React.useState<LoanField[]>([]);
  const [interestRatesFields, setInterestRatesFields] = React.useState<LoanField[]>([]);
  const [interestPaymentsFields, setInterestPaymentsFields] = React.useState<LoanField[]>(
    []
  );
  const [interestHistoryFields, setInterestHistoryFields] = React.useState<LoanField[]>(
    []
  );
  const [pledgedAccountsFields, setPledgedAccountsFields] = React.useState<LoanField[]>(
    []
  );
  const [blendedRatesFields, setBlendedRatesFields] = React.useState<LoanField[]>([]);

  React.useEffect(() => {
    if (loan && customer) {
      const loanFields = new LoanFieldsFormatterJSX(loan, customer, config);
      setLoanDetailsfields(loanFields.getDetails());
      setOpportunityFields(loanFields.getOpportunity());
      setKeyInformationFields(loanFields.getKeyInformation());
      setInterestRatesFields(loanFields.getInterestRates());
      setInterestPaymentsFields(loanFields.getInterestPayments());
      setInterestHistoryFields(loanFields.getInterestHistory());
      setPledgedAccountsFields(loanFields.getPledgedAccounts());
      setBlendedRatesFields(loanFields.getBlendedRates());
    }
  }, [loan, customer, config]);

  const numberOfLoans = customer?.portfolioSummary?.numberOfLoans || 0;
  const showOpportunity =
    numberOfLoans === 1 &&
    clientNumberOfLoans === 1 &&
    loan?.status !== LoanStatus.SellOut &&
    loan?.status !== LoanStatus.TopUp &&
    loan?.status !== LoanStatus.OverAdvanced;

  return (
    <QuerySuspense error={error} isLoading={isLoading} noData={!data?.loan}>
      {customer && loan && (
        <>
          <LoanActions
            customer={customer}
            loan={loan}
            showOpportunity={showOpportunity}
          />

          {loan.status === LoanStatus.DataIssue && (
            <div className="alert my-5 text-center" data-testid="data-issue-message">
              <FontIcon size="lg" icon={faCircleXmark} className="mr-2" />
              {t('customer.loans.dataIssueError')}
            </div>
          )}
          <div
            className={clsx('flex', 'flex-row', 'w-full', 'flex-wrap', 'gap-4')}
            data-testid="single-loan-container"
          >
            <div className={loanColClass}>
              {config.loanDetailsInfo.show && loanDetailsfields.length > 0 && (
                <LoanSection
                  title={config.labels.details}
                  fields={loanDetailsfields as ListItem[]}
                  testid="loan-details"
                />
              )}
              {config.loanOpportunity.show &&
                opportunityFields.length > 0 &&
                showOpportunity && (
                  <LoanSection
                    title={config.labels.opportunity}
                    fields={opportunityFields as ListItem[]}
                    testid="loan-opportunity"
                  />
                )}
              {showPartiesCard && loan && <Parties loan={loan} />}
            </div>
            <div className={loanColClass}>
              {config.loanKeyInformation.show && keyInformationFields.length > 0 && (
                <LoanSection
                  title={config.labels.keyInformation}
                  fields={keyInformationFields as ListItem[]}
                  testid="loan-key-information"
                />
              )}
              {config.loanInterestRates.show && interestRatesFields.length > 0 && (
                <LoanSection
                  title={config.labels.interestRates}
                  fields={interestRatesFields as ListItem[]}
                  testid="loan-interest-rates"
                />
              )}
              {config.loanInterestPayments.show && interestPaymentsFields.length > 0 && (
                <LoanSection
                  title={config.labels.interestPayments}
                  fields={interestPaymentsFields as ListItem[]}
                  testid="loan-interest-payments"
                />
              )}
              {config.loanInterestHistory.show && interestHistoryFields.length > 0 && (
                <LoanSection
                  title={config.labels.interestHistory}
                  fields={interestHistoryFields as ListItem[]}
                  testid="loan-interest-history"
                />
              )}
            </div>
            <div className={loanColClass}>
              {config.loanPledgedAccounts.show && pledgedAccountsFields.length > 0 && (
                <LoanSection
                  title={config.labels.pledgedAccounts}
                  fields={pledgedAccountsFields as ListItem[]}
                  testid="loan-pledged-accounts"
                />
              )}
              {config.loanBlendedRates.show && blendedRatesFields.length > 0 && (
                <LoanSection
                  title={config.labels.blendedRates}
                  fields={blendedRatesFields as ListItem[]}
                  testid="loan-blended-rates"
                />
              )}
              {config.loanMarginAnalysis.show &&
                (loan.status !== LoanStatus.DataIssue &&
                loan.eligibleMarketValue &&
                loan.selloutThreshold &&
                loan.topupThreshold ? (
                  <LoanMarginGraph
                    eligible={loan.eligibleMarketValue}
                    sellOut={loan.selloutThreshold}
                    topUp={loan.topupThreshold}
                    topUpCushion={loan.topUpCushion || null}
                    fixAmount={loan.fixAmount || null}
                    repaymentFixAmount={loan.repaymentFixAmount || null}
                    className={highlightMarginAnalysis ? 'animate-highlight' : undefined}
                    ref={activeMarginElemRef}
                  />
                ) : (
                  <Card title={config.labels.marginAnalysis}>
                    <div className="warning m-5" data-testid="data-issue-chart-message">
                      {config.labels.graphCannotBeDisplayed}
                    </div>
                  </Card>
                ))}
            </div>
          </div>
        </>
      )}
    </QuerySuspense>
  );
};
