import React from 'react';

import { Content, ShrinkHeader } from 'components';
import { useTranslation } from 'react-i18next';

import { usePageTitle } from 'modules/root/Settings';

export const Reports: React.FC = () => {
  const { t } = useTranslation();
  usePageTitle(t('reports.title'));
  return (
    <Content shrinkHeader={<ShrinkHeader title={<span>{t('reports.title')}</span>} />}>
      <div className="h-[1500px]">{'Report content to come'}</div>
    </Content>
  );
};
