import React from 'react';

import { Button, Modal, ModalTitle } from 'components';
import { useTranslation } from 'react-i18next';

interface LeaveEvaluationModalProps {
  onClose: (choose: 'cancel' | 'leave') => void;
}

export const LeaveEvaluationModal: React.FC<LeaveEvaluationModalProps> = ({
  onClose,
}) => {
  const { t } = useTranslation();
  const cancel = () => onClose('cancel');
  const leave = () => onClose('leave');
  return (
    <Modal
      isOpen={true}
      onClose={cancel}
      title={<ModalTitle title={t('common.unsavedChangesTitle')} onClose={cancel} />}
    >
      <div className="flex-col py-6 px-10 w-[500px]">
        {t('common.unsavedChangesMsg')}
        <div className="basis-full flex justify-end gap-4 mt-6">
          <Button onClick={cancel} state="secondary" size="sm">
            {t('common.cancel')}
          </Button>
          <Button onClick={leave} state="secondary" size="sm">
            {t('common.leavePage')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
