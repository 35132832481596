import React from 'react';

export interface ReactTableHeaderProps {
  children?: string | JSX.Element | JSX.Element[];
  'data-testid'?: string;
}

export const ReactTableHeader = ({
  children,
  'data-testid': testid,
}: ReactTableHeaderProps) => {
  return <thead data-testid={testid}>{children}</thead>;
};
