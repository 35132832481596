import React from 'react';

import {
  ActivityAuditType,
  DataBarrierFunctionality,
  useCustomerLoanApplicationActivitiesSearchQuery,
} from 'generated/graphql';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';
import { useTranslation } from 'react-i18next';
import { ResourceAccessed, useSendAuditReport } from 'services/auditReport';

import { DashboardList } from 'modules/Home/components/DashboardList';
import { ShowingLatestFive } from 'modules/Home/components/ShowingLatestFive';
import { QuerySuspense } from 'modules/common/QuerySuspense/QuerySuspense';
import { useAccess } from 'modules/root/auth/Authenticated/Permission/permission.context';

import { LoanApplicationActivity } from './LoanApplicationActivity';
import { LoanApplicationActivitiesModal } from './modals/LoanApplicationActivitiesModal';

interface LoanApplicationActivitiesCardProps {}

export type ModalState = {
  customerName: string;
  customerId: string;
  customerExternalId: string;
  applicationId: string;
};

export const LoanApplicationActivitiesCard: React.FC<
  LoanApplicationActivitiesCardProps
> = () => {
  const { data, isLoading, error } = useQueryFetch(
    useCustomerLoanApplicationActivitiesSearchQuery,
    {
      queryHookParams: {
        fromIndex: 0,
        numResults: 5,
        dataBarrierFunctionality: DataBarrierFunctionality.Home,
      },
    }
  );

  const customersLoanApplicationActivities =
    data?.customerLoanApplicationActivitiesSearch.items;
  const [modalState, setModalState] = React.useState<ModalState | null>(null);
  const sendAudit = useSendAuditReport();
  const showLoanApplicationActivityModal = (state: ModalState) => () => {
    setModalState(state);
    sendAudit({
      activityType: ActivityAuditType.Read,
      customerExternalId: state.customerExternalId,
      resourceAccessed: ResourceAccessed.ViewCustomerLoanApplication,
      queryDetails: JSON.stringify({ formBundleId: state.applicationId }),
    });
  };
  const { t } = useTranslation();
  const { permissions } = useAccess();
  return permissions.canViewActivityNotifications && permissions.canViewApplications ? (
    <div className="overflow-auto">
      <QuerySuspense
        error={error}
        isLoading={isLoading}
        noData={
          !customersLoanApplicationActivities ||
          customersLoanApplicationActivities.length === 0
        }
      >
        <ShowingLatestFive data-testid="show-top-five" />
        <DashboardList>
          {customersLoanApplicationActivities?.map(
            (customerLoanApplicationActivities) =>
              customerLoanApplicationActivities && (
                <DashboardList.Item key={customerLoanApplicationActivities?.id}>
                  <LoanApplicationActivity
                    customerLoanApplicationActivities={customerLoanApplicationActivities}
                    showLoanApplicationActivityModal={showLoanApplicationActivityModal}
                  />
                </DashboardList.Item>
              )
          )}
        </DashboardList>
      </QuerySuspense>
      {modalState && (
        <LoanApplicationActivitiesModal
          onClose={() => setModalState(null)}
          onBackToAll={() => setModalState(null)}
          customerName={modalState.customerName}
          applicationId={modalState.applicationId}
          customerId={modalState.customerId}
        />
      )}
    </div>
  ) : (
    <div className="warning m-2" data-testid="no-permissions-message">
      {t('auth.disallow')}
    </div>
  );
};
