import React from 'react';

import { PartiesSection, PartiesSectionType } from 'generated/graphql';
import { isArray as _isArray, isEmpty as _isEmpty } from 'lodash';

import { CustomisationConfig, useCustomisation } from 'modules/root/Settings';

import { PartiesCard, PartiesState } from '.';
import { PartiesListCustomer } from './PartiesModal/PartiesModalBody';
import { isLegalEntity } from './partiesUtils';

interface PartyItemProps {
  sections: PartiesSection[];
  parties: PartiesState;
}
export const getSectionName = (
  labels: CustomisationConfig['labels'],
  sectionType: PartiesSectionType
): string => {
  if (sectionType === PartiesSectionType.Borrower) {
    return labels.borrower;
  } else if (sectionType === PartiesSectionType.CoBorrowers) {
    return labels.coBorrowers;
  } else if (sectionType === PartiesSectionType.Guarantors) {
    return labels.guarantors;
  } else if (sectionType === PartiesSectionType.AccountOwnerDetails) {
    return labels.accountOwnerDetails;
  } else {
    throw new Error('Unknown selection for sectionType');
  }
};
export const PartyItem: React.FC<PartyItemProps> = ({ sections, parties }) => {
  const { labels } = useCustomisation();

  return (
    <div data-testid="party-items">
      {sections.map((section) => {
        const sectionType = section.type;
        const sectionName = getSectionName(labels, sectionType);
        const partiesBySection = parties?.[sectionType];
        if (partiesBySection && sectionType === PartiesSectionType.Borrower) {
          const party = partiesBySection as PartiesListCustomer;
          return (
            <PartiesCard
              key={party.id}
              relationship={sectionName}
              name={party.displayName}
              isLegal={isLegalEntity(party)}
            />
          );
        }

        return (
          partiesBySection &&
          !_isEmpty(partiesBySection) &&
          _isArray(partiesBySection) &&
          partiesBySection.map((party) => (
            <PartiesCard
              key={party.id}
              relationship={sectionName}
              name={party.displayName}
              isLegal={isLegalEntity(party)}
            />
          ))
        );
      })}
    </div>
  );
};
