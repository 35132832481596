import React from 'react';
import { useNavigate } from 'react-router';
import { generatePath, useParams } from 'react-router-dom';

import { BreadCrumb, BreadCrumbItemType, Button } from 'components';
import { useCustomerHeaderQuery } from 'generated/graphql';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';

import { useCustomisation, usePageTitle, usePaths } from 'modules/root/Settings';

interface EvaluationHeaderProps {
  list: BreadCrumbItemType[];
}

export const EvaluationHeader: React.FC<EvaluationHeaderProps> = ({ list }) => {
  const { customerId = 'customerId' } = useParams();
  const { data } = useQueryFetch(useCustomerHeaderQuery, {
    queryHookParams: {
      customerID: customerId,
    },
  });
  const { labels } = useCustomisation();

  usePageTitle([data?.customer.displayName || '', list[list.length - 1].label]);

  const paths = usePaths();
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(
      generatePath(paths.customer.main, {
        customerId: data?.customer?.id,
      })
    );
  };

  return (
    <>
      <BreadCrumb list={list} />
      <div className="grid grid-cols-3 mt-6">
        {data && (
          <>
            <h1
              className="font-bold text-2xl my-1 capitalize col-span-2 break-words text-left flex-wrap items-center"
              data-testid="customername"
            >
              {data?.customer.displayName}
            </h1>
            <div className="ml-2 whitespace-nowrap justify-self-center self-center">
              {data?.customer && (
                <Button
                  state="secondary"
                  onClick={handleClick}
                  data-testid="see-profile-btn"
                >
                  {labels.seeProfile}
                </Button>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};
