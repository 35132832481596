import React from 'react';
import {
  generatePath,
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router';

import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { useCustomisation, usePaths } from 'modules/root/Settings';

import { BreadcrumbAtom, breadcrumbsAtom } from '../models/breadcrumb';
import { selCustomerAtom } from '../models/customer';
import {
  EvaluationAction,
  EvaluationState,
  evaluationActionAtom,
  evaluationAtom,
  evaluationStateAtom,
} from '../models/evaluation';
import { ID_STEPS, currentStepAtom, stepsAtom } from '../models/step';

const useInitSteps = (action: EvaluationAction) => {
  const { t } = useTranslation();
  const {
    labels,
    creditPolicy: { allowCreditPolicySelection },
  } = useCustomisation();
  const [steps, setSteps] = useAtom(stepsAtom);
  const initialPath = React.useRef<string>();
  const isInitialised = React.useRef(false);
  const { pathname } = useLocation();
  const paths = usePaths();

  React.useEffect(() => {
    if (!initialPath.current) {
      initialPath.current = pathname;
    }

    const matchProceedNew = matchPath(
      paths.evaluationsV2.proceedNew,
      initialPath.current
    );
    const matchNew = matchPath(paths.evaluationsV2.new, initialPath.current);
    if (
      !isInitialised.current &&
      initialPath.current &&
      action === EvaluationAction.CreateNew
    ) {
      const initialSteps = [
        {
          id: ID_STEPS.Customer,
          label: labels.customer,
          enable: true,
          active: !!matchNew,
          valid: !matchNew,
        },
        {
          id: ID_STEPS.Accounts,
          label: labels.accounts,
          enable: !!matchProceedNew && !matchNew,
          active: !!matchProceedNew && !matchNew,
          valid: false,
        },
      ];

      if (allowCreditPolicySelection) {
        initialSteps.push({
          id: ID_STEPS.CreditPolicy,
          label: labels.creditPolicy,
          enable: false,
          active: false,
          valid: false,
        });
      }
      setSteps(initialSteps);
      isInitialised.current = true;
    }
  }, [labels, allowCreditPolicySelection, pathname, paths, setSteps, steps, t, action]);
};

const useInitBreadcrumbs = (action: EvaluationAction) => {
  const { labels } = useCustomisation();
  const evalState = useAtomValue(evaluationStateAtom);
  const paths = usePaths();
  const { customerId, evaluationId } = useParams();
  const { t } = useTranslation();

  const evaluation = useAtomValue(evaluationAtom);
  const evaluationAction = useAtomValue(evaluationActionAtom);
  const setBreadcrumbs = useSetAtom(breadcrumbsAtom);
  const selCustomer = useAtomValue(selCustomerAtom);

  React.useEffect(() => {
    const breadcrumbs: BreadcrumbAtom[] = [
      { link: generatePath(paths.home), label: labels.home },
      { link: generatePath(paths.origination.evaluations), label: labels.evaluations },
    ];
    if (evaluationAction === EvaluationAction.CreateNew) {
      breadcrumbs.push({
        label: labels.newEvaluation,
        link: generatePath(paths.evaluationsV2.new),
      });
      if (selCustomer) {
        breadcrumbs.push({
          label: selCustomer.displayName,
          link: generatePath(paths.evaluationsV2.proceedNew, {
            customerId: selCustomer.id,
          }),
        });
      }
    }

    if ([EvaluationAction.View, EvaluationAction.ReEvaluate].includes(evaluationAction)) {
      let breadcrumb =
        evaluation?.externalId === '0'
          ? `- ${t('evaluationV2.unsaved')}`
          : `#${evaluation?.externalId}`;
      if (evalState === EvaluationState.Saved) {
        breadcrumb = `- ${t('evaluationV2.saved')}`;
      }
      breadcrumbs.push({
        label: `${t('evaluationV2.existingEvaluation')} ${
          evaluation?.externalId ? breadcrumb : ''
        }`,
        link: '#',
      });
    }

    setBreadcrumbs(breadcrumbs);
  }, [
    selCustomer,
    evaluationAction,
    labels,
    paths,
    setBreadcrumbs,
    evaluation,
    customerId,
    evaluationId,
    t,
    evalState,
  ]);
};

const useSetCustomer = (action: EvaluationAction) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const {
    evaluationsV2: { proceedNew },
  } = usePaths();
  const currentStep = useAtomValue(currentStepAtom);
  const [breadcrumbs, setBreadcrumbs] = useAtom(breadcrumbsAtom);

  const selCustomer = useAtomValue(selCustomerAtom);

  const isPathWithActualCustomer = React.useMemo(() => {
    return (
      !!selCustomer?.id &&
      generatePath(proceedNew, { customerId: selCustomer.id }) === pathname
    );
  }, [selCustomer?.id, pathname, proceedNew]);

  React.useEffect(() => {
    if (
      !isPathWithActualCustomer &&
      currentStep?.id !== ID_STEPS.Customer &&
      selCustomer &&
      action === EvaluationAction.CreateNew
    ) {
      navigate(generatePath(proceedNew, { customerId: selCustomer.id }), {
        replace: true,
      });
      const newBreadcrumbs = [...breadcrumbs];
      if (newBreadcrumbs.length < 4) {
        newBreadcrumbs.push({
          link: generatePath(proceedNew, { customerId: selCustomer.id }),
          label: selCustomer.displayName,
        });
      } else {
        const lastBreadcrumb = newBreadcrumbs[newBreadcrumbs.length - 1];
        if (matchPath(proceedNew, lastBreadcrumb.link)) {
          newBreadcrumbs[newBreadcrumbs.length - 1] = {
            label: selCustomer.displayName,
            link: generatePath(proceedNew, { customerId: selCustomer.id }),
          };
        }
      }
      setBreadcrumbs(newBreadcrumbs);
    }
  }, [
    breadcrumbs,
    currentStep?.id,
    selCustomer,
    isPathWithActualCustomer,
    navigate,
    proceedNew,
    setBreadcrumbs,
    action,
  ]);
};

export const EvaluationSettings: React.FC = (props) => {
  const setAction = useSetAtom(evaluationActionAtom);
  const setEvaluationState = useSetAtom(evaluationStateAtom);
  const { pathname } = useLocation();
  const paths = usePaths();
  const matchView = matchPath(paths.evaluationsV2.view, pathname);
  const isView = !!matchView;
  const action = isView ? EvaluationAction.View : EvaluationAction.CreateNew;

  React.useEffect(() => {
    setAction(action);
    setEvaluationState(EvaluationState.Processing);
  }, [action, isView, setAction, setEvaluationState]);

  useInitSteps(action);
  useInitBreadcrumbs(action);
  useSetCustomer(action);
  return null;
};
