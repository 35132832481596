import { UiThemeConfig } from 'generated/graphql';

import { ColorCategory, ColorElement, ColorSchemaType } from './themeType';

const SHORTHAND_REGEX = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

const hexToRgb = (hex: string) => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  hex = hex.replace(SHORTHAND_REGEX, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}`
    : null;
};

const colorElements: ColorCategory = {
  color: {
    filterColors: [
      'primary',
      'secondary',
      'gray',
      'green',
      'red',
      'pink',
      'purple',
      'indigo',
      'cyan',
      'lime',
      'yellow',
      'orange',
    ],
    tints: ['_700', '_600', '_500', '_400', '_300', '_200', '_100', '_50'],
  },
};

const getColors = (
  startName: string,
  schema: UiThemeConfig['colorSchema'],
  colorElement: ColorElement
) => {
  const { filterColors, tints } = colorElement;
  let result = '';
  filterColors.forEach((color) => {
    const values = tints
      .map((tint) => {
        const colorT = schema[color];
        if (typeof colorT !== 'string') {
          const colorValue = colorT[tint];
          return `--${startName}-${color}-${tint.replace('_', '')}: ${hexToRgb(
            colorValue
          )}`;
        }
        return false;
      })
      .filter(Boolean);
    result += values.join(';') + ';';
  });
  return result;
};

const getMainColors = (colorSchema: ColorSchemaType) => {
  return `
    --color-primary-text: ${hexToRgb(colorSchema.primaryText)};
    --color-secondary-text: ${hexToRgb(colorSchema.secondaryText)};
    --color-disabled: ${hexToRgb(colorSchema.disabled)};
    --color-secondary-bg: ${hexToRgb(colorSchema.secondaryBg)};
    --color-primary-bg: ${hexToRgb(colorSchema.primaryBg)};`;
};

export const createCSSVariables = (theme: UiThemeConfig) => {
  const { colorSchema } = theme;
  const colorStyle = getColors('color', colorSchema, colorElements.color);
  const mainColorStyle = getMainColors(colorSchema);
  const style = document.createElement('style');
  style.innerHTML = `:root {${mainColorStyle}${colorStyle}`;
  document.head.appendChild(style);
};
