import React from 'react';

import { ThemeOwner, useCustomisationQuery } from 'generated/graphql';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';
import { useTranslation } from 'react-i18next';

import navIconTab from 'assets/logo/broadridge/broadridge-logo-tab.png';
import navLoginlogo from 'assets/logo/broadridge/broadridge-logo-white.svg';
import navMenuLogo from 'assets/logo/broadridge/broadridge-small.svg';
import pncLoginLogo from 'assets/logo/pnc/pnc-horizontal.svg';
import pncMenuLogo from 'assets/logo/pnc/pnc.svg';

import { CommonError } from 'modules/common/errors/CommonError';
import { GraphqlQueryError } from 'modules/common/errors/GraphqlQueryError';

import { createCSSVariables } from '../theme/themeConfiguration';
import { Branding, BrandingContext } from './contexts/branding';
import { CustomisationConfig, CustomisationContext } from './contexts/customisation';
import { NavigationContext, NavigationPaths, buildPaths } from './contexts/navigation';

const SettingsProvider: React.FC<React.PropsWithChildren<{}>> = React.memo(
  ({ children }) => {
    const { t } = useTranslation();
    const {
      data: schemaData,
      error,
      isFetching,
    } = useQueryFetch(useCustomisationQuery, { extra: { excludeToken: true } });
    const [urls, setUrls] = React.useState<NavigationPaths | null>(null);
    const [appConfig, setAppconfig] = React.useState<CustomisationConfig | null>(null);
    const [font, setFont] = React.useState<ThemeOwner>(ThemeOwner.Nav);
    const [branding, setBranding] = React.useState<Branding>({
      loginLogo: navLoginlogo,
      footerLogo: navLoginlogo,
      sideMenuLogo: navMenuLogo,
      owner: ThemeOwner.Nav,
    });

    React.useEffect(() => {
      const body = document.getElementsByTagName('body')[0];
      if (body) {
        body.className = `bg-main-secondary font-${font}`;
      }
    }, [font]);

    React.useEffect(() => {
      if (!isFetching) {
        if (schemaData?.applicationConfig) {
          setUrls(
            buildPaths({
              customers: schemaData.applicationConfig.sideMenu.customers.navKey,
              home: schemaData.applicationConfig.sideMenu.home.navKey,
              reports: schemaData.applicationConfig.sideMenu.reports.navKey,
              originations: schemaData.applicationConfig.sideMenu.originations.navKey,
            })
          );

          // app configuration
          setAppconfig({
            ...schemaData.applicationConfig,
            pdfHexColor: schemaData.applicationConfig.theme.colorSchema.primary._600,
          });

          // theme configuration
          setFont(schemaData.applicationConfig.theme.owner);
          // set color variables
          createCSSVariables(schemaData.applicationConfig.theme);
          // set logo variables
          const favicon = document.getElementById('favicon');
          const newFaviconLink = document.createElement('link');
          newFaviconLink.rel = 'icon';
          newFaviconLink.id = 'favicon';
          if (schemaData.applicationConfig.theme.owner === ThemeOwner.Nav) {
            newFaviconLink.href = navIconTab;
          } else if (schemaData.applicationConfig.theme.owner === ThemeOwner.Pnc) {
            newFaviconLink.href = pncMenuLogo;
            setBranding({
              sideMenuLogo: pncMenuLogo,
              loginLogo: pncLoginLogo,
              footerLogo: pncLoginLogo,
              owner: ThemeOwner.Pnc,
            });
          }
          favicon?.parentNode?.replaceChild(newFaviconLink, favicon);
        }
      }
    }, [schemaData, isFetching]);

    if (error) {
      const errorMsg: string =
        (error as GraphqlQueryError).response?.errors
          ?.map((e: any) => e?.message)
          ?.filter((e: string) => !!e)
          ?.join(',') || t('common.error.default');
      throw new CommonError('ConfigurationProviderError', errorMsg);
    }

    if (!appConfig) {
      return null;
    }

    if (!schemaData) {
      throw new CommonError(
        'ConfigurationProviderError',
        'data from query is not provided'
      );
    }
    return (
      <CustomisationContext.Provider value={appConfig}>
        <BrandingContext.Provider value={branding}>
          {urls && (
            <NavigationContext.Provider value={urls}>
              {children}
            </NavigationContext.Provider>
          )}
        </BrandingContext.Provider>
        {/* Tailwind should know about all possible fonts to generate all for them */}
        <div className="hidden font-nav">{'Generating nav fonts'}</div>
        <div className="hidden font-pnc">{'Generating pnc fonts'}</div>
      </CustomisationContext.Provider>
    );
  }
);

SettingsProvider.displayName = 'PureSettingsProvider';

export { SettingsProvider };
