import React from 'react';

import { ReactComponent as Skeleton } from 'assets/icons/skeleton.svg';

export const TableSkeleton: React.FC = () => {
  return (
    <div className="w-[calc(100vw-148px)] lg:w-[calc(100vw-248px)] xl:w-full overflow-hidden">
      <Skeleton
        data-testid="table-skeleton"
        className="animate-pulse h-[400px] xl:h-full"
      />
    </div>
  );
};
