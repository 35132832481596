import { LoanProposalRates } from 'generated/graphql';

export const isLoanAmountWithinRange = (
    loanAmount: number,
    rate: LoanProposalRates
  ): boolean => {
    return !!(
      (rate.from === null && rate.to && loanAmount <= rate.to) ||
      (rate.to === null && rate.from && loanAmount >= rate.from) ||
      (rate.from && rate.to && loanAmount >= rate.from && loanAmount <= rate.to)
    );
  };
  
export const getCalculatedRate = (
  ratesConfig: LoanProposalRates[],
  loanAmount: number,
  loanTerm: string,
  loanType: string,
  proposedPricing: string,
  interestRateType: string
): LoanProposalRates | undefined => {
  const calculatedRate = ratesConfig.find((rate) => {
    return !!(
      isLoanAmountWithinRange(loanAmount, rate) &&
      rate.loanType.toLocaleLowerCase() === loanType.toLocaleLowerCase() &&
      ((rate.loanTerm === null && !loanTerm) ||
        rate.loanTerm?.toLocaleLowerCase() === loanTerm?.toLocaleLowerCase()) &&
      rate.interestRateType.toLocaleLowerCase() ===
        interestRateType.toLocaleLowerCase() &&
      ((rate.loanIndex === null && !proposedPricing) ||
        rate.loanIndex?.toLocaleLowerCase() === proposedPricing?.toLocaleLowerCase())
    );
  });
  return calculatedRate;
};

export const getSpreadValue = (
  ratesConfig: LoanProposalRates[],
  loanAmount: number,
  loanTerm: string,
  loanType: string,
  proposedPricing: string,
  interestRateType: string
): string => {
  const rate = getCalculatedRate(ratesConfig, loanAmount, loanTerm, loanType, proposedPricing, interestRateType);
  return rate?.spread?.toString() || '';
};

export const getInterestRate = (
  ratesConfig: LoanProposalRates[],
  loanAmount: number,
  loanTerm: string,
  loanType: string,
  proposedPricing: string,
  interestRateType: string
): string | null => {
  const rate = getCalculatedRate(ratesConfig, loanAmount, loanTerm, loanType, proposedPricing, interestRateType);
  return rate?.rates || null;
};
