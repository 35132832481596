import React from 'react';
import { useNavigate } from 'react-router';
import { generatePath, useParams } from 'react-router-dom';

import { BreadCrumbItemType, Card, Content } from 'components';
import {
  AccountSource,
  EvaluationCollateralAccountPayload,
  EvaluationInput,
  RunEvaluationInput,
  useGetEvaluationQuery,
} from 'generated/graphql';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT_TIME, formatDate } from 'utils';

import { EvaluationHeader } from 'modules/common/EvaluationHeader/EvaluationHeader';
import { QuerySuspense } from 'modules/common/QuerySuspense/QuerySuspense';
import { useCustomisation, usePaths } from 'modules/root/Settings';
import { useBlockNavigation } from 'utils/blockNavigation';

import { useEvaluationContext } from './Evaluation';
import { EvaluationActions, UIEvaluationStatus } from './NewEvaluation';
import { LeaveEvaluationModal } from './modals/LeaveEvaluationModal';
import { AccountTable } from './sections/AccountTable';
import { EvaluationInfo } from './sections/EvaluationInfo';
import { ExistingEvaluationSummary } from './sections/Summary/ExistingEvaluationSummary';
import { NewEvaluationSummary } from './sections/Summary/NewEvaluationSummary';
import {
  EvaluationErrorMessage,
  EvaluationLoading,
} from './sections/Summary/QueryHandlers';
import { saveEvaluationResolver } from './sections/Summary/formResolvers';
import { Account } from './types';

export const ReEvaluation: React.FC = () => {
  const { customerId = 'customerId', evaluationId = 'evaluationId' } = useParams();
  const { accountList } = useEvaluationContext();
  const [evaluationStatus, setEvaluationStatus] = React.useState<UIEvaluationStatus>(
    UIEvaluationStatus.Idle
  );
  const [runEvaluationPayload, setRunEvaluationPayload] =
    React.useState<RunEvaluationInput>();

  const evaluationAction = EvaluationActions.ReEvaluate;
  const {
    data: existingEvaluationData,
    error: existingEvaluationError,
    isLoading: existingEvaluationLoading,
    refetch: existingEvaluationRefetch,
  } = useQueryFetch(useGetEvaluationQuery, {
    queryHookParams: { evaluationId },
  });
  const navigate = useNavigate();
  const [showLeaveModal, setShowLeaveModal] = React.useState(false);
  const handleBlock = React.useCallback(() => setShowLeaveModal(true), []);
  const isWorkCanBeLost = [
    UIEvaluationStatus.Processing,
    UIEvaluationStatus.Ran,
    UIEvaluationStatus.SaveFailed,
  ].includes(evaluationStatus);
  const { unblock } = useBlockNavigation(isWorkCanBeLost, handleBlock);
  const { t } = useTranslation();
  const paths = usePaths();

  const { evaluation } = useCustomisation();
  const form = useForm<EvaluationInput>({
    defaultValues: {
      id: '',
      label: '',
      compareTo: evaluation.compareTo,
      customerId: customerId,
      customerName: accountList.data?.customer.displayName,
      collateralAccountIds: [],
      lastEvaluated: '',
      description: '',
    },
    resolver: saveEvaluationResolver,
    mode: 'onBlur',
  });

  React.useEffect(() => {
    if (accountList.data?.customer.displayName) {
      form.setValue('customerName', accountList.data?.customer.displayName);
    }
  }, [accountList.data?.customer.displayName, form]);

  const runReevaluation = (
    accounts: EvaluationCollateralAccountPayload[],
    creditPolicyId: string
  ) => {
    form.setValue('creditPolicyId', creditPolicyId);
    setRunEvaluationPayload({
      label: '',
      persist: false,
      creditPolicyId,
      evaluationCollateralAccounts: accounts,
    });
    setEvaluationStatus(UIEvaluationStatus.Processing);
  };

  const isEvaluationRAN = ![
    UIEvaluationStatus.Idle,
    UIEvaluationStatus.Processing,
    UIEvaluationStatus.RunFailed,
  ].includes(evaluationStatus);

  const handleLeaveEvaluationModalClose = (choose: 'cancel' | 'leave') => {
    if (choose === 'leave') {
      unblock().then((blockedPath) => {
        navigate(blockedPath);
      });
    } else {
      setShowLeaveModal(false);
    }
  };
  const { labels, sideMenu } = useCustomisation();

  const breadcrumbList: BreadCrumbItemType[] = [];

  if (sideMenu.home.show) {
    breadcrumbList.push({ label: labels.home, link: generatePath(paths.home) });
  }
  if (sideMenu.originations.show) {
    breadcrumbList.push({
      label: labels.originations,
      link: generatePath(paths.origination.list),
    });
  }
  breadcrumbList.push({
    label: t('evaluations.reEvaluateExistingEvaluation'),
    link: generatePath(paths.evaluations.view, { customerId, evaluationId }),
  });

  const evaluatedAccounts: Account[] = React.useMemo(() => {
    return (
      existingEvaluationData?.evaluation?.evaluationCollateralAccount.map<Account>(
        (account) => ({
          id: account.collateralAccountId || '',
          collateralAccountId: account.externalAccountId,
          accountType: account.accountType,
          custodian: account.custodian,
          ownerDisplayName: account.ownerDisplayName,
          marketData: { marketValue: account.marketValue },
          pledged: account.pledged,
          source: account.source,
        })
      ) || []
    );
  }, [existingEvaluationData]);

  const hasManualAccounts: boolean = React.useMemo(() => {
    return (
      existingEvaluationData?.evaluation?.evaluationCollateralAccount.some(
        (account) => account.source === AccountSource.Manual
      ) || false
    );
  }, [existingEvaluationData]);

  return (
    <FormProvider {...form}>
      <form>
        <Content header={<EvaluationHeader list={breadcrumbList} />}>
          <div className="my-6 lg:my-10 mx-8 lg:mx-12 xl:mx-20">
            {evaluationStatus === UIEvaluationStatus.Idle && existingEvaluationData && (
              <div className="flex flex-row gap-4 text-base lg:text-lg">
                <div className="my-4 basis-1/2">{`${t('evaluations.label')}: ${
                  existingEvaluationData.evaluation?.label
                }`}</div>
                <div className="my-4 basis-1/2">{`${t(
                  'evaluations.lastEvaluated'
                )}:  ${formatDate(
                  existingEvaluationData.evaluation?.lastEvaluated || '',
                  DATE_FORMAT_TIME
                )}`}</div>
              </div>
            )}
            {isEvaluationRAN && (
              <EvaluationInfo readOnly={evaluationStatus === UIEvaluationStatus.Saved} />
            )}
            <QuerySuspense
              error={accountList.error || existingEvaluationError}
              isLoading={accountList.isLoading || existingEvaluationLoading}
              loadingRenderer={() => <></>}
            >
              {accountList.data?.customer && (
                <Card className="p-6">
                  <>
                    <AccountTable
                      collateralAccounts={evaluatedAccounts}
                      runEvaluation={runReevaluation}
                      evaluationStatus={evaluationStatus}
                      viewOnly={hasManualAccounts}
                      evaluationAction={evaluationAction}
                      evaluatedCreditPolicyId={
                        existingEvaluationData?.evaluation?.creditPolicyId || undefined
                      }
                    />

                    {hasManualAccounts && (
                      <div
                        data-testid="has-manual-accounts-error-msg"
                        className="warning"
                      >
                        {t('evaluations.accountList.hasManualAccountsError')}
                      </div>
                    )}
                  </>
                </Card>
              )}
            </QuerySuspense>
            {runEvaluationPayload && evaluationStatus !== UIEvaluationStatus.Idle && (
              <Card className="my-5 p-6">
                <NewEvaluationSummary
                  status={evaluationStatus}
                  updateEvaluationStatus={setEvaluationStatus}
                  evaluationAction={evaluationAction}
                  runEvaluationPayload={runEvaluationPayload}
                  customerExternalId={accountList.data?.customer.customerId}
                />
              </Card>
            )}

            {evaluationStatus === UIEvaluationStatus.Idle && (
              <QuerySuspense
                isLoading={existingEvaluationLoading}
                error={existingEvaluationError}
                loadingRenderer={() => <EvaluationLoading />}
                errorRenderer={() => (
                  <EvaluationErrorMessage rerunEvaluation={existingEvaluationRefetch} />
                )}
              >
                {existingEvaluationData && (
                  <Card className="my-5 p-6">
                    <ExistingEvaluationSummary evaluationData={existingEvaluationData} />
                  </Card>
                )}
              </QuerySuspense>
            )}
          </div>
        </Content>
      </form>
      {showLeaveModal && (
        <LeaveEvaluationModal onClose={handleLeaveEvaluationModalClose} />
      )}
    </FormProvider>
  );
};
