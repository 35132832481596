import React from 'react';
import { Navigate } from 'react-router-dom';

import { usePaths } from 'modules/root/Settings';
import { useAuth } from 'modules/root/auth/auth.context';

import { PermissionProvider } from './Permission/PermissionProvider';

interface AuthenticatedProps {
  children: React.ReactNode;
}

const Authenticated: React.FC<AuthenticatedProps> = React.memo(({ children }) => {
  const paths = usePaths();
  const { isAuthenticated, isLoading, originPathKey } = useAuth();

  if (isLoading) {
    return null;
  }

  if (!isAuthenticated) {
    localStorage.setItem(originPathKey, window.location.pathname);
    return <Navigate to={paths.auth.signIn} replace={true} />;
  }

  return <PermissionProvider>{children}</PermissionProvider>;
});

Authenticated.displayName = 'PureAuthenticated';

export { Authenticated };
