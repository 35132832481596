import React from 'react';
import { useParams } from 'react-router';

import {
  AccountSource,
  ActivityAuditType,
  GetEvaluationQuery,
  RunEvaluationQuery,
  useGetEvaluationQuery,
  useRunEvaluationQuery,
} from 'generated/graphql';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';
import { DATE_FORMAT_DASH_SEPARATED, DATE_FORMAT_TIME, formatDate } from 'utils';

import { evaluationInputAccountsAtom } from 'modules/EvaluationV2/models/account';
import { creditPolicyIdAtom } from 'modules/EvaluationV2/models/creditPolicy';
import { selCustomerAtom } from 'modules/EvaluationV2/models/customer';
import {
  EvaluationAction,
  EvaluationState,
  saveDownloadStateAtom,
  evaluationActionAtom,
  evaluationAtom,
  evaluationDetailsAtom,
  evaluationStateAtom,
  payloadAtom,
  saveEvaluationInputAtom,
} from 'modules/EvaluationV2/models/evaluation';
import { useCustomisation } from 'modules/root/Settings';

export const useEvaluationQuery = () => {
  const [payload] = useAtom(payloadAtom);
  const customerExternalId = useAtomValue(selCustomerAtom)?.customerId;
  const [evalState, setEvalState] = useAtom(evaluationStateAtom);
  const setEvaluation = useSetAtom(evaluationAtom);
  const setEvaluationInputAccounts = useSetAtom(evaluationInputAccountsAtom);
  const [action, setAction] = useAtom(evaluationActionAtom);
  const { evaluationId = '' } = useParams();
  const selCustomer = useAtomValue(selCustomerAtom);
  const setCreditPolicy = useSetAtom(creditPolicyIdAtom);
  const [saveEvaluationInput, setSaveEvaluationInput] = useAtom(saveEvaluationInputAtom);
  const { evaluation: evaluationCustomisation } = useCustomisation();
  const setEvaluationDetailsAtom = useSetAtom(evaluationDetailsAtom);
  const setSaveDownloadState = useSetAtom(saveDownloadStateAtom);

  const extra = {
    auditReport: {
      activityType: ActivityAuditType.Read,
      customerExternalId,
    },
  };
  const {
    error: errorGet,
    isFetching: isFetchingGet,
    isSuccess: isViewSucceed,
  } = useQueryFetch(useGetEvaluationQuery, {
    queryHookParams: { evaluationId },
    queryHookOptions: {
      enabled:
        evalState === EvaluationState.Processing && action === EvaluationAction.View,
      onSuccess: (data) => {
        const evaluationData = (data as GetEvaluationQuery).evaluation;
        setEvalState(EvaluationState.Idle);
        setEvaluation(evaluationData);
        setCreditPolicy(evaluationData?.creditPolicyId || null);
        setEvaluationInputAccounts(
          evaluationData?.evaluationCollateralAccount.map((account) => {
            return {
              ...account,
              source: account.source || AccountSource.System,
            };
          }) || null
        );
        setEvaluationDetailsAtom({
          label: evaluationData?.label,
          lastEvaluated: evaluationData?.lastEvaluated,
        });
        setSaveDownloadState({
          enableDownloadPdf: true,
          enableApplyForLoan: false,
          evaluationId: evaluationData?.id || null,
          externalEvalId: null
        });
      },
      onError: () => {
        setEvalState(EvaluationState.RunFailed);
      },
    },
    extra,
  });

  React.useEffect(() => {
    // Once we get saved evaluation, the userc can re-evaluate existing one
    if (isViewSucceed) {
      setAction(EvaluationAction.ReEvaluate);
    }
  }, [isViewSucceed, setAction]);

  const { error, isFetching } = useQueryFetch(useRunEvaluationQuery, {
    queryHookParams: { runEvaluation: payload },
    queryHookOptions: {
      enabled:
        evalState === EvaluationState.Processing &&
        (action === EvaluationAction.CreateNew || action === EvaluationAction.ReEvaluate),
      onSuccess: (data) => {
        setEvalState(EvaluationState.Ran);
        setEvaluation((data as RunEvaluationQuery).runEvaluation);
        const currentDateTime = new Date().toUTCString();
        setSaveEvaluationInput({
          ...saveEvaluationInput,
          compareTo: evaluationCustomisation.compareTo,
          creditPolicyId: payload.creditPolicyId,
          customerId: selCustomer?.id || '',
          customerName: selCustomer?.displayName || '',
          evaluationCollateralAccounts: payload.evaluationCollateralAccounts,
          lastEvaluated: formatDate(currentDateTime, DATE_FORMAT_DASH_SEPARATED),
          label: `Evaluation: ${formatDate(currentDateTime, DATE_FORMAT_TIME)}`,
          description: formatDate(currentDateTime, DATE_FORMAT_TIME),
        });
        setSaveDownloadState(null);
      },
      onError: () => {
        setEvalState(EvaluationState.RunFailed);
        setSaveDownloadState(null);
      },
    },
    extra,
  });
  return {
    error: errorGet || error,
    isFetching: isFetchingGet || isFetching,
  };
};
