import React from 'react';

import { createColumnHelper } from '@tanstack/react-table';
import { Ellipsis, ReactTable } from 'components';
import { UnpledgedAccountsQuery } from 'generated/graphql';
import { ArrayElement, printPrimitive } from 'utils';

import { useCustomisation } from 'modules/root/Settings';
import { notEmpty } from 'utils/helpers';

type Accounts = NonNullable<UnpledgedAccountsQuery['unpledgedAccounts']>['records'];
type Account = ArrayElement<Accounts>;
type Holding = ArrayElement<NonNullable<Account['account']>['assetHoldings']>;

export interface HoldingsProps {
  holdings: Holding[];
}

export const Holdings: React.FC<HoldingsProps> = ({ holdings }) => {
  const { labels } = useCustomisation();
  const columnHelper = createColumnHelper<NonNullable<Holding>>();
  const columns = React.useMemo(() => {
    return [
      columnHelper.accessor('securityId', {
        header: labels.securityId,
        cell: ({ getValue }) => <Ellipsis>{printPrimitive(getValue())}</Ellipsis>,
      }),
      columnHelper.accessor('quantity', {
        header: labels.units,
        cell: ({ getValue }) => <Ellipsis>{printPrimitive(getValue())}</Ellipsis>,
      }),
    ];
  }, [columnHelper, labels.securityId, labels.units]);
  return (
    <ReactTable<NonNullable<Holding>>
      data={holdings.filter(notEmpty)}
      columns={columns}
      data-testid="acquired-accounts-table"
    />
  );
};
