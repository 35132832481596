import { useDownloadFile } from './fileDownload';

export const useDownloadPdf = () => {
  const { download, isError, isLoading } = useDownloadFile();

  return {
    download: (urlPath: string, filename: string) =>
      download(urlPath, `${filename}.pdf`, 'application/pdf'),
    isError,
    isLoading,
  };
};
