import Highcharts from 'highcharts';

import { palette, visualization } from 'modules/root/theme/visualization';
import { money } from 'utils/currency';

export type LoanValue = {
  name: string;
  value: number;
};

type BorderType = 'both' | 'left' | 'right' | 'none';

const getSerie = (
  collateralValue: number,
  loanValue: LoanValue,
  border: BorderType
): Highcharts.SeriesOptionsType => {
  const value = Math.ceil((loanValue.value / collateralValue) * 100);

  let borderRadiusLeft = '2px';
  let borderRadiusRight = '2px';
  if (border === 'left') {
    borderRadiusRight = '0';
  } else if (border === 'right') {
    borderRadiusLeft = '0';
  } else if (border === 'none') {
    borderRadiusRight = '0';
    borderRadiusLeft = '0';
  }

  return {
    type: 'column',
    name: loanValue.name,
    enableMouseTracking: false,
    index: 2,
    legendIndex: 0,
    data: [value],
    states: {
      hover: {
        enabled: false,
      },
    },

    borderRadiusBottomLeft: borderRadiusLeft,
    borderRadiusBottomRight: borderRadiusLeft,
    borderRadiusTopLeft: borderRadiusRight,
    borderRadiusTopRight: borderRadiusRight,
  };
};

export const getOptions = (
  loanValues: LoanValue[],
  collateralValue: number
): Highcharts.Options => {
  const height = 100 + 44 * loanValues.length;
  const marginTop = loanValues.length * -44;
  return {
    credits: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    chart: {
      type: 'column',
      backgroundColor: 'transparent',
      inverted: true,
      height,
      marginTop,
      marginLeft: 5,
      marginRight: 5,
      plotBorderWidth: 0,
    },
    title: {
      text: '',
    },
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: false,
      min: 0,
      max: 100,
    },
    colors: [
      visualization.optionC.colorA,
      visualization.optionC.colorB,
      visualization.optionC.colorC,
      visualization.optionC.colorD,
      visualization.optionC.colorE,
    ],
    legend: {
      labelFormatter() {
        const apportionmentAmount = loanValues[this.index].value;
        let percent = (apportionmentAmount / collateralValue) * 100;
        if (percent >= 1) {
          percent = Math.ceil(percent);
        } else {
          percent = parseFloat(percent.toFixed(2));
        }
        return `<span class="block ml-5 text-gray-500 text-sm">${
          this.name
        }</span><span class="block font-bold text-sm">${money.format(
          apportionmentAmount
        )} (${percent.toString()}%)</span>`;
      },
      useHTML: true,
      layout: 'vertical',
      align: 'left',
      verticalAlign: 'bottom',
      y: -4,
      x: -20,
      itemMarginBottom: 8,
      itemStyle: {
        cursor: 'default',
        fontWeight: 'normal',
        fontSize: '12px',
        color: palette.darker,
        marginBottom: '8px',
      },
      squareSymbol: true,
      symbolHeight: 10,
      symbolRadius: 5,
      symbolPadding: -10,
    },
    plotOptions: {
      column: {
        minPointLength: 5,
        stacking: 'normal',
        borderWidth: 0.001,
        pointWidth: 10,
      },
      series: {
        states: {
          inactive: {
            opacity: 1,
          },
          hover: {
            enabled: false,
          },
        },
        events: {
          legendItemClick: (e) => e.preventDefault(),
        },
        marker: {
          enabled: false,
        },
      },
    },
    series: loanValues.map((loanValue, index) => {
      let border: BorderType = 'none';
      if (index === 0 && index === loanValues.length - 1) {
        border = 'both';
      } else if (index === 0) {
        border = 'right';
      } else if (index === loanValues.length - 1) {
        border = 'left';
      }
      return getSerie(collateralValue, loanValue, border);
    }),
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
  };
};

export const getAfterRender = (loanCnt: number): Highcharts.ChartCallbackFunction => {
  const afterRender: Highcharts.ChartCallbackFunction = (chart) => {
    // Place the external rect ellipse for the plot
    // eslint-disable-next-line no-param-reassign
    chart.stackedChartOuterRect = chart.renderer
      .rect(
        1,
        chart.series[0].data[0].plotX
          ? chart.series[0].data[0].plotX - loanCnt * 44 - 7
          : 0,
        chart.chartWidth - 2,
        15,
        4,
        1
      )
      .attr({
        'stroke-width': 1,
        stroke: palette.gray400,
        fill: 'transparent',
        zIndex: 1,
      })
      .add();
  };
  return afterRender;
};
