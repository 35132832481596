import React from 'react';

import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontIcon } from 'components';

export interface ModalTitleProps {
  onClose: () => void;
  title: string;
}

export const ModalTitle: React.FC<ModalTitleProps> = ({ onClose, title }) => {
  return (
    <div className="flex justify-between px-10 py-6 ">
      <div>{title}</div>
      <FontIcon className="cursor-pointer" onClick={onClose} icon={faXmark} />
    </div>
  );
};
