import React from 'react';

import { Dropdown } from 'components';
import {
  ActivityAuditType,
  CreditPoliciesQuery,
  useCreditPoliciesQuery,
} from 'generated/graphql';
import { useAtom } from 'jotai';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';

import {
  creditPoliciesAtom,
  creditPolicyIdAtom,
} from 'modules/EvaluationV2/models/creditPolicy';
import { QuerySuspense } from 'modules/common/QuerySuspense/QuerySuspense';
import { useCustomisation } from 'modules/root/Settings/contexts/customisation';

interface CreditPolicyDropdownProps {
  disabled?: boolean;
}

export const CreditPolicyDropdown: React.FC<CreditPolicyDropdownProps> = ({
  disabled = false,
}) => {
  const { labels } = useCustomisation();
  const [creditPolicyId, setCreditPolicyId] = useAtom(creditPolicyIdAtom);
  const [creditPolicies, setCreditPolicies] = useAtom(creditPoliciesAtom);
  const { isLoading, isError } = useQueryFetch(useCreditPoliciesQuery, {
    queryHookOptions: {
      enabled: !creditPolicies.length,
      onSuccess: (data) => {
        const creditPolicies = (data as CreditPoliciesQuery).creditPolicies;
        setCreditPolicies(creditPolicies);
      },
    },
    extra: {
      auditReport: { activityType: ActivityAuditType.Read },
    },
  });

  const creditPolicy = creditPolicies.find((p) => p?.id === creditPolicyId);

  return (
    <QuerySuspense
      error={isError}
      isLoading={isLoading}
      loadingRenderer={() => (
        <div className="w-full h-16 bg-gray-100 animate-pulse rounded-md" />
      )}
    >
      <Dropdown
        label={creditPolicyId ? '' : labels.chooseCreditPolicy}
        onChange={(value) => {
          if (typeof value !== 'string') {
            setCreditPolicyId(value.key);
          } else if (!value) {
            setCreditPolicyId(null);
          }
        }}
        value={creditPolicy?.description.value || ''}
        options={
          creditPolicies.map((policy) => ({
            key: policy?.id || '',
            value: policy?.description.value || '',
          })) || []
        }
        disabled={disabled}
      ></Dropdown>
    </QuerySuspense>
  );
};
