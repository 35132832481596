export const palette = {
  text: 'rgb(var(--color-primary-text))',
  darker: 'rgb(var(--color-darker))',
  gray200: 'rgb(var(--color-gray-200))',
  gray400: 'rgb(var(--color-gray-400))',
  gray700: 'rgb(var(--color-gray-700))',
  primary600: 'rgb(var(--color-primary-600))',
  primary400: 'rgb(var(--color-primary-400))',
  yellow300: 'rgb(var(--color-yellow-300))',
  red600: 'rgb(var(--color-red-600))',
  white: '#fff',
};

export const visualization = {
  optionA: {
    colorA: 'rgb(var(--color-primary-600))',
    colorB: 'rgb(var(--color-purple-200))',
    colorC: 'rgb(var(--color-cyan-500))',
    colorD: 'rgb(var(--color-orange-300))',
    colorE: 'rgb(var(--color-green-400))',
  },
  optionB: {
    colorA: 'rgb(var(--color-green-700))',
    colorB: 'rgb(var(--color-indigo-400))',
    colorC: 'rgb(var(--color-green-300))',
    colorD: 'rgb(var(--color-red-500))',
    colorE: 'rgb(var(--color-lime-200))',
  },
  optionC: {
    colorA: 'rgb(var(--color-primary-700))',
    colorB: 'rgb(var(--color-indigo-400))',
    colorC: 'rgb(var(--color-primary-300))',
    colorD: 'rgb(var(--color-orange-400))',
    colorE: 'rgb(var(--color-orange-200))',
  },
  optionD: {
    colorA: 'rgb(var(--color-lime-400))',
    colorB: 'rgb(var(--color-lime-600))',
    colorC: 'rgb(var(--color-cyan-500))',
    colorD: 'rgb(var(--color-indigo-600))',
    colorE: 'rgb(var(--color-lime-200))',
  },
  optionE: {
    colorA: 'rgb(var(--color-primary-100))',
    colorB: 'rgb(var(--color-pink-500))',
    colorC: 'rgb(var(--color-orange-500))',
    colorD: 'rgb(var(--color-pink-700))',
    colorE: 'rgb(var(--color-primary-300))',
  },
};
