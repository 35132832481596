import React from 'react';
import { generatePath, useNavigate, useParams } from 'react-router';

import { Button, LoadedButton } from 'components';
import {
  ActivityAuditType,
  EvaluationInput,
  RunEvaluationInput,
  SaveEvaluationMutation,
  useAuditReportMutation,
  useRunEvaluationQuery,
} from 'generated/graphql';
import { graphqlClient, useAuthClient } from 'queries/apiFetch/graphqlClient';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDownloadPdf } from 'services';
import { ResourceAccessed } from 'services/auditReport';

import { QuerySuspense } from 'modules/common/QuerySuspense/QuerySuspense';
import { usePaths } from 'modules/root/Settings';
import { useAccess } from 'modules/root/auth/Authenticated/Permission/permission.context';
import {
  DATE_FORMAT_DASH_SEPARATED,
  DATE_FORMAT_TIME,
  DATE_FORMAT_UNDERSCORE,
  formatDate,
} from 'utils/date';

import { EvaluationActions, UIEvaluationStatus } from '../../NewEvaluation';
import { SaveEvaluationModal } from '../../modals/SaveEvaluationModal';
import { EvaluationErrorMessage, EvaluationLoading } from './QueryHandlers';
import { SummaryTable } from './SummaryTable';

interface EvaluationSummaryProps {
  status: UIEvaluationStatus;
  evaluationAction?: EvaluationActions;
  runEvaluationPayload: RunEvaluationInput;
  customerExternalId?: string;
  updateEvaluationStatus: (status: UIEvaluationStatus) => void;
}

export const NewEvaluationSummary: React.FC<EvaluationSummaryProps> = ({
  status,
  updateEvaluationStatus,
  evaluationAction,
  runEvaluationPayload,
  customerExternalId,
}) => {
  const { setValue } = useFormContext<EvaluationInput>();
  const { t } = useTranslation();
  const { customerId } = useParams();
  const {
    data,
    error,
    isLoading,
    isSuccess,
    refetch: rerunEvaluation,
  } = useQueryFetch(useRunEvaluationQuery, {
    queryHookParams: { runEvaluation: runEvaluationPayload },
    extra: {
      auditReport: {
        activityType: ActivityAuditType.Read,
        customerExternalId,
      },
    },
  });
  const [savedEvaluationData, setSavedEvaluationData] =
    React.useState<SaveEvaluationMutation | null>(null);
  const { permissions } = useAccess();

  const {
    download,
    isError: pdfDownloadError,
    isLoading: pdfIsLoading,
  } = useDownloadPdf();

  const paths = usePaths();
  const navigate = useNavigate();
  const handleStartApplication = () => {
    mutateAsync({
      activityType: ActivityAuditType.Read,
      customerExternalId,
      resourceAccessed: ResourceAccessed.LoanProposal,
      queryDetails: JSON.stringify({
        externalId: savedEvaluationData?.saveEvaluation?.externalId,
      }),
    });
    navigate(
      generatePath(paths.evaluations.startapplication, {
        customerId: customerId,
        evaluationId: savedEvaluationData?.saveEvaluation?.id,
      })
    );
  };

  React.useEffect(() => {
    if (isSuccess) {
      const currentDateTime = new Date().toUTCString();
      updateEvaluationStatus(UIEvaluationStatus.Ran);
      setValue('label', `Evaluation: ${formatDate(currentDateTime, DATE_FORMAT_TIME)}`);
      setValue('description', formatDate(currentDateTime, DATE_FORMAT_TIME));
      setValue(
        'lastEvaluated',
        `${formatDate(currentDateTime, DATE_FORMAT_DASH_SEPARATED)}`
      );
    }
    if (error) {
      updateEvaluationStatus(UIEvaluationStatus.RunFailed);
    }
  }, [isSuccess, error, setValue, updateEvaluationStatus]);

  const isNotValidMaxCommitmentAmount = data?.runEvaluation?.commitmentAmount === 0;

  const [showSaveModal, setShowSaveModal] = React.useState(false);

  const handleSaveModalClose = (
    status?: 'failed' | 'saved',
    responseData?: SaveEvaluationMutation
  ) => {
    setShowSaveModal(false);

    if (status === 'saved' && responseData) {
      updateEvaluationStatus(UIEvaluationStatus.Saved);
      setSavedEvaluationData(responseData);
    } else if (status === 'failed') {
      updateEvaluationStatus(UIEvaluationStatus.SaveFailed);
      setSavedEvaluationData(null);
    }
  };

  const authClient = useAuthClient(graphqlClient);
  const { mutateAsync } = useAuditReportMutation(authClient);

  const handleDownloadPdf = (id: string) => {
    const fileName = `Evaluation-${id}-${formatDate(
      new Date().toUTCString(),
      DATE_FORMAT_UNDERSCORE
    )}`;
    const urlPath = `/schema/valuations/${id}/pdf`;
    download(urlPath, fileName)
      .then(() =>
        mutateAsync({
          activityType: ActivityAuditType.Read,
          customerExternalId,
          resourceAccessed: ResourceAccessed.DownloadEvaluationPdf,
          queryDetails: JSON.stringify({ evaluationId: id }),
        })
      )
      .catch(() => {
        mutateAsync({
          activityType: ActivityAuditType.Read,
          customerExternalId,
          resourceAccessed: ResourceAccessed.DownloadEvaluationPdf,
          queryDetails: JSON.stringify({ evaluationId: id, error: true }),
        });
      });
  };

  return (
    <QuerySuspense
      error={error}
      isLoading={isLoading}
      noData={!data}
      errorRenderer={() => <EvaluationErrorMessage rerunEvaluation={rerunEvaluation} />}
      loadingRenderer={() => <EvaluationLoading />}
    >
      {data?.runEvaluation && (
        <div>
          <h2>{t('evaluations.summary.title')}</h2>
          {data.runEvaluation && <SummaryTable evaluation={data.runEvaluation} />}
          {isNotValidMaxCommitmentAmount && (
            <div
              className="warning mt-6"
              data-testid="not-eligible-commitment-amount-msg"
            >
              {t('evaluations.summary.notEligibleCommitmentAmount')}
            </div>
          )}
          <div className="flex flex-row my-6">
            <div className="basis-1/2">
              <Button
                className="mr-5"
                onClick={() => setShowSaveModal(true)}
                state="secondary"
                disabled={status === UIEvaluationStatus.Saved}
              >
                {t('evaluations.summary.saveEvaluation')}
              </Button>
              {permissions.canAccessEvaluationPdf && (
                <LoadedButton
                  onClick={() =>
                    handleDownloadPdf(savedEvaluationData?.saveEvaluation?.id || '')
                  }
                  state="secondary"
                  isLoading={pdfIsLoading}
                  disabled={!savedEvaluationData?.saveEvaluation?.id || pdfIsLoading}
                >
                  {t('evaluations.summary.downloadPdf')}
                </LoadedButton>
              )}
            </div>
            {permissions.canCreateLoanApplication && (
              <div className="flex basis-1/2 justify-end">
                <Button
                  disabled={
                    isNotValidMaxCommitmentAmount ||
                    !savedEvaluationData?.saveEvaluation?.id
                  }
                  onClick={handleStartApplication}
                  data-testid="apply-for-loan-button"
                >
                  {t('evaluations.summary.applyForALoan')}
                </Button>
              </div>
            )}
          </div>
          {status === UIEvaluationStatus.SaveFailed && (
            <div data-testid="evaluation-failed-msg" className="alert">
              {t('evaluations.saveEvaluation.failed')}
            </div>
          )}
          {pdfDownloadError && (
            <div data-testid="download-failed-msg" className="alert">
              {t('common.error.unspecific')}
            </div>
          )}
        </div>
      )}
      {showSaveModal && (
        <SaveEvaluationModal
          onClose={handleSaveModalClose}
          evaluationAction={evaluationAction}
          allCollateralAccounts={runEvaluationPayload.evaluationCollateralAccounts}
          customerExternalId={customerExternalId}
        />
      )}
    </QuerySuspense>
  );
};
