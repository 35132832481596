import React from 'react';

import { useAtomValue } from 'jotai';

import { evaluationAtom } from 'modules/EvaluationV2/models/evaluation';

import { EvaluationGraph } from './EvaluationGraph/EvaluationGraph';

interface GraphProps {}

export const Graph: React.FC<GraphProps> = (props) => {
  const evaluation = useAtomValue(evaluationAtom);
  return (
    <EvaluationGraph
      evaluationAssetSummaryValues={evaluation?.evaluationAssetSummary || []}
    />
  );
};
