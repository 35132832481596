import React from 'react';

import { Button, LoadedButton, Modal, ModalTitle, TextInput } from 'components';
import {
  ActivityAuditType,
  EvaluationInput,
  RunEvaluationInput,
  SaveEvaluationMutation,
  useSaveEvaluationMutation,
} from 'generated/graphql';
import { useQueryMutation } from 'queries/apiFetch/useQueryMutation';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { useIsTouchDevice } from 'utils/helpers';

import { EvaluationActions } from '../NewEvaluation';

interface SaveEvaluationModalProps {
  onClose: (status?: 'failed' | 'saved', responseData?: SaveEvaluationMutation) => void;
  evaluationAction?: EvaluationActions;
  allCollateralAccounts?: RunEvaluationInput['evaluationCollateralAccounts'];
  customerExternalId?: string;
}

export const SaveEvaluationModal: React.FC<SaveEvaluationModalProps> = ({
  onClose,
  evaluationAction,
  allCollateralAccounts,
  customerExternalId,
}) => {
  const { t } = useTranslation();

  const isReevaluate = evaluationAction === EvaluationActions.ReEvaluate;

  const {
    control,
    formState: { isSubmitting, isValid },
    handleSubmit,
    getValues,
    setValue,
  } = useFormContext<EvaluationInput>();

  const initLabel = getValues('label');
  const isTouchDevice = useIsTouchDevice();

  const { mutateAsync } = useQueryMutation(useSaveEvaluationMutation, {
    extra: {
      auditReport: {
        activityType: ActivityAuditType.Write,
        customerExternalId,
      },
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    data.id = uuidv4();
    if (allCollateralAccounts) {
      data.evaluationCollateralAccounts = allCollateralAccounts!;
    }
    try {
      const responseData = await mutateAsync({
        evaluationDate: data.lastEvaluated,
        evaluation: data,
      });
      onClose('saved', responseData);
    } catch (e) {
      onClose('failed');
    }
  });

  const handleClose = () => {
    if (isSubmitting) {
      return;
    }
    setValue('label', initLabel);
    return onClose();
  };

  return (
    <Modal
      isOpen={true}
      onClose={handleClose}
      title={
        <ModalTitle
          title={t('evaluations.summary.saveEvaluation')}
          onClose={handleClose}
        />
      }
    >
      {isReevaluate && (
        <div className="my-2 px-10 w-[500px] text-sm">
          {t('evaluations.saveEvaluation.saveReevaluationNote')}
        </div>
      )}
      <form onSubmit={onSubmit} className="flex-col py-6 px-10 w-[500px]">
        <Controller
          render={({ field, fieldState: { error } }) => {
            return (
              <TextInput
                label={t('evaluations.label')}
                text={field?.value || undefined}
                onChange={field.onChange}
                onBlur={field.onBlur}
                maxLength={140}
                errorMessage={error?.message}
                data-testid="evaluation-label"
                tabIndex={isTouchDevice ? -1 : undefined}
              />
            );
          }}
          name="label"
          control={control}
        />
        <div className="basis-full flex justify-end gap-4 mt-6">
          <Button
            onClick={handleClose}
            state="secondary"
            disabled={isSubmitting}
            size="sm"
            data-testid="cancel-evaluation-btn"
          >
            {t('common.cancel')}
          </Button>
          <LoadedButton
            isLoading={isSubmitting}
            onClick={onSubmit}
            disabled={isSubmitting || !isValid}
            size="sm"
            data-testid="save-evaluation-btn"
          >
            {t('common.proceed')}
          </LoadedButton>
        </div>
      </form>
    </Modal>
  );
};
