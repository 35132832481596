import { atom } from 'jotai';

import { CreditPolicies } from '../types';
import { payloadAtom } from './evaluation';
import { ID_STEPS, stepsAtom } from './step';

type CreditPolicyAtom = string | null;

export const creditPoliciesAtom = atom<CreditPolicies>([]);

export const creditPolicyIdAtom = atom<CreditPolicyAtom, [CreditPolicyAtom], void>(
  null,
  (get, set, update: CreditPolicyAtom) => {
    if (update) {
      const steps = get(stepsAtom);
      // enable next step after Accounts selection
      const index = steps.findIndex((step) => step.id === ID_STEPS.CreditPolicy);
      const updatedSteps = steps.map((step, i) => {
        if (i === index) {
          return { ...step, valid: true };
        } else if (i === index + 1) {
          return { ...step, enable: true };
        }
        return step;
      });
      set(stepsAtom, updatedSteps);

      // update evaluation input
      const payload = get(payloadAtom);
      set(payloadAtom, { ...payload, creditPolicyId: update });
    }
    set(creditPolicyIdAtom, update);
  }
);
