import React from 'react';

import clsx from 'clsx';

export interface ListItem {
  label: string | JSX.Element;
  value: string | JSX.Element;
  key: string;
}

interface ListBodyCardProps {
  rows: ListItem[];
}

interface CardRowProps {
  row: ListItem;
}

const CardRow: React.FC<CardRowProps> = ({ row }) => {
  return (
    <div
      className={clsx(
        'flex',
        'w-full',
        'items-center',
        'text-sm',
        'border-b',
        'last:border-none',
        'py-4'
      )}
      data-testid={row.key}
    >
      <div className="basis-1/2 px-5 text-gray-500 font-semibold" data-testid="label">
        {row.label}
      </div>
      <div className="basis-1/2 text-gray-700 font-normal" data-testid="value">
        {row.value}
      </div>
    </div>
  );
};

export const ListBodyCard: React.FC<ListBodyCardProps> = ({ rows }) => {
  return (
    <>
      {rows.map((row: ListItem) => {
        return <CardRow key={row.key} row={row} />;
      })}
    </>
  );
};
