import React from 'react';

import { LoanStatus, LoanSummaryQuery } from 'generated/graphql';

import { CustomisationConfig } from 'modules/root/Settings';
import { printForAllStatuses, printMoney } from 'utils/print';

export const useSummaryOpportunityFields = (
  config: CustomisationConfig,
  customer: LoanSummaryQuery['customer'] | undefined,
  loanStatus: LoanStatus | null | undefined,
  isCrossCollateralised: boolean
) => {
  return React.useMemo(
    () =>
      [
        {
          key: 'lendable-amount',
          show:
            config.portfolioOpportinity.showFields.lendableAmount &&
            !isCrossCollateralised,
          label: config.labels.lendableAmount,
          showDash: true,
          value: printMoney(customer?.opportunity?.lendableAmount),
        },
        {
          key: 'potential-to-borrow',
          show:
            config.portfolioOpportinity.showFields.potentialToBorrow &&
            !isCrossCollateralised,
          label: config.labels.potentialToBorrow,
          showDash: true,
          value: printMoney(customer?.opportunity?.potentialToBorrow),
        },
        {
          key: 'total-remaining-market-value',
          show:
            config.portfolioOpportinity.showFields.totalRemainingMarketValue &&
            isCrossCollateralised &&
            loanStatus !== LoanStatus.DataIssue,
          label: config.labels.totalRemainingMarketValue,
          showDash: true,
          value: printMoney(customer?.opportunity?.totalRemainingMarketValue),
        },
        {
          key: 'total-remaining-eligible-market-value',
          show:
            config.portfolioOpportinity.showFields.totalRemainingEligibleMarketValue &&
            isCrossCollateralised &&
            loanStatus !== LoanStatus.DataIssue,
          label: config.labels.totalRemainingEligibleMarketValue,
          showDash: true,
          value: printMoney(customer?.opportunity?.totalRemainingEligibleMarketValue),
        },
      ]
        .filter((field) => field.show)
        .map((field) => {
          field.value = printForAllStatuses(
            field.value,
            field.showDash,
            customer?.loans?.map((loan) => loan?.status) || []
          );
          return field;
        }),
    [config, customer, isCrossCollateralised, loanStatus]
  );
};
