import React from 'react';

import { PartiesSection } from 'generated/graphql';

import { ReactComponent as PersonSvg } from 'assets/icons/person.svg';

import { useCustomisation } from 'modules/root/Settings';

import { PartiesListCustomer } from '../PartiesModalBody';
import { RelationshipSection } from './PartiesTreeItem/Sections';

interface Props {
  sections: PartiesSection[];
  borrower: PartiesListCustomer;
}

export const RootBorrowerItem: React.FC<Props> = ({ sections, borrower }) => {
  const { labels } = useCustomisation();
  return (
    <>
      {sections
        ?.filter(({ type, show }) => show && type === 'borrower')
        .map(() => {
          return (
            borrower && (
              <div className="flex items-center p-5" key={borrower.displayName}>
                <div className="rounded-full overflow-hidden border-2 w-12 h-12">
                  <PersonSvg className="bg-primary-50 p-3 fill-primary-300 stroke-primary-300 w-full h-full" />
                </div>
                <RelationshipSection
                  relationship={labels.borrower}
                  displayName={borrower.displayName}
                />
              </div>
            )
          );
        })}
    </>
  );
};
