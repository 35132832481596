import React from 'react';
import { useParams } from 'react-router';

import { ActivityAuditType, useInternalContactsQuery } from 'generated/graphql';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';

import { useCustomerProfileContext } from 'modules/Customers/CustomerProfile';
import { QuerySuspense } from 'modules/common/QuerySuspense/QuerySuspense';
import { useCustomisation, usePageTitle } from 'modules/root/Settings';
import { printPrimitive } from 'utils/print';

type ContactItemProps = React.PropsWithChildren<{
  label: string;
  show: boolean;
}>;

const ContactItem: React.FC<ContactItemProps> = ({ label, show, children }) => {
  if (!show) {
    return null;
  }
  return (
    <dl className="basis-1/3 lg:basis-1/5 grow">
      <dt data-testid="contact-label" className="label mb-1">
        {label}
      </dt>
      <dd data-testid="contact-value" className="detail font-semibold">
        {children}
      </dd>
    </dl>
  );
};

const HorizontalSplit: React.FC = () => {
  return <div className="w-full h-[1px] bg-gray-100 m-1"></div>;
};

export const InternalContacts: React.FC = () => {
  const { customerName } = useCustomerProfileContext();
  const { internalContacts, labels } = useCustomisation();
  usePageTitle([customerName, labels.internalContacts]);
  const { customerId } = useParams();
  const { data, error, isLoading } = useQueryFetch(useInternalContactsQuery, {
    queryHookParams: { customerID: customerId! },
    extra: {
      auditReport: { activityType: ActivityAuditType.Read },
    },
  });
  const contacts = data?.customer.internalContacts;
  const fields = internalContacts.showFields;
  return (
    <div className="mx-8 lg:mx-20 my-6 lg:my-14">
      <h2>{labels.internalContactDetails}</h2>
      <QuerySuspense
        error={error}
        isLoading={isLoading}
        noData={!contacts?.length}
        noDataMessage={labels.noContacts}
      >
        <div className="flex flex-col gap-6">
          {contacts?.map((contact) => (
            <React.Fragment key={contact?.id}>
              <div data-testid="contact-entry" className="flex flex-row flex-wrap gap-6">
                <ContactItem show={fields.name} label={labels.name}>
                  {printPrimitive(contact?.name)}
                </ContactItem>
                <ContactItem show={fields.role} label={labels.role}>
                  {printPrimitive(contact?.role)}
                </ContactItem>
                <ContactItem show={fields.email} label={labels.email}>
                  {printPrimitive(contact?.email)}
                </ContactItem>
                <ContactItem show={fields.phone} label={labels.phone}>
                  {printPrimitive(contact?.phone)}
                </ContactItem>
              </div>
              <HorizontalSplit />
            </React.Fragment>
          ))}
        </div>
      </QuerySuspense>
    </div>
  );
};
