const fmt = Intl.NumberFormat('en-EN', {
  useGrouping: true,
  style: 'currency',
  currency: 'USD',
  currencyDisplay: 'symbol',
});

const shortFmt = Intl.NumberFormat('en-EN', {
  notation: 'compact',
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
  style: 'currency',
  currency: 'USD',
});

function toFixed(num: number | null | undefined, fixed = 2): number {
  if (!num) {
    return 0.0;
  }
  return parseFloat(num.toFixed(fixed));
}

function format(val: number | null | undefined): string | null {
  if (typeof val === 'number') {
    const trimmedVal = toFixed(val, 2);
    return fmt.format(trimmedVal);
  }
  return null;
}

function toNumber(val: string | null): number {
  if (!val) {
    return 0;
  }

  return +val.slice(4);
}

function formatInLetters(val: number | null | undefined): string {
  if (typeof val === 'number') {
    return shortFmt.format(val).toLowerCase();
  }
  return '';
}

export const money = {
  toFixed,
  format,
  toNumber,
  formatInLetters,
};
