import React from 'react';

import { LoanSummaryQuery } from 'generated/graphql';

import { CustomisationConfig } from 'modules/root/Settings';
import { printForAllStatuses, printMoney } from 'utils/print';

export const usePledgedAccountSummary = (
  config: CustomisationConfig,
  customer: LoanSummaryQuery['customer'] | undefined,
  isCrossCollateralised: boolean
) => {
  return React.useMemo(
    () =>
      [
        {
          key: 'total-market-value',
          show: config.pledgedAccountsSummary.showFields.totalMarketValue,
          label: config.labels.totalMarketValue,
          showDash: true,
          value: printMoney(customer?.pledgedAccountSummary?.totalMarketValue),
        },
        {
          key: 'total-eligible-market-value',
          show: config.pledgedAccountsSummary.showFields.totalEligibleMarketValue,
          label: config.labels.totalEligibleMarketValue,
          showDash: true,
          value: printMoney(customer?.pledgedAccountSummary?.totalEligibleMarketValue),
        },
        {
          key: 'total-collateral-value',
          show:
            config.pledgedAccountsSummary.showFields.totalCollateralValue &&
            !isCrossCollateralised,
          label: config.labels.totalCollateralValue,
          showDash: true,
          value: printMoney(customer?.pledgedAccountSummary?.totalCollateralValue),
        },
      ]
        .filter((field) => field.show)
        .map((field) => {
          field.value = printForAllStatuses(
            field.value,
            field.showDash,
            customer?.loans?.map((loan) => loan?.status) || []
          );
          return field;
        }),
    [config, customer, isCrossCollateralised]
  );
};
