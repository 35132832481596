import React from 'react';

import { ActivityAuditType, useAuditReportMutation } from 'generated/graphql';
import { GraphQLClient } from 'graphql-request';
import { UseMutationOptions, UseMutationResult } from 'react-query';

import { graphqlClient, useAuthClient } from './graphqlClient';

export const useQueryMutation: UMutation = (queryHook, { extra = {} } = {}) => {
  const { auditReport } = extra;
  const authClient = useAuthClient(graphqlClient);
  const { mutateAsync } = useAuditReportMutation(authClient);

  const sendAudit = React.useCallback(
    (data: any, error: any, variables: any) => {
      if (auditReport) {
        const [resourceAccessed] = queryHook.getKey();
        const queryDetails =
          auditReport.queryDetails ??
          JSON.stringify({
            data,
            variables,
            error: error ? true : undefined,
          });
        mutateAsync({
          ...auditReport,
          resourceAccessed: resourceAccessed as string,
          queryDetails,
          customerExternalId: auditReport.customerExternalId,
        });
      }
    },
    [auditReport, mutateAsync, queryHook]
  );
  return queryHook(authClient, { onSettled: sendAudit });
};

type UMutation = <
  H extends {
    (
      client: GraphQLClient,
      options?: UseMutationOptions<any, any, any, any>,
      headers?: RequestInit['headers']
    ): any;
    getKey: () => string[];
  }
>(
  queryHook: H,
  options?: {
    extra?: {
      auditReport?: {
        activityType: ActivityAuditType;
        customerExternalId?: string;
        queryDetails?: string;
      };
    };
  }
) => H extends (
  client: GraphQLClient,
  options?: UseMutationOptions<infer D, infer E, infer V, infer C>,
  headers?: RequestInit['headers']
) => ReturnType<H>
  ? UseMutationResult<D, E, V, C>
  : never;
