import React from 'react';

import { ActivityAuditType, useAuditReportMutation } from 'generated/graphql';
import { GraphQLClient } from 'graphql-request';
import { UseQueryOptions, UseQueryResult } from 'react-query';

import { graphqlClient, useAuthClient } from './graphqlClient';

export const useQueryFetch: UFetch = (
  queryHook,
  { queryHookOptions, queryHookParams, extra = {} } = {}
) => {
  const { excludeToken, auditReport } = extra;
  const authClient = useAuthClient(graphqlClient, !excludeToken);
  const { mutateAsync } = useAuditReportMutation(authClient);

  const sendAudit = React.useCallback(
    (data: any, error: any) => {
      if (auditReport) {
        const [resourceAccessedDefault, ...variables] = queryHook.getKey(queryHookParams);
        const resourceAccessed = auditReport.resourceAccessed || resourceAccessedDefault;
        const queryDetails =
          auditReport.queryDetails ??
          JSON.stringify({
            query: queryHook.document,
            variables,
            error: error ? true : undefined,
          });
        const customerExternalId =
          auditReport.customerExternalId ?? data?.customer?.customerId;
        mutateAsync({
          ...auditReport,
          resourceAccessed: resourceAccessed as string,
          queryDetails,
          customerExternalId,
        });
      }
    },
    [auditReport, mutateAsync, queryHook, queryHookParams]
  );
  const onSettled = queryHookOptions?.onSettled || null;
  const auditOnSettled = onSettled
    ? (data: any, error: any) => {
        onSettled(data, error);
        sendAudit(data, error);
      }
    : sendAudit;
  return queryHook(authClient, queryHookParams, {
    ...queryHookOptions,
    onSettled: auditOnSettled,
  });
};
type UFetch = <
  H extends {
    (
      client: GraphQLClient,
      variables: Parameters<H>[1],
      options?: UseQueryOptions<any, any, any>,
      headers?: RequestInit['headers']
    ): any;
    document: string;
    getKey: (variables: Parameters<H>[1]) => Array<string | Parameters<H>[1]>;
  }
>(
  queryHook: H,
  options?: {
    queryHookParams?: Parameters<H>[1];
    queryHookOptions?: Parameters<H>[2];
    extra?: {
      excludeToken?: boolean;
      auditReport?: {
        resourceAccessed?: string;
        activityType: ActivityAuditType;
        customerExternalId?: string;
        queryDetails?: string;
      };
    };
  }
) => H extends (
  client: GraphQLClient,
  variables: Parameters<H>[1],
  options?: UseQueryOptions<infer R, infer E, infer R>,
  headers?: RequestInit['headers']
) => ReturnType<H>
  ? UseQueryResult<R, E>
  : never;
