import React from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Listbox } from '@headlessui/react';
import clsx from 'clsx';
import { FontIcon } from 'components';

export interface SelectItemValueType {
  id: string;
  name: string;
  disabled?: boolean;
  icon?: IconProp;
}

interface SelectItemProps {
  value: SelectItemValueType;
  selectedValue: SelectItemValueType; // TODO fix it in NAV-3021
}
const optionClass = clsx(
  'hover:bg-primary-50',
  'px-4',
  'py-3',
  'bg-white',
  'font-normal',
  'text-gray-700'
);

export const SelectItem: React.FC<SelectItemProps> = ({ value, selectedValue }) => {
  return (
    <Listbox.Option key={value.id} value={value} disabled={value.disabled}>
      {({ active }) => (
        <div
          className={clsx(
            optionClass,
            { 'bg-primary-50': active },
            { 'font-bold text-primary-600': value.id === selectedValue.id },
            { 'text-gray-300': value.disabled }
          )}
        >
          {value.icon && <FontIcon icon={value.icon} className="pr-3" />}
          {value.name}
        </div>
      )}
    </Listbox.Option>
  );
};
