import React from 'react';

import { useTranslation } from 'react-i18next';

export const Loading: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div data-testid="loading" className="tracking-widest">
      {t('common.loading')}
    </div>
  );
};
