import React, { useState } from 'react';

import clsx from 'clsx';

const SSN_MASK = '***-**-****';

type Props = {
  text: string | null;
  mask?: string;
};

const MaskCopyProhibitedText: React.FC<Props> = ({ text, mask = SSN_MASK }) => {
  const [textToShow, setTextToShow] = useState<string | null>(mask);
  const isMask = textToShow === mask;

  return (
    <div
      className="w-full h-6 relative"
      onMouseEnter={() => setTextToShow(text)}
      onMouseLeave={() => setTextToShow(mask)}
      onTouchEnd={() => setTextToShow(isMask ? text : mask)}
      unselectable="on"
      data-testid="mask-copy-prohibited-text"
    >
      <span
        className={clsx('select-none', {
          'tracking-wide': isMask,
        })}
      >
        {textToShow}
      </span>
    </div>
  );
};

export default MaskCopyProhibitedText;
