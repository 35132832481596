import React from 'react';

import clsx from 'clsx';
import { ThemeOwner } from 'generated/graphql';
import { useTranslation } from 'react-i18next';

import navLogo from 'assets/logo/broadridge/broadridge-logo-white.svg';

import { NavIcon } from 'components/NavIcon/NavIcon';
import { useBranding } from 'modules/root/Settings/contexts/branding';

interface FooterProps {
  text?: string;
}

export const Footer: React.FC<FooterProps> = ({ text }) => {
  const { owner, footerLogo } = useBranding();
  const { t } = useTranslation();
  return (
    <footer
      id="footer"
      role="contentinfo"
      className={clsx(
        'flex',
        'justify-between',
        'text-white',
        'px-5',
        'lg:px-20',
        'items-center',
        'bg-main-primary'
      )}
    >
      <div className="flex gap-6 items-center">
        {text && <div>{text}</div>}
        {owner !== ThemeOwner.Nav && (
          <div className="h-7 flex items-center">
            <NavIcon
              className="h-full w-auto mb-1"
              data-testid="footer-img"
              alt="company logo"
              source={footerLogo}
            />
          </div>
        )}
      </div>
      <div className="h-6 flex gap-2 items-center justify-end">
        {t('common.poweredBy')}
        <NavIcon
          className="h-full w-auto mb-1"
          data-testid="powered-by"
          alt="broadridge logo"
          source={navLogo}
        />
      </div>
    </footer>
  );
};
