import { createContext, useContext } from 'react';

export interface Session {
  isAuthenticated: boolean;
  profile?: auth0.Auth0UserProfile;
  accessToken?: string;
  idToken?: string;
  expiredAt?: number;
}

export interface AuthContextState extends Session {
  originPathKey: string;
  isLoading: boolean;
  login: (options?: { callbackFn?: Function }) => void;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextState>({
  originPathKey: '',
  isAuthenticated: false,
  isLoading: false,
  login: () => undefined,
  logout: () => undefined,
});

const { Provider, Consumer } = AuthContext;

const useAuth = () => useContext(AuthContext);

export { Provider as AuthProvider, Consumer, useAuth };
