import clsx from 'clsx';

export interface ReactTableRowProps {
  children?: string | JSX.Element | JSX.Element[];
  onClick?: () => void;
  'data-testid'?: string;
}

export const ReactTableRow = ({
  children,
  onClick,
  'data-testid': testid,
}: ReactTableRowProps) => {
  return (
    <tr
      className={clsx('group', {
        'cursor-pointer': onClick,
      })}
      onClick={onClick}
      data-testid={testid}
    >
      {children}
    </tr>
  );
};
