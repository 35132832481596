import React from 'react';

import { faDownload, faFile } from '@fortawesome/free-solid-svg-icons';
import { ActionMenu, FontIcon } from 'components';
import {
  ActivityAuditType,
  ApplicationSearchResultItem,
  useAuditReportMutation,
} from 'generated/graphql';
import { graphqlClient, useAuthClient } from 'queries/apiFetch/graphqlClient';
import { ResourceAccessed } from 'services/auditReport';
import { DATE_FORMAT_UNDERSCORE, formatDate } from 'utils';

import { useCustomisation } from 'modules/root/Settings';
import { useAccess } from 'modules/root/auth/Authenticated/Permission/permission.context';
import { handleViewInformApplication } from 'utils/inform';

interface ApplicationActionProps {
  application: ApplicationSearchResultItem;
  downloadPdf: (urlPath: string, filename: string) => Promise<void>;
}

export const ApplicationAction: React.FC<ApplicationActionProps> = ({
  application,
  downloadPdf,
}) => {
  const { labels, applicationsActionModal } = useCustomisation();
  const { permissions } = useAccess();
  const authClient = useAuthClient(graphqlClient);
  const { mutateAsync } = useAuditReportMutation(authClient);

  const applicationActions = React.useMemo(() => {
    const handleDownloadPdf = (application: ApplicationSearchResultItem) => {
      const fileName = `LoanDocs-${application.id}-${formatDate(
        new Date().toUTCString(),
        DATE_FORMAT_UNDERSCORE
      )}`;
      const urlPath = `/schema/applications/${application.id}/documents`;
      const commonAuditData = {
        activityType: ActivityAuditType.Read,
        resourceAccessed: ResourceAccessed.DownloadApplicationDocs,
        customerExternalId: application.customerExternalId,
      };
      downloadPdf(urlPath, fileName)
        .then(() =>
          mutateAsync({
            ...commonAuditData,
            queryDetails: JSON.stringify({ formBundleId: application.formBundleId }),
          })
        )
        .catch(() => {
          mutateAsync({
            ...commonAuditData,
            queryDetails: JSON.stringify({
              formBundleId: application.formBundleId,
              error: true,
            }),
          });
        });
    };
    const actions = [];
    if (applicationsActionModal.showFields.view && permissions.canViewApplications) {
      actions.push({
        action: (row: ApplicationSearchResultItem) => {
          mutateAsync({
            activityType: ActivityAuditType.Read,
            resourceAccessed: ResourceAccessed.ViewApplication,
            customerExternalId: row.customerExternalId,
            queryDetails: JSON.stringify({
              formBundleId: row.formBundleId,
            }),
          });
          handleViewInformApplication(row.formBundleId);
        },
        text: labels.view,
        icon: <FontIcon icon={faFile} />,
      });
    }
    if (
      applicationsActionModal.showFields.downloadDocument &&
      permissions.canDownloadLoanDocs
    ) {
      actions.push({
        action: (row: ApplicationSearchResultItem) => handleDownloadPdf(row),
        text: labels.downloadDocument,
        icon: <FontIcon icon={faDownload} />,
      });
    }
    return actions;
  }, [downloadPdf, permissions, labels, applicationsActionModal, mutateAsync]);

  return (
    <ActionMenu
      position="center"
      items={applicationActions}
      data={application}
      data-testid="application-action-menu"
    />
  );
};
