import React from 'react';

import clsx from 'clsx';
import { Ellipsis, HelpIcon } from 'components';

import { TwoColBodyCard } from 'components/Card/Content/TwoColBodyCard';
import { useCustomerProfileContext } from 'modules/Customers/CustomerProfile';
import { useCustomisation } from 'modules/root/Settings';
import {
  EMPTY_DASH,
  printBooleanFull,
  printMoney,
  printPercent,
  printPrimitive,
} from 'utils/print';

import { CollAccount } from '../CollateralAccounts';
import { CollateralApportionmentGraph } from '../CollateralApportionmentGraph/CollateralApportionmentGraph';

interface AccountBlockProps {
  account: CollAccount;
  mRef?: React.RefObject<HTMLDivElement> | null;
  className?: string;
}

interface SecondaryAccountsProps {
  accountIds: NonNullable<CollAccount>['secondaryCollateralAccountIds'];
}

const SecondaryAccounts: React.FC<SecondaryAccountsProps> = ({ accountIds }) => {
  return (
    <>
      {accountIds?.map((secAccountId) => (
        <div key={secAccountId?.secondaryCollateralId}>
          {printPrimitive(secAccountId?.secondaryCollateralId)}
        </div>
      ))}
    </>
  );
};

export const AccountBlock: React.FC<AccountBlockProps> = ({
  account,
  className,
  mRef,
}) => {
  const { collateralAccounts: config, labels, helpText } = useCustomisation();
  const { isDataIssue } = useCustomerProfileContext();
  const accountFields = React.useMemo(() => {
    const totalApportioned = account?.totalApportioned || 0;
    const apportionedOfEligibleMarketValue = account?.marketData?.eligibleMarketValue
      ? (totalApportioned / account.marketData.eligibleMarketValue) * 100
      : null;
    const shouldShowCollateralGraph =
      Boolean(account?.pledged && totalApportioned) &&
      typeof account?.marketData?.eligibleMarketValue === 'number';
    const fields = [
      {
        key: 'collateral-account-id',
        label: labels.collateralAccountId,
        show: config.showFields.collateralAccountId,
        value: printPrimitive(account?.collateralAccountId),
      },
      {
        key: 'secondary-collateral-account-id',
        label: labels.secondaryCollateralAccountId,
        show: config.showFields.secondaryCollateralAccountId,
        value: account?.secondaryCollateralAccountIds ? (
          <SecondaryAccounts accountIds={account.secondaryCollateralAccountIds} />
        ) : (
          EMPTY_DASH
        ),
      },
      {
        key: 'account-type',
        label: labels.accountType,
        show: config.showFields.accountType,
        value: printPrimitive(account?.accountType),
      },
      {
        key: 'custodian',
        label: labels.custodian,
        show: config.showFields.custodian,
        value: <Ellipsis>{printPrimitive(account?.custodian)}</Ellipsis>,
      },
      {
        key: 'market-value',
        label: labels.marketValue,
        show: config.showFields.marketValue,
        value: printMoney(account?.marketData?.marketValue),
      },
      {
        key: 'eligible-market-value',
        label: labels.eligibleMarketValue,
        show: config.showFields.eligibleMarketValue,
        value: printMoney(account?.marketData?.eligibleMarketValue),
      },
      {
        key: 'collateral-value',
        label: (
          <div className="flex flex-nowrap items-center gap-1">
            {labels.collateralValue} <HelpIcon text={helpText.collateralValue} />
          </div>
        ),
        show: config.showFields.collateralValue,
        value: printMoney(account?.marketData?.collateralValue),
      },
      {
        key: 'total-apportioned',
        label: labels.totalApportioned,
        show: config.showFields.totalApportioned && account?.pledged && totalApportioned,
        value: (
          <div>
            <span>{printMoney(totalApportioned)}</span>
            {apportionedOfEligibleMarketValue && (
              <span className="ml-1">
                {`(${printPercent(apportionedOfEligibleMarketValue)})`}
              </span>
            )}
          </div>
        ),
      },
      {
        key: 'pledged',
        label: labels.pledged,
        show: config.showFields.pledged,
        value: printBooleanFull(account?.pledged),
      },
      {
        key: 'collateral-apportionment-graph-view',
        label: labels.apportionedAmounts,
        show: config.showFields.apportionedAmounts && shouldShowCollateralGraph,
        value: (
          <div className="font-semibold max-w-[460px]">
            <CollateralApportionmentGraph
              loanCollateral={account?.loanCollateral}
              eligibleMarketValue={
                account?.marketData?.eligibleMarketValue
                  ? account?.marketData?.eligibleMarketValue
                  : 0
              }
              isDataIssue={isDataIssue}
            />
          </div>
        ),
      },
    ].filter((field) => field.show);
    return fields;
  }, [account, config, isDataIssue, labels, helpText]);
  return (
    <div
      className={clsx('bg-secondary-50', 'rounded', className)}
      id={`account-${account?.id}`}
      ref={mRef}
      key={account?.id}
    >
      <TwoColBodyCard items={accountFields} />
    </div>
  );
};
