/* eslint-disable @typescript-eslint/no-unused-vars */
import Highcharts from 'highcharts';

Highcharts.SVGRenderer.prototype.symbols.threshold = function (x, y, w, h) {
  y += 14;
  return [
    'M',
    x + 6,
    y - 3,
    'L',
    x - 2,
    y - 8.6,
    x - 2,
    y + 2.6,
    'Z',
    'L',
    x + w - 5,
    y - 3,
    'M',
    x + w - 5,
    y - 3,
    'L',
    x + w + 3,
    y - 8.6,
    x + w + 3,
    y + 2.6,
    'Z',
  ];
};

if (Highcharts.VMLRenderer) {
  Highcharts.VMLRenderer.prototype.symbols.threshold = Highcharts.SVGRenderer.prototype.symbols.threshold;
}
