import React from 'react';
import { NavLink } from 'react-router-dom';

import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { FontIcon } from 'components';

export interface BreadCrumbItemType {
  label: string;
  link: string;
}

export enum BreadCrumbTheme {
  'dark',
  'light',
}

interface BreadCrumbProps {
  list: BreadCrumbItemType[];
  theme?: BreadCrumbTheme;
}

export const BreadCrumb: React.FC<BreadCrumbProps> = ({
  list,
  theme = BreadCrumbTheme.dark,
}) => {
  return (
    <nav aria-label="breadcrumb">
      <ul className="flex items-center space-x-2.5">
        {list.map((elem, index) => {
          return (
            <li
              key={elem.link}
              className={clsx('inline-flex', {
                'text-white/70': theme === BreadCrumbTheme.dark,
                'text-gray-500': theme === BreadCrumbTheme.light,
              })}
            >
              {index !== 0 && (
                <FontIcon size="xs" className="mr-2 mt-0.5" icon={faAngleRight} />
              )}
              <NavLink
                to={`${elem.link}`}
                className={clsx(
                  'uppercase',
                  'flex',
                  'text-xs',
                  'tracking-[0.06em]',
                  'font-normal',
                  {
                    'text-white':
                      theme === BreadCrumbTheme.dark && index < list.length - 1,
                    'text-gray-500':
                      theme === BreadCrumbTheme.light && index < list.length - 1,
                  },
                  {
                    'text-primary-300':
                      theme === BreadCrumbTheme.dark && index === list.length - 1,
                    'text-primary-600':
                      theme === BreadCrumbTheme.light && index === list.length - 1,
                  }
                )}
              >
                {elem.label}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};
