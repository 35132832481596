import { money } from 'utils/currency';

import { CollAccount } from '../CollateralAccounts';

export const ASSET_TYPES = [
  'CASH',
  'FIXED_INCOME',
  'MUTUAL_FUNDS',
  'EQUITY',
  'ETFS',
  'INELIGIBLES',
];

export type AssetType = typeof ASSET_TYPES[number];

export type AssetTotal = Record<AssetType, number>;

export type AccountsCheck = { [key: string]: boolean };

export const isAssetType = (assetType: unknown): assetType is AssetType => {
  return assetType ? ASSET_TYPES.includes(assetType as AssetType) : false;
};

export const DEFAULT_ASSET_TOTAL = ASSET_TYPES.reduce<AssetTotal>((acc, val) => {
  acc[val] = 0;
  return acc;
}, {});

export const ASSET_TYPE_TITLES: Record<AssetType, string> = {
  CASH: 'cash',
  FIXED_INCOME: 'fixedIncome',
  MUTUAL_FUNDS: 'mutualFunds',
  EQUITY: 'equity',
  ETFS: 'etfs',
  INELIGIBLES: 'ineligibles',
};

export const getAssetTotal = (
  accounts: CollAccount[],
  checks: AccountsCheck
): AssetTotal => {
  const values: AssetTotal = { ...DEFAULT_ASSET_TOTAL };
  accounts.forEach((account) => {
    if (account && checks[account.collateralAccountId]) {
      // eslint-disable-next-line no-unused-expressions
      account.assetTotal?.forEach((assetTotal) => {
        if (assetTotal) {
          const marketValue = money.toNumber(assetTotal.marketValue || '0');
          if (assetTotal.assetSubType && assetTotal.eligible) {
            const assetCategory = assetTotal.assetSubType.toLowerCase() || '';
            const assetLabel = assetTotal.assetSubTypeDetails?.toLowerCase() || '';

            if (assetCategory.includes('cash')) {
              values.CASH += marketValue;
            } else if (assetCategory.includes('fixed income')) {
              values.FIXED_INCOME += marketValue;
            } else if (
              assetCategory.includes('fund') &&
              (assetLabel.includes('etf') || assetLabel.includes('exchange'))
            ) {
              values.ETFS += marketValue;
            } else if (
              assetCategory.includes('fund') &&
              (assetLabel.includes('mutual') ||
                assetLabel.includes('bond') ||
                assetLabel.includes('world') ||
                assetLabel.includes('commodities'))
            ) {
              values.MUTUAL_FUNDS += marketValue;
            } else if (assetCategory.includes('equity')) {
              values.EQUITY += marketValue;
            }
          } else if (!assetTotal.eligible) {
            values.INELIGIBLES += marketValue;
          }
        }
      });
    }
    return values;
  });
  return values;
};
