import React from 'react';

import clsx from 'clsx';

import { DashboardCardProps } from './DashboardCard';

export type SectionView = 'one-column' | 'two-column' | 'three-column';

type CardElement = React.ReactElement<DashboardCardProps> | null;

interface DashboardSectionProps {
  label: string;
  view?: SectionView;
  children: CardElement | Array<CardElement>;
}

export const DashboardSection: React.FC<DashboardSectionProps> = ({
  label,
  view = 'one-column',
  children,
}) => {
  const cards = Array.isArray(children) ? children : [children];
  let containerClass = 'flex';
  let itemClass = 'flex';
  if (view === 'one-column') {
    containerClass = clsx('flex', 'flex-col', 'w-full', 'lg:w-[calc(50%-8px)]');
    itemClass = clsx('flex', 'w-full');
  } else if (view === 'two-column') {
    containerClass = clsx('flex', 'flex-col', 'w-full');
    itemClass = clsx('flex', 'w-[calc(50%-8px)]', 'grow');
  } else {
    containerClass = clsx('flex', 'flex-col', 'w-full', 'gap-x-4');
    itemClass = clsx('flex', 'w-[30%]', 'grow');
  }
  return (
    <div data-testid="dashboard-section" className={containerClass}>
      <h2 className="mb-6 text-[20px] w-full">{label}</h2>
      <div className="flex flex-row flex-wrap gap-x-4">
        {cards.map(
          (child) =>
            child && (
              <div
                key={child.key}
                className={clsx('mb-4', itemClass, child.props.className)}
              >
                {child}
              </div>
            )
        )}
      </div>
    </div>
  );
};
