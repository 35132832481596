import React from 'react';

import { CustomisationQuery } from 'generated/graphql';
import { DeepReadonly } from 'ts-essentials';

import { OmitRecursively } from 'modules/common/types/helpers';

export type CustomisationConfig = DeepReadonly<
  OmitRecursively<CustomisationQuery['applicationConfig'], '__typename' | 'theme'>
> & { pdfHexColor: string };

export const CustomisationContext = React.createContext<CustomisationConfig>(
  {} as CustomisationConfig
);

export const useCustomisation = (): CustomisationConfig =>
  React.useContext(CustomisationContext);
