export const getPageRange = (
  totalRecords: number,
  rangeCount: number,
  rowsPerPage: number,
  currentPage: number
) => {
  if (rangeCount < 1) {
    return [];
  }

  const totalPages = Math.ceil(totalRecords / rowsPerPage);
  const halfRange = Math.floor(rangeCount / 2);
  let startPage = currentPage - halfRange;
  let endPage = currentPage + halfRange;

  if (startPage < 1) {
    endPage += 1 - startPage;
    startPage = 1;
  }
  if (endPage > totalPages) {
    startPage -= endPage - totalPages;
    endPage = totalPages;
  }
  if (startPage < 1) {
    startPage = 1;
  }

  const pageIndices = [];
  for (let i = startPage; i <= endPage; i++) {
    pageIndices.push(i);
  }

  return pageIndices;
};
