import React from 'react';
import { useLocation } from 'react-router';

import { history } from './history';

type UnblockFn = () => void;
type BlockNavigation = (
  when: boolean | null,
  onBlock: () => void
) => {
  unblock: () => Promise<string>;
  blockedPath: string;
};

export const useBlockNavigation: BlockNavigation = (when, onBlock) => {
  const [path, setPath] = React.useState('');
  const location = useLocation();
  const unblockRef = React.useRef<UnblockFn>(() => void 0);

  React.useEffect(() => {
    if (!when) {
      return;
    }
    const unblock = history.block((targetLocation) => {
      const basepath = process.env.PUBLIC_URL || '';
      const targetPathname =
        basepath.length > 0
          ? targetLocation.location.pathname.replace(basepath, '')
          : targetLocation.location.pathname;
      if (location.pathname !== targetPathname) {
        onBlock();
      }
      setPath(targetPathname);
    });
    // unblock the previous one in case it was set once already
    unblockRef.current();
    unblockRef.current = unblock;
    return unblock;
  }, [when, onBlock, location.pathname]);

  const handleBeforeUnload = React.useCallback(
    (e: BeforeUnloadEvent): void => {
      if (when) {
        e.preventDefault();
        e.returnValue = '';
      }
    },
    [when]
  );

  React.useEffect(() => {
    window.removeEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [handleBeforeUnload]);

  const unblockPath = () => {
    unblockRef.current();
    return path;
  };

  return {
    unblock: () => Promise.resolve(unblockPath()),
    blockedPath: path,
  };
};
