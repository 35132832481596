import { ReactNode } from 'react';

import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { Column } from '@tanstack/react-table';
import clsx from 'clsx';

import { FontIcon } from 'components/FontIcon/FontIcon';

export interface ReactTableHeaderCellProps<T extends unknown> {
  children?: string | JSX.Element | JSX.Element[] | ReactNode;
  colSpan: number;
  column: Column<T>;
  stickyFromTopPx?: number;
  'data-testid'?: string;
}

export const ReactTableHeaderCell = <T extends unknown>({
  children,
  colSpan,
  column,
  stickyFromTopPx,
  'data-testid': testid,
}: ReactTableHeaderCellProps<T>) => {
  const sortDesc = column.getIsSorted() === 'asc' ? true : false;
  const sortFn = () =>
    column.getIsSorted() === 'desc'
      ? column.clearSorting()
      : column.toggleSorting(sortDesc);
  return (
    <th
      colSpan={colSpan}
      className={clsx(
        'p-3',
        'first:rounded-l-lg',
        'last:rounded-r-lg',
        'text-left bg-secondary-50 text-gray-500 border-b-2'
      )}
      style={
        typeof stickyFromTopPx === 'number'
          ? { top: `${stickyFromTopPx + 10}px`, position: 'sticky', zIndex: 5 }
          : {}
      }
      data-testid={testid}
    >
      <div
        data-testid={`${column.id}-header-content`}
        className={clsx('gap-2', 'flex', 'flex-row', 'items-center', {
          'select-none': column.getCanSort(),
          'text-primary-600': column.getIsSorted(),
        })}
        role={column.getCanSort() ? 'button' : ''}
        onClick={column.getCanSort() ? sortFn : undefined}
      >
        {children}
        {column.getCanSort() && !column.getIsSorted() && <div className="w-[10.5px]" />}
        {column.getIsSorted() === 'asc' && <FontIcon icon={faArrowUp} size="sm" />}
        {column.getIsSorted() === 'desc' && <FontIcon icon={faArrowDown} size="sm" />}
      </div>
    </th>
  );
};
