import axios from 'axios';
import FileSaver from 'file-saver';
import { ActivityAuditType } from 'generated/graphql';
import { useMutation } from 'react-query';
import { ResourceAccessed, sendAuditReport } from 'services/auditReport';

import { SCHEMA_HOST } from 'modules/root/Settings/envVars';
import { useAuth } from 'modules/root/auth/auth.context';

const GENERATE_PDF_URL = '/schema/generate/loans/pdf';
const URL = SCHEMA_HOST
  ? `${SCHEMA_HOST}${GENERATE_PDF_URL}`
  : `http://127.0.0.1:4000${GENERATE_PDF_URL}`;

type PdfLoanSections = {
  details?: string;
  keyInformation?: string;
  interestRates?: string;
  interestPayments?: string;
  interestHistory?: string;
  pledgedAccounts?: string;
  opportunity?: string;
  blendedRates?: string;
  marginAnalysis?: string;
};

export type PdfField = {
  label: string;
  value: string;
};

type MarginAnaylysisPdf = {
  selloutWarning?: {
    collateral: string;
    paydown: string;
  };
  topUpWarning?: {
    collateral: string;
    paydown: string;
  };
  eligibleMarketValue?: {
    label: string;
    value: string;
  };
  sellOutThreshold?: {
    label: string;
    value: string;
  };
  topUpThreshold?: {
    label: string;
    value: string;
  };
  topUpCushion?: {
    label: string;
    value: string;
  };
  marginGraphCannotBeDisplayed: string;
  base64?: string;
};

export type PdfData = {
  logo: string;
  color: string;
  username: string;
  date: string;
  name: string;
  sections?: PdfLoanSections;
  details?: PdfField[];
  keyInformation?: PdfField[];
  interestRates?: PdfField[];
  interestPayments?: PdfField[];
  interestHistory?: PdfField[];
  pledgedAccounts?: PdfField[];
  opportunity?: PdfField[];
  blendedRates?: PdfField[];
  marginAnalysis?: MarginAnaylysisPdf;
};

export const usePDFGeneration = (filename: string, customerExternalId: string) => {
  const { idToken } = useAuth();

  return useMutation(
    (pdfData: PdfData) =>
      axios.post(URL, pdfData, {
        responseType: 'arraybuffer',
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }),
    {
      onSuccess: (response) => {
        FileSaver.saveAs(
          new File([response.data], `${filename}.pdf`, {
            type: 'text/plain;charset=utf-8',
          })
        );
      },
      onSettled: (data, error) => {
        if (idToken) {
          sendAuditReport(idToken, {
            activityType: ActivityAuditType.Read,
            resourceAccessed: ResourceAccessed.DownloadLoanPdf,
            customerExternalId,
            queryDetails: error ? JSON.stringify({ error: true }) : undefined,
          });
        }
      },
    }
  );
};
