import React from 'react';

import { Button, LoadedButton, Modal, ModalTitle, TextInput } from 'components';
import { ActivityAuditType, useSaveEvaluationMutation } from 'generated/graphql';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useQueryMutation } from 'queries/apiFetch/useQueryMutation';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { selCustomerAtom } from 'modules/EvaluationV2/models/customer';
import {
  EvaluationState,
  evaluationDetailsAtom,
  evaluationStateAtom,
  saveDownloadStateAtom,
  saveEvaluationInputAtom,
  savedEvaluationAtom,
} from 'modules/EvaluationV2/models/evaluation';
import { NotificationAtom, NotificationType } from 'modules/common/models/notify';
import { useIsTouchDevice } from 'utils/helpers';

interface SaveEvaluationModalProps {
  onClose: () => void;
}

export const SaveEvaluationModal: React.FC<SaveEvaluationModalProps> = ({ onClose }) => {
  const { t } = useTranslation();

  const [saveEvaluationInput, setSaveEvaluationInput] = useAtom(saveEvaluationInputAtom);
  const [savedEvaluation, setSavedEvaluation] = useAtom(savedEvaluationAtom);
  const [evalState, setEvalState] = useAtom(evaluationStateAtom);
  const setNotification = useSetAtom(NotificationAtom);
  const isSaveAgain = savedEvaluation && savedEvaluation.id !== saveEvaluationInput.id;
  const isTouchDevice = useIsTouchDevice();
  const customerExternalId = useAtomValue(selCustomerAtom)?.customerId;
  const setEvaluationDetailsAtom = useSetAtom(evaluationDetailsAtom);
  const setSaveDownloadState = useSetAtom(saveDownloadStateAtom);

  const { mutateAsync } = useQueryMutation(useSaveEvaluationMutation, {
    extra: {
      auditReport: {
        activityType: ActivityAuditType.Write,
        customerExternalId,
      },
    },
  });

  const onSubmit = async () => {
    setEvalState(EvaluationState.Saving);
    try {
      const responseData = await mutateAsync({
        evaluationDate: saveEvaluationInput.lastEvaluated,
        evaluation: { ...saveEvaluationInput, id: uuidv4() },
      });
      setEvalState(EvaluationState.Saved);
      setNotification({
        type: NotificationType.info,
        message: t('evaluationV2.evaluationSaved'),
      });
      setTimeout(() => setNotification(null), 3000);
      setSavedEvaluation(responseData.saveEvaluation);
      setEvaluationDetailsAtom({
        label: saveEvaluationInput.label,
        lastEvaluated: saveEvaluationInput.lastEvaluated,
      });
      setSaveDownloadState({
        enableDownloadPdf: true,
        enableApplyForLoan: true,
        evaluationId: responseData.saveEvaluation.id || null,
        externalEvalId: responseData.saveEvaluation.externalId,
      });

      onClose();
    } catch (e) {
      setEvalState(EvaluationState.SaveFailed);
      setNotification({
        type: NotificationType.error,
        message: t('evaluations.saveEvaluation.failed'),
      });
      setTimeout(() => setNotification(null), 3000);
      setSavedEvaluation(null);
      setSaveDownloadState(null);
      onClose();
    }
  };

  const handleClose = () => {
    if (evalState === EvaluationState.Saving) {
      return;
    }
    return onClose();
  };

  const errorMessage = !saveEvaluationInput.label ? t('validation.required') : '';

  const handleChange = React.useCallback(
    (value: string) => {
      setSaveEvaluationInput({ ...saveEvaluationInput, label: value });
    },
    [saveEvaluationInput, setSaveEvaluationInput]
  );

  return (
    <Modal
      isOpen={true}
      onClose={handleClose}
      title={
        <ModalTitle
          title={t('evaluations.summary.saveEvaluation')}
          onClose={handleClose}
        />
      }
    >
      {isSaveAgain && (
        <div className="my-2 px-10 w-[500px] text-sm">
          {t('evaluations.saveEvaluation.saveReevaluationNote')}
        </div>
      )}
      <div className="flex-col py-6 px-10 w-[500px]">
        <TextInput
          label={t('evaluations.label')}
          text={saveEvaluationInput.label || undefined}
          onChange={handleChange}
          maxLength={140}
          errorMessage={errorMessage}
          data-testid="evaluation-label"
          tabIndex={isTouchDevice ? -1 : undefined}
        />
        <div className="basis-full flex justify-end gap-4 mt-6">
          <Button
            onClick={handleClose}
            state="secondary"
            disabled={evalState === EvaluationState.Saving}
            size="sm"
            data-testid="cancel-evaluation-btn"
          >
            {t('common.cancel')}
          </Button>
          <LoadedButton
            isLoading={evalState === EvaluationState.Saving}
            onClick={onSubmit}
            disabled={evalState === EvaluationState.Saving || !!errorMessage}
            size="sm"
            data-testid="proceed-to-save-btn"
          >
            {t('evaluationV2.save')}
          </LoadedButton>
        </div>
      </div>
    </Modal>
  );
};
