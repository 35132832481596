import React from 'react';

import { Button, Modal, ModalBackToTitle } from 'components';
import {
  CustomerLoanApplicationActivitiesQuery,
  DataBarrierFunctionality,
  useCustomerLoanApplicationActivitiesQuery,
} from 'generated/graphql';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';
import { useTranslation } from 'react-i18next';
import {
  ArrayElement,
  DATE_FORMAT_MONTH_NAME,
  TIME_FORMAT_WITH_MS_12H,
  formatDate,
} from 'utils';

import { ActivityBadge } from 'modules/common/ActivityBadge/ActivityBadge';
import { QuerySuspense } from 'modules/common/QuerySuspense/QuerySuspense';
import { handleViewInformApplication } from 'utils/inform';

interface LoanApplicationActivitiesModalProps {
  onClose: () => void;
  customerName: string;
  customerId: string;
  applicationId: string;
  onBackToAll: () => void;
}

export const LoanApplicationActivitiesModalTitle: React.FC<
  LoanApplicationActivitiesModalProps
> = ({ onClose, customerName, applicationId, onBackToAll }) => {
  const { t } = useTranslation();
  return (
    <div className="bg-gray-50 px-10 py-6">
      <div className="flex justify-between h-14">
        <ModalBackToTitle
          onClose={onClose}
          backTo={{
            title: t(
              'dashboard.loanApplicationActivities.modal.allLoanApplicationActivities'
            ),
            onClick: onBackToAll,
          }}
        />
      </div>
      <div className="flex justify-between items-center">
        <div className="flex gap-x-4">
          <span>{customerName}</span>
        </div>
        <div>
          <Button
            data-testid="view-application-btn"
            state="secondary"
            size="sm"
            onClick={() => handleViewInformApplication(applicationId)}
          >
            {t('dashboard.loanApplicationActivities.modal.viewApplication')}
          </Button>
        </div>
      </div>
    </div>
  );
};

interface LoanApplicationActivityEntityProps {
  loanApplicationActivity: ArrayElement<
    Exclude<
      CustomerLoanApplicationActivitiesQuery['customerLoanApplicationActivities'],
      null | undefined
    >['activities']
  >;
}

export const LoanApplicationActivityEntity: React.FC<
  LoanApplicationActivityEntityProps
> = ({ loanApplicationActivity }) => {
  const { t } = useTranslation();
  return (
    <div className="border rounded-lg p-5" data-testid="loan-application-modal-entity">
      <div className="flex justify-between text-gray-600 border-b pb-4 mb-4 text-xs">
        <span data-testid="loan-application-date">
          {formatDate(loanApplicationActivity!.eventOccurredAt, DATE_FORMAT_MONTH_NAME)}
        </span>
        <span data-testid="loan-application-time">
          {formatDate(loanApplicationActivity!.eventOccurredAt, TIME_FORMAT_WITH_MS_12H)}
        </span>
      </div>

      <div className="flex" data-testid="loan-application-status">
        <span className="mr-2">
          {t('dashboard.loanApplicationActivities.modal.stage')}
          {': '}
        </span>

        <ActivityBadge
          status={loanApplicationActivity ? loanApplicationActivity.description : ''}
        />
      </div>
    </div>
  );
};

export const LoanApplicationActivitiesModal: React.FC<
  LoanApplicationActivitiesModalProps
> = (props) => {
  const { data, isLoading, error } = useQueryFetch(
    useCustomerLoanApplicationActivitiesQuery,
    {
      queryHookParams: {
        customerId: props.customerId,
        applicationId: props.applicationId,
        dataBarrierFunctionality: DataBarrierFunctionality.Home,
      },
    }
  );
  const { t } = useTranslation();
  const customerLoanApplicationActivities = data?.customerLoanApplicationActivities;
  return (
    <Modal
      className="w-[750px] lg:w-[850px] xl:w-[1050px]"
      isOpen={true}
      onClose={props.onClose}
      title={<LoanApplicationActivitiesModalTitle {...props} />}
    >
      <div className="h-[500px] mb-10 overflow-auto">
        <div data-testid="loan-appication-id" className="px-10 py-6 flex font-semibold">
          <span>
            {`${t('dashboard.loanApplicationActivities.modal.applicationId')} : ${
              props.applicationId
            }`}
          </span>
        </div>
        <QuerySuspense
          error={error}
          isLoading={isLoading}
          noData={
            !customerLoanApplicationActivities ||
            customerLoanApplicationActivities === null
          }
        >
          <div
            data-testid="customer-loan-appication-activities"
            className="px-10 py-2 flex flex-col gap-y-3"
          >
            {customerLoanApplicationActivities?.activities.map(
              (loanApplicationActivity, index) => (
                <LoanApplicationActivityEntity
                  key={index}
                  loanApplicationActivity={
                    loanApplicationActivity ? loanApplicationActivity : null
                  }
                />
              )
            )}
          </div>
        </QuerySuspense>
      </div>
    </Modal>
  );
};
