import React from 'react';

import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import clsx from 'clsx';

import { ReactComponent as SpinnerSvg } from 'assets/icons/spinner.svg';

interface LoadingSpinnerProps {
  className?: string;
  size?: SizeProp;
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  className,
  size = '3x',
}) => {
  const sizes: { [key in SizeProp]: string } = {
    xs: 'w-3',
    sm: 'w-[14px]',
    lg: 'w-5',
    '1x': 'w-[1rem]',
    '2x': 'w-[2rem]',
    '3x': 'w-[3rem]',
    '4x': 'w-[4rem]',
    '5x': 'w-[5rem]',
    '6x': 'w-[6rem]',
    '7x': 'w-[7rem]',
    '8x': 'w-[8rem]',
    '9x': 'w-[9rem]',
    '10x': 'w-[10rem]',
  };
  return (
    <div
      data-testid="loading"
      className={clsx(
        className,
        'flex',
        'justify-center',
        'items-center',
        size ? sizes[size] : ''
      )}
    >
      <SpinnerSvg className={clsx('animate-spin', 'select-none')} />
    </div>
  );
};
