import React from 'react';

import { Button } from 'components';

export interface DashboardCardProps {
  label?: string;
  className?: string;
  CardComponent?: React.FunctionComponent;
  actionButton?: ActionButtonType;
}

export interface ModalProps {
  onClose: () => void;
}

export interface ActionButtonType {
  label: string;
  ModalComponent?: React.FunctionComponent<ModalProps>;
}

export const DashboardCard: React.FC<DashboardCardProps> = ({
  label = '',
  CardComponent,
  className,
  actionButton: { ModalComponent, label: actionLabel } = {},
}) => {
  let [isOpen, setIsOpen] = React.useState(false);
  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  return (
    <div className={className || 'w-full'}>
      <div
        className="flex flex-row items-center py-4 px-3 h-[60px] bg-secondary-50
       border-l border-t border-r rounded-tl-xl rounded-tr-xl"
      >
        <span className="subheadline grow mr-5">{label}</span>
        {actionLabel && (
          <Button
            size="md"
            state="secondary"
            className="ml-5 h-9 whitespace-nowrap"
            onClick={handleOpen}
          >
            {actionLabel}
          </Button>
        )}
        {ModalComponent && isOpen && <ModalComponent onClose={handleClose} />}
      </div>
      <div className="p-4 bg-white border-l border-b border-r rounded-bl-xl rounded-br-xl">
        {CardComponent && <CardComponent />}
      </div>
    </div>
  );
};
