import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import localeEn from './locale/en.json';

const language = process.env.REACT_APP_LANGUAGE || 'en';

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    lng: language,
    detection: {
      order: ['cookie', 'localStorage'],
      lookupCookie: 'NAV_TRANSLATE_LANG_KEY',
    },
    resources: {
      en: { translation: localeEn },
    },
  });

export default i18next;
