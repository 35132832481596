import { EvaluationInput } from 'generated/graphql';
import { t } from 'i18next';
import { FieldErrors, Resolver } from 'react-hook-form';

export const saveEvaluationResolver: Resolver<EvaluationInput> = async (values) => {
  const errors: FieldErrors<EvaluationInput> = {};
  if (!values?.label?.trim()) {
    errors.label = { type: 'required', message: t('validation.required') };
  }
  return {
    values: Object.keys(errors).length ? {} : values,
    errors,
  };
};
