import React from 'react';
import { Link, generatePath, useParams } from 'react-router-dom';

import clsx from 'clsx';
import { SingleLoanQuery } from 'generated/graphql';
import { DATE_FORMAT, DATE_FORMAT_UNDERSCORE, formatDate } from 'utils';

import { useCustomisation, usePaths } from 'modules/root/Settings';
import { useBranding } from 'modules/root/Settings/contexts/branding';
import { useAccess } from 'modules/root/auth/Authenticated/Permission/permission.context';
import { useAuth } from 'modules/root/auth/auth.context';
import { printMoney } from 'utils/print';

import { useCustomerProfileContext } from '../../CustomerProfile';
import { PlainLoanFieldsFormatter } from './LoanFields';
import { getLoanMarginGraphAsBase64 } from './SingleLoan/LoanMarginGraph/loanMarginGraphBase64';
import {
  PdfData,
  PdfField,
  usePDFGeneration,
} from './SingleLoan/LoanPdf/usePDFGeneration';

interface Props {
  loan: NonNullable<SingleLoanQuery['loan']>;
  customer: SingleLoanQuery['customer'];
  showOpportunity: boolean;
  className?: string;
}
const linkStyles = clsx(
  'font-semibold',
  'h-5',
  'inline-flex',
  'uppercase',
  'text-xs',
  'tracking-[1px]'
);
const idleStyles = clsx(
  'text-disabled',
  'border-disabled',
  'hover:text-primary-700',
  'hover:border-b-2'
);
const activeLinkStyles = clsx('text-primary-600', 'border-primary-600', 'border-b-2');
const actionLinkStyles = clsx('border-dashed');

export const prepareLogoSvg = (svgInline: string, color: string): string => {
  const trimmedSvg = svgInline.replace(/\r?\n|\r/g, '').trim();
  return trimmedSvg.replace(/#ffffff/gi, color);
};

const getBase64Logo = (logoPath: string, color: string): Promise<string> => {
  return fetch(logoPath)
    .then((res) => res.text())
    .then((inline) => {
      const svg = prepareLogoSvg(inline, color);
      return `data:image/svg+xml;base64,${window.btoa(svg)}`;
    });
};

export const LoanActions: React.FC<Props> = ({
  loan,
  customer,
  showOpportunity,
  className,
}) => {
  const paths = usePaths();
  const { footerLogo } = useBranding();
  const { pdfHexColor } = useCustomisation();

  const { permissions } = useAccess();
  const { customerId } = useParams();
  const { profile } = useAuth();
  const config = useCustomisation();
  const filename = `${loan?.obligor}-${loan?.obligation}-${formatDate(
    new Date().toUTCString(),
    DATE_FORMAT_UNDERSCORE
  )}`;
  const { customerExternalId } = useCustomerProfileContext();
  const { mutate, isLoading } = usePDFGeneration(filename, customerExternalId);
  const handlePdfDownload = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    if (!isLoading) {
      const loanFields = new PlainLoanFieldsFormatter(loan, customer, config);
      const pdfData: PdfData = {
        logo: '',
        color: pdfHexColor,
        date: formatDate(new Date().toLocaleDateString(), DATE_FORMAT),
        username: profile?.name || profile?.username || '',
        name: customer.displayName,
        sections: {
          details: config.labels.details,
          keyInformation: config.labels.keyInformation,
          interestRates: config.labels.interestRates,
          interestPayments: config.labels.interestPayments,
          interestHistory: config.labels.interestHistory,
          pledgedAccounts: config.labels.pledgedAccounts,
          opportunity: config.labels.opportunity,
          blendedRates: config.labels.blendedRates,
          marginAnalysis: config.labels.marginAnalysis,
        },
        details: loanFields.getDetails() as PdfField[],
        keyInformation: loanFields.getKeyInformation() as PdfField[],
        interestRates: loanFields.getInterestRates() as PdfField[],
        interestPayments: loanFields.getInterestPayments() as PdfField[],
        interestHistory: loanFields.getInterestHistory() as PdfField[],
        pledgedAccounts: loanFields.getPledgedAccounts() as PdfField[],
        opportunity: (showOpportunity ? loanFields.getOpportunity() : []) as PdfField[],
        blendedRates: loanFields.getBlendedRates() as PdfField[],
        marginAnalysis: {
          marginGraphCannotBeDisplayed: config.labels.graphCannotBeDisplayed,
          eligibleMarketValue: {
            label: config.labels.eligibleMarketValue,
            value: loanFields.eligibleMarketValue as string,
          },
          sellOutThreshold: {
            label: config.labels.sellOutThreshold,
            value: printMoney(loan.selloutThreshold),
          },
          topUpThreshold: {
            label: config.labels.topUpThreshold,
            value: printMoney(loan.topupThreshold),
          },
        },
      };
      if (loan.topUpCushion) {
        pdfData.marginAnalysis!.topUpCushion = {
          label: config.labels.topUpCushion,
          value: printMoney(loan.topUpCushion),
        };
      }
      if (
        loan.topupThreshold &&
        loan.selloutThreshold &&
        loan.eligibleMarketValue &&
        loan.fixAmount &&
        loan.repaymentFixAmount
      ) {
        if (loan.selloutThreshold >= loan.eligibleMarketValue) {
          pdfData.marginAnalysis!.selloutWarning = {
            collateral: printMoney(loan.fixAmount),
            paydown: printMoney(loan.repaymentFixAmount),
          };
        } else if (loan.topupThreshold >= loan.eligibleMarketValue) {
          pdfData.marginAnalysis!.topUpWarning = {
            collateral: printMoney(loan.fixAmount),
            paydown: printMoney(loan.repaymentFixAmount),
          };
        }
      }
      Promise.all([
        getBase64Logo(footerLogo, pdfHexColor),
        getLoanMarginGraphAsBase64(loan),
      ])
        .then(([logoBase64, chartBase64]) => {
          pdfData.logo = logoBase64;
          pdfData.marginAnalysis!.base64 = chartBase64;
          mutate(pdfData);
        })
        .catch(() => {
          mutate(pdfData);
        });
    }
  };

  return (
    <div
      data-testid="loan-navigation"
      className={clsx(
        className,
        'h-[50px]',
        'mb-8',
        '-mx-6 lg:-mx-10 xl:-mx-16',
        'shadow-bottom',
        'flex',
        'flex-col',
        'justify-end'
      )}
    >
      <div
        className={clsx(
          'flex',
          'flex-row',
          'content-between',
          'mx-[44px] lg:mx-[60px] xl:mx-[84px]',
          'items-baseline'
        )}
      >
        <div className="basis-1/2">
          <Link
            className={clsx(
              linkStyles,
              { [activeLinkStyles]: true },
              { [idleStyles]: false }
            )}
            to={generatePath(paths.customer.loans.details, {
              customerId,
              loanId: loan.id,
            })}
          >
            {config.labels.loanDetails}
          </Link>
        </div>
        <div className="basis-1/2 flex flex-row justify-end">
          {permissions.canAccessLoanPdf && (
            <Link
              className={clsx(
                linkStyles,
                actionLinkStyles,
                { [activeLinkStyles]: false },
                { [idleStyles]: true }
              )}
              to={'#'}
              onClick={handlePdfDownload}
            >
              {config.labels.downloadPdf}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};
