import React from 'react';

import clsx from 'clsx';

import { Button } from './Button';

export interface FileUploadButtonProps {
  'data-testid'?: string;
  label: string;
  className?: string;
  disabled?: boolean;
  fileType?: string;
  error?: string;
  onChange: (file: React.ChangeEvent<HTMLInputElement>) => void;
}
export const FileUploadButton: React.FC<FileUploadButtonProps> = ({
  'data-testid': dataTestId,
  className,
  label,
  disabled,
  fileType,
  error,
  onChange,
}) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  return (
    <div className={clsx(['inline-block items-center', className])}>
      <Button
        onClick={() => fileInputRef.current?.click()}
        disabled={disabled}
        data-testid={dataTestId}
      >
        {label}
      </Button>
      <input
        type="file"
        className="hidden"
        accept={fileType}
        onChange={onChange}
        ref={fileInputRef}
      />
      {error && (
        <div role="alert" className="text-red-500 mt-1 h-5 text-sm absolute">
          {error}
        </div>
      )}
    </div>
  );
};
