import React from 'react';

import clsx from 'clsx';
import { Tooltip } from 'components';

import info from 'assets/icons/info.svg';

type HelpIconProps = {
  text: string | null | undefined;
  className?: string;
  size?: 'base' | 'sm';
  'data-testid'?: string;
};
export const HelpIcon: React.FC<HelpIconProps> = ({
  text,
  size = 'base',
  className,
  'data-testid': dataTestId,
}) => {
  const handleClick = React.useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
  }, []);

  return text ? (
    <div
      className={clsx(
        className,
        { 'h-5': size === 'base', 'h-4': size === 'sm' },
        'flex items-center normal-case'
      )}
    >
      <Tooltip text={text} className="leading-none">
        <img
          onClick={handleClick}
          data-testid={dataTestId}
          alt={'info'}
          src={info}
          className={clsx(
            { 'h-5': size === 'base', 'h-3': size === 'sm' },
            'inline',
            'w-4',
            'min-w-[1rem]'
          )}
        />
      </Tooltip>
    </div>
  ) : null;
};
