import React from 'react';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';

import { Card } from 'components';
import {
  ActivityAuditType,
  CollateralAccountsQuery,
  useCollateralAccountsQuery,
} from 'generated/graphql';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';

import { useCustomerProfileContext } from 'modules/Customers/CustomerProfile';
import { QuerySuspense } from 'modules/common/QuerySuspense/QuerySuspense';
import { useCustomisation, usePageTitle } from 'modules/root/Settings';
import { ArrayElement } from 'utils/genericType';

import { AccountBlock } from './AccountBlock/AccountBlock';
import { TotalCollateralGraph } from './TotalCollateralGraph/TotalCollateralGraph';
import { AccountsCheck, getAssetTotal } from './TotalCollateralGraph/assetTotal';

export type CollAccount = ArrayElement<
  CollateralAccountsQuery['customer']['collateralAccounts']
>;
export const CollateralAccounts: React.FC = () => {
  const { labels } = useCustomisation();
  const { customerName, isDataIssue } = useCustomerProfileContext();
  usePageTitle([customerName, labels.accounts]);
  const { customerId } = useParams();
  const location = useLocation();
  const activeAccountId = location.state;
  const activeAccElemRef = React.createRef<HTMLDivElement>();
  const [highlight, setHighlight] = React.useState(false);
  const { data, error, isLoading } = useQueryFetch(useCollateralAccountsQuery, {
    queryHookParams: { customerID: customerId! },
    extra: {
      auditReport: { activityType: ActivityAuditType.Read },
    },
  });
  const [accountsChecks, setAccountChecks] = React.useState<AccountsCheck | null>(null);
  React.useEffect(() => {
    setAccountChecks(
      data?.customer.collateralAccounts?.reduce<AccountsCheck>((res, account) => {
        if (account) {
          res[account.collateralAccountId] = true;
        }
        return res;
      }, {}) || null
    );
  }, [data?.customer.collateralAccounts]);
  const assetTotal = React.useMemo(
    () =>
      accountsChecks && data?.customer.collateralAccounts
        ? getAssetTotal(data?.customer.collateralAccounts, accountsChecks)
        : null,
    [data?.customer.collateralAccounts, accountsChecks]
  );
  React.useLayoutEffect(() => {
    if (activeAccElemRef) {
      activeAccElemRef.current?.scrollIntoView({ behavior: 'smooth' });
      setHighlight(true);
    }
  }, [activeAccElemRef]);
  return (
    <div className="px-8 lg:px-12 xl:px-20 py-6 lg:py-12 flex flex-col lg:flex-row gap-4">
      <div className="flex flex-col basis-1/3 grow touchable:min-w-[360px] lg:max-w-[548px] text-sm">
        <Card title={labels.accounts} className="h-full">
          <QuerySuspense
            error={error}
            isLoading={isLoading}
            noData={!data?.customer.collateralAccounts}
            noDataMessage={labels.noActiveAccounts}
          >
            <div className="flex flex-col gap-2 p-2">
              {data?.customer.collateralAccounts?.map((acc) => (
                <AccountBlock
                  key={acc?.id}
                  account={acc}
                  mRef={activeAccountId === acc?.id ? activeAccElemRef : null}
                  className={
                    highlight && activeAccountId === acc?.id
                      ? 'animate-highlight'
                      : undefined
                  }
                />
              ))}
            </div>
          </QuerySuspense>
        </Card>
      </div>
      <div className="flex flex-col basis-1/3 grow">
        <div className="sticky top-0">
          <Card title={labels.totalCollateralView} className="h-full">
            <QuerySuspense
              error={error}
              isLoading={!assetTotal && !accountsChecks && isLoading}
              noData={!data?.customer.collateralAccounts}
              noDataMessage={labels.graphCannotBeDisplayed}
            >
              {assetTotal && accountsChecks && (
                <TotalCollateralGraph
                  className={'top-5 lg:max-w-[510px] p-4'}
                  assetTotal={assetTotal}
                  accountsChecks={accountsChecks}
                  isDataIssue={isDataIssue}
                  onChangeAccountChecks={setAccountChecks}
                />
              )}
            </QuerySuspense>
          </Card>
        </div>
      </div>
    </div>
  );
};
