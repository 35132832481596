import React from 'react';
import { Outlet, useOutletContext, useParams } from 'react-router';

import {
  AccountListQuery,
  ActivityAuditType,
  LoanStatus,
  useAccountListQuery,
} from 'generated/graphql';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';

const MARGIN_STATUSES = [LoanStatus.TopUp, LoanStatus.SellOut, LoanStatus.OverAdvanced];

type Notification = {
  messageI18n: string;
  dataTestId: string;
  type: 'warning' | 'error' | 'info';
};

type State = {
  isDataIssue: boolean;
  isNoEligible: boolean;
  accountList: {
    data: AccountListQuery | null;
    isLoading: boolean;
    error: unknown;
  };
  notification: {
    accounts: Notification | null;
  };
};

type Action = {
  accountsNotification?: Notification | null;
  accountList?: { data: AccountListQuery | null; error: unknown };
};

type EvaluationContext = State & {
  accountsNotify: (notification: Notification) => void;
  clearAccountsNotifications: () => void;
};

const reducer = (state: State, action: Action) => {
  const newState: State = { ...state };
  const { accountList, accountsNotification } = action;
  if (accountList) {
    const status = accountList.data
      ? accountList.data.customer.serviceStatus?.status
      : null;
    newState.isDataIssue = status ? status === LoanStatus.DataIssue : false;
    newState.isNoEligible = status ? MARGIN_STATUSES.includes(status) : false;
    newState.accountList.data = accountList.data;
    newState.accountList.isLoading = false;
    newState.accountList.error = accountList.error;
  }
  if (accountsNotification) {
    newState.notification.accounts = accountsNotification;
  } else if (accountsNotification === null) {
    newState.notification.accounts = null;
  }

  // default behaviour for accounts errors
  if (newState.isDataIssue) {
    newState.notification.accounts = {
      type: 'error',
      dataTestId: 'data-issue-msg',
      messageI18n: 'evaluations.accountList.dataIssue',
    };
  } else if (newState.isNoEligible) {
    newState.notification.accounts = {
      type: 'error',
      dataTestId: 'no-eligible-msg',
      messageI18n: 'evaluations.accountList.notEligibleForLoan',
    };
  }
  return newState;
};

export const Evaluation: React.FC = () => {
  const { customerId = '' } = useParams();

  const [state, dispatch] = React.useReducer(reducer, {
    isDataIssue: false,
    isNoEligible: false,
    accountList: {
      data: null,
      isLoading: true,
      error: null,
    },
    notification: {
      accounts: null,
    },
  });

  useQueryFetch(useAccountListQuery, {
    queryHookParams: { customerId },
    queryHookOptions: {
      onSuccess: (data) => {
        dispatch({ accountList: { data: data as AccountListQuery, error: null } });
      },
      onError: (error) => {
        dispatch({
          accountList: {
            data: null,
            error,
          },
        });
      },
    },
    extra: {
      auditReport: { activityType: ActivityAuditType.Read },
    },
  });

  const clearAccountsNotifications = React.useCallback(
    () => dispatch({ accountsNotification: null }),
    []
  );
  const accountsNotify = React.useCallback(
    (notification: Notification) => dispatch({ accountsNotification: notification }),
    []
  );

  const context = React.useMemo(
    () => ({
      ...state,
      clearAccountsNotifications,
      accountsNotify,
    }),
    [accountsNotify, clearAccountsNotifications, state]
  );
  return <Outlet context={context} />;
};

export const useEvaluationContext = () => {
  return useOutletContext<EvaluationContext>();
};
