import React from 'react';

import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontIcon } from 'components';

import { useCustomisation } from 'modules/root/Settings';

import { CustomerLoan } from './PartiesModal';

interface ModalTitleProps {
  setIsOpen: (isOpen: boolean) => void;
  loan: CustomerLoan;
  title: string;
}

export const PartiesModalTitle: React.FC<ModalTitleProps> = ({
  loan,
  setIsOpen,
  title,
}) => {
  const config = useCustomisation();
  return (
    <div className="flex justify-between px-10 py-6">
      <div>
        <div className="mb-2">{title}</div>
        <div className="text-gray-500 text-base font-normal">
          <span className="mr-1">{config.labels.loan}</span>
          <span>{`${loan.obligor} - ${loan.obligation}`}</span>
        </div>
      </div>
      <FontIcon
        className="cursor-pointer"
        onClick={() => setIsOpen(false)}
        icon={faXmark}
      />
    </div>
  );
};
