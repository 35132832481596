import { INFORM_HOST, SCHEMA_HOST } from 'modules/root/Settings/envVars';

export const setInformCookieFromServer = (
  token: string,
  expiredAt: number
): Promise<void> => {
  const urlPath = '/schema/set-inform-token';
  const url = `${SCHEMA_HOST}${urlPath}`;
  return fetch(url, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({ token, expiredAt }),
  }).then((response) => {
    if (response.ok) {
      console.info('Inform token saved successfully');
    } else {
      console.error('Error occurred trying to save with inform token');
    }
  });
};

export const removeInformCookieFromServer = (): Promise<void> => {
  const urlPath = '/schema/remove-inform-token';
  const url = `${SCHEMA_HOST}${urlPath}`;
  return fetch(url, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'DELETE',
  }).then((response) => {
    if (response.ok) {
      console.info('Inform cookie removed successfully');
    } else {
      console.error('Error occurred trying to removing with inform token');
    }
  });
};

export const informLogin = (authResult: AuthResult): Promise<void> => {
  const LOGIN_URL = `${INFORM_HOST}/jwtLogin`;
  return fetch(LOGIN_URL, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authResult.accessToken}`,
    },
    method: 'GET',
  }).then((response) => {
    if (response.ok) {
      console.info('Successfully logged into inform');
    } else {
      console.error('Error occurred trying to register with inform');
    }
  });
};
