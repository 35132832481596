import React from 'react';

import {
  DashboardCard as DashboardCardConfig,
  DashboardCards,
  DashboardSectionName,
} from 'generated/graphql';

import { AlertsToLoanRatio } from 'modules/Home/Dashboard/cards/AlertsToLoanRatio/AlertsToLoanRatio';

import { ActionButtonType, DashboardCard } from './DashboardCard';
import { SectionView } from './DashboardSection';
import {
  viewAllActivitiesActionButton,
  viewAllAlertsActionButton,
  viewAllLoanApplicationActivitiesActionButton,
} from './actionButton';
import { AlertCard } from './cards/Alert/Alert';
import { CustomerActivity } from './cards/CustomerActivity/CustomerActivity';
import { LoanApplicationActivitiesCard } from './cards/LoanApplicationActivities/LoanApplicationActivities';

export type DashboardCardKeys = keyof Omit<DashboardCards, '__typename'>;
type DashboardListParams = {
  key: string;
  config: DashboardCardConfig | null | undefined;
};
type DashboardCardListValue = (params: DashboardListParams) => React.ReactElement | null;
type DashboardCardListItem = Record<DashboardCardKeys, DashboardCardListValue>;

export const sectionViewMap: Record<DashboardSectionName, SectionView> = {
  activity: 'one-column',
  monitor: 'one-column',
  prospects: 'two-column',
  myPractice: 'three-column',
  market: 'two-column',
};

type DashboardCardBuilder = (
  params: DashboardListParams & {
    CardComponent?: React.FunctionComponent;
    className?: string;
    actionButton?: ActionButtonType;
  }
) => React.ReactElement | null;

const getCard: DashboardCardBuilder = ({
  key,
  config,
  CardComponent,
  className: classname,
  actionButton,
}) => {
  return config?.show ? (
    <DashboardCard
      key={key}
      label={config?.label}
      className={classname}
      CardComponent={CardComponent}
      actionButton={actionButton}
    />
  ) : null;
};

export const cardsMap: DashboardCardListItem = {
  customersActivity: ({ key, config }) =>
    getCard({
      key,
      config,
      CardComponent: CustomerActivity,
      actionButton: viewAllActivitiesActionButton,
    }),
  loanApplicationActivityStatus: ({ key, config }) =>
    getCard({
      key,
      config,
      CardComponent: LoanApplicationActivitiesCard,
      actionButton: viewAllLoanApplicationActivitiesActionButton,
    }),
  alertsToLoanRatio: ({ key, config }) =>
    getCard({
      key,
      config,
      CardComponent: AlertsToLoanRatio,
      actionButton: viewAllAlertsActionButton,
    }),
  alerts: ({ key, config }) =>
    getCard({
      key,
      config,
      actionButton: viewAllAlertsActionButton,
      CardComponent: AlertCard,
    }),
  lendingOpportunities: ({ key, config }) => getCard({ key, config }),
  topClients: ({ key, config }) => getCard({ key, config }),
  customer: ({ key, config }) => getCard({ key, config }),
  loans: ({ key, config }) => getCard({ key, config }),
  balances: ({ key, config }) =>
    getCard({
      key,
      config,
      className: 'grow w-[36%]',
    }),
  sblInterestRates: ({ key, config }) => getCard({ key, config }),
  sofrRates: ({ key, config }) => getCard({ key, config }),
  holdingWatchlist: ({ key, config }) => getCard({ key, config }),
  stockNews: ({ key, config }) => getCard({ key, config }),
};
