import React from 'react';

import { FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

export const ErrorPage: React.FC<FallbackProps> = (props) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col h-[100vh] justify-center items-center">
      <h1>{t('common.error.default')}</h1>
      <div>
        {props.error.message && (
          <>
            <span className="mr-2">{'Error message:'}</span>
            <span className="font-bold">{props.error.message}</span>
          </>
        )}
      </div>
    </div>
  );
};
