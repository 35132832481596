import React from 'react';

import { Button } from 'components';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import {
  AccountSourceUI,
  accountSourceAtom,
  accountsAtom,
  chooseAccountSourceAtom,
  csvDataAtom,
  manualAccountsAtom,
  selAccountIdsAtom,
  selManualAccountIdsAtom,
  showExtraStepAtom,
  thirdPartyCustomerIdsAtom,
} from 'modules/EvaluationV2/models/account';
import { useCustomisation } from 'modules/root/Settings/contexts/customisation';

interface AddAccountsControlProps {
  onClose: () => void;
}

export const AddAccountsControl: React.FC<AddAccountsControlProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const chooseSource = useAtomValue(chooseAccountSourceAtom);
  const [accountSource, setAccountSource] = useAtom(accountSourceAtom);
  const [showExtraStep, setShowExtraStep] = useAtom(showExtraStepAtom);
  const [manualAccounts, setManualAccounts] = useAtom(manualAccountsAtom);
  const [selManualAccountIds, setSelAddAccountIds] = useAtom(selManualAccountIdsAtom);
  const thirdPartyCustomerIds = useAtomValue(thirdPartyCustomerIdsAtom);
  const setCsvData = useSetAtom(csvDataAtom);
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const { labels } = useCustomisation();

  const handleContinue = () => {
    if (accountSource === AccountSourceUI.None) {
      setManualAccounts(null);
      setSelAddAccountIds(new Set());
      if (chooseSource === AccountSourceUI.Import) {
        fileInputRef.current?.click();
      } else {
        setAccountSource(chooseSource);
      }

      if (chooseSource === AccountSourceUI.AddThirdParty) {
        setShowExtraStep(true);
      }
    } else {
      setShowExtraStep(false);
    }
  };

  const handleCsvUpload = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCsvData(e);
      setAccountSource(chooseSource);
    },
    [chooseSource, setAccountSource, setCsvData]
  );

  const [selAccountIds, setSelAccountIds] = useAtom(selAccountIdsAtom);
  const [accounts, setAccounts] = useAtom(accountsAtom);

  const handleAddAccounts = () => {
    if (chooseSource !== AccountSourceUI.None && selManualAccountIds) {
      let manualAccountIds: typeof selManualAccountIds = new Set();
      if (accounts) {
        // filter for any duplicate accounts

        manualAccountIds = new Set(
          Array.from(selManualAccountIds).filter(
            (accId) => !accounts.find((acc) => acc.externalAccountId === accId)
          )
        );
        setAccounts([
          ...accounts,
          ...(manualAccounts || [])?.filter((acc) =>
            manualAccountIds.has(acc.externalAccountId)
          ),
        ]);
      } else {
        manualAccountIds = selManualAccountIds;
        setAccounts([
          ...(manualAccounts || [])?.filter((acc) =>
            manualAccountIds.has(acc.externalAccountId)
          ),
        ]);
      }
      setSelAccountIds(
        new Set([...Array.from(selAccountIds), ...Array.from(manualAccountIds)])
      );
      onClose();
    }
  };

  return (
    <div className="border-gray-100 border-t w-full flex justify-end px-8 py-3 gap-3">
      <Button state="secondary" onClick={onClose} data-testid="cancel-add-accounts-btn">
        {t('common.cancel')}
      </Button>
      {(accountSource === AccountSourceUI.None ||
        (accountSource === AccountSourceUI.AddThirdParty && showExtraStep)) && (
        <Button
          disabled={
            chooseSource === AccountSourceUI.None ||
            (accountSource === AccountSourceUI.AddThirdParty &&
              thirdPartyCustomerIds.length === 0)
          }
          onClick={handleContinue}
        >
          {t('common.continue')}
        </Button>
      )}
      {(accountSource === AccountSourceUI.Acquire ||
        accountSource === AccountSourceUI.Import ||
        (accountSource === AccountSourceUI.AddThirdParty && !showExtraStep)) && (
        <Button
          data-testid="confirm-add-accounts-btn"
          disabled={
            chooseSource === AccountSourceUI.None || selManualAccountIds.size === 0
          }
          onClick={handleAddAccounts}
        >
          {`Add ${labels.accounts}`}
        </Button>
      )}
      <input
        type="file"
        className="hidden"
        accept=".csv"
        onChange={handleCsvUpload}
        ref={fileInputRef}
      />
    </div>
  );
};
