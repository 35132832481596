import {
  AccountSource,
  Currency,
  EvaluationAsset,
  EvaluationCollateralAccountPayload,
} from 'generated/graphql';

import { Account } from './types';

export const buildEvaluationCollateralAccounts = (
  selectedAccounts: Set<string | null>,
  allAccounts: Account[]
) => {
  return allAccounts.reduce<EvaluationCollateralAccountPayload[]>((accounts, acc) => {
    const accountId = acc?.source === AccountSource.System ? acc?.id : null;
    if (!acc?.source || !acc?.id || !selectedAccounts.has(acc?.id)) {
      return accounts;
    }

    const assetHoldings = acc.assetHoldings?.reduce<EvaluationAsset[]>(
      (assets, asset) => {
        if (asset) {
          assets.push({
            assetId: asset?.securityId,
            currency: Currency.Usd,
            quantity: asset?.quantity,
            type: asset?.assetType,
          });
        }
        return assets;
      },
      []
    ) || [];

    accounts.push({
      id: accountId,
      externalAccountId: acc.collateralAccountId,
      accountType: acc?.accountType,
      ownerId: acc?.ownerId,
      custodian: acc?.custodian,
      pledged: acc?.pledged,
      source: acc?.source,
      evaluationAssets: assetHoldings,
    });
    return accounts;
  }, []);
};
