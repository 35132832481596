import React from 'react';
import { Link, generatePath, useParams } from 'react-router-dom';

import { LoanSummaryQuery } from 'generated/graphql';
import { ArrayElement } from 'utils';

import { usePaths } from 'modules/root/Settings';

type Loan = ArrayElement<LoanSummaryQuery['customer']['loans']>;

interface AccountLinksProps {
  loanCollateral: NonNullable<Loan>['loanCollateral'];
  collateralAccounts: LoanSummaryQuery['customer']['collateralAccounts'];
}

export const AccountLinks: React.FC<AccountLinksProps> = ({
  loanCollateral,
  collateralAccounts,
}) => {
  const paths = usePaths();
  const hasAccount = (collateralAccountId?: string): boolean => {
    return Boolean(collateralAccounts?.find((ca) => ca?.id === collateralAccountId));
  };
  const { customerId } = useParams();
  return (
    <>
      {loanCollateral?.map((collateral) =>
        hasAccount(collateral?.collateralAccountId) ? (
          <div key={collateral?.accountId}>
            <Link
              className="underline"
              to={generatePath(paths.customer.accounts, { customerId })}
              state={collateral?.collateralAccountId}
              data-testid="account-links"
            >
              {collateral?.accountId}
            </Link>
          </div>
        ) : (
          <div key={collateral?.accountId} data-testid="account-links">
            {collateral?.accountId}
          </div>
        )
      )}
    </>
  );
};
