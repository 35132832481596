import React from 'react';

import { Card } from 'components';
import {
  DetailSectionSubject,
  Maybe,
  PartiesSectionFieldTypeName,
  PartiesSectionsFieldType,
} from 'generated/graphql';

import { ReactComponent as LegalSvg } from 'assets/icons/legal.svg';
import { ReactComponent as PersonSvg } from 'assets/icons/person.svg';

import { CustomisationConfig, useCustomisation } from 'modules/root/Settings';

import { isLegalEntity } from '../../../partiesUtils';
import { PartiesListCustomer } from '../../PartiesModalBody';
import { CustomSection, RelationshipSection } from './Sections';

interface Props {
  party: PartiesListCustomer;
  title: string;
  buttonName: string;
  fields: Maybe<PartiesSectionsFieldType>[];
  isLast: boolean;
}
const getFieldLabel = (
  labels: CustomisationConfig['labels'],
  fieldName?: PartiesSectionFieldTypeName
): string => {
  if (fieldName === PartiesSectionFieldTypeName.GovId) {
    return labels.govId;
  } else if (fieldName === PartiesSectionFieldTypeName.CustomerExternalId) {
    return labels.customerId;
  } else if (fieldName === PartiesSectionFieldTypeName.Type) {
    return labels.legalEntityType;
  } else if (fieldName === PartiesSectionFieldTypeName.GovernmentFederalTaxId) {
    return labels.taxId;
  } else if (fieldName === PartiesSectionFieldTypeName.CollateralAccounts) {
    return labels.collateralAccountNumbers;
  } else {
    throw new Error('Unknown selection for fieldName');
  }
};
export const PartiesTreeItem: React.FC<Props> = ({ party, title, fields, isLast }) => {
  const { labels } = useCustomisation();
  const legalType: DetailSectionSubject = isLegalEntity(party)
    ? DetailSectionSubject.LegalEntity
    : DetailSectionSubject.IndividualCustomer;
  const fieldsToShow = fields?.filter((fields) => fields?.type === legalType);

  return (
    <div
      className="flex items-center min-h-[88px] relative"
      data-testid="parties-tree-item"
    >
      <div className="h-full absolute">
        <div className="border-l-[1px] border-b-[1px] border-gray-200 w-5 h-[50%] top-0"></div>
        {!isLast && (
          <div className="border-l-[1px] border-gray-200 w-5 h-[50%] bottom-0"></div>
        )}
      </div>
      <Card className="grow ml-7 mb-2">
        <div className="flex justify-between p-5">
          <div className="flex items-start">
            <div className="rounded-full overflow-hidden border-2 w-12 h-12">
              {isLegalEntity(party) ? (
                <LegalSvg className="bg-primary-50 p-3 fill-primary-300 stroke-primary-300 w-full h-full" />
              ) : (
                <PersonSvg className="bg-primary-50 p-3 fill-primary-300 stroke-primary-300 w-full h-full" />
              )}
            </div>
            <RelationshipSection relationship={title} displayName={party.displayName} />
            <div className="flex">
              {fieldsToShow?.map((fields) => {
                return (fields?.values || []).map((field) => {
                  const fieldName = field?.fieldName;
                  const fieldLabel = getFieldLabel(labels, fieldName);
                  const value = fieldName ? party[fieldName] : null;
                  const values = Array.isArray(value)
                    ? value.map((acc) => acc?.collateralAccountId || '')
                    : [value || ''];
                  return (
                    field?.show &&
                    fieldLabel &&
                    fieldName &&
                    value && (
                      <CustomSection
                        key={fieldName}
                        field={fieldLabel}
                        values={values}
                        showMask={
                          fieldName === PartiesSectionFieldTypeName.GovId ||
                          fieldName === PartiesSectionFieldTypeName.GovernmentFederalTaxId
                        }
                        className="ml-5 pl-5 border-l-2 border-grayColor"
                      />
                    )
                  );
                });
              })}
            </div>
          </div>
          {/* <Button state="secondary" size="md" className="ml-5 h-9">
            {buttonName}
          </Button> */}
        </div>
      </Card>
    </div>
  );
};
