import React, { useState } from 'react';

import { faMultiply, faSearch } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { FontIcon } from 'components';
import { debounce } from 'lodash';

import { useIsTouchDevice } from 'utils/helpers';

export interface SearchBoxProps {
  placeholder: string;
  onChange: (value: string) => void;
  initialValue?: string;
  className?: string;
}
const DEBOUNCE_MS = 500;
export const SearchBox: React.FC<SearchBoxProps> = ({
  onChange,
  placeholder,
  initialValue,
  className,
}) => {
  const [query, setQuery] = useState<string>(initialValue || '');
  const debouncedOnChange = React.useMemo(
    () => debounce(onChange, DEBOUNCE_MS, { leading: false }),
    [onChange]
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
    debouncedOnChange(event.target.value);
  };
  const handleTextClear = () => {
    setQuery('');
    onChange('');
  };

  const iconStyles = clsx(['h-4 w-4 ml-2 text-primary-700 justify-center']);
  const isTouchDevice = useIsTouchDevice();
  return (
    <div
      className={clsx(
        className,
        'group',
        'w-full',
        'items-center',
        'flex',
        'border',
        'rounded-md',
        'border-gray-300',
        'bg-white',
        'hover:bg-gray-50',
        'px-4',
        'py-3'
      )}
    >
      <input
        aria-label={placeholder}
        type="text"
        placeholder={placeholder}
        value={query}
        onChange={handleChange}
        tabIndex={isTouchDevice ? -1 : undefined}
        className="flex text-gray-700 w-full outline-none group-hover:bg-gray-50"
      />
      {query ? (
        <FontIcon className={iconStyles} icon={faMultiply} onClick={handleTextClear} />
      ) : (
        <FontIcon className={iconStyles} icon={faSearch} />
      )}
    </div>
  );
};
