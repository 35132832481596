import React, { CSSProperties, useRef, useState } from 'react';

import useResizeObserver from '@react-hook/resize-observer';
import clsx from 'clsx';
import { Tooltip } from 'components';

import { useIsTouchDevice } from 'utils/helpers';

export interface EllipsisProps {
  children?: string | JSX.Element | JSX.Element[] | null;
  className?: string | React.ReactElement;
  style?: CSSProperties;
  'data-testid'?: string;
}

export const Ellipsis: React.FC<EllipsisProps> = ({
  children,
  className,
  style,
  'data-testid': dataTestId,
}) => {
  // Overflow detection
  const [overflowDetected, setOverflowDetected] = useState<boolean>(false);
  const overflowingText = useRef<HTMLDivElement | null>(null);
  const checkOverflow = (textContainer: HTMLDivElement | null): boolean => {
    if (textContainer) {
      return (
        textContainer.offsetHeight < textContainer.scrollHeight ||
        textContainer.offsetWidth < textContainer.scrollWidth
      );
    }
    return false;
  };

  useResizeObserver(overflowingText, () => {
    if (checkOverflow(overflowingText.current)) {
      setOverflowDetected(true);
      return;
    }
    setOverflowDetected(false);
  });
  const isTouchDevice = useIsTouchDevice();
  const handleClick = React.useCallback(
    (event: React.MouseEvent) => {
      if (overflowDetected && isTouchDevice) {
        event.stopPropagation();
        event.preventDefault();
      }
    },
    [overflowDetected, isTouchDevice]
  );
  return (
    <>
      <Tooltip text={children} active={overflowDetected}>
        <div
          data-testid={'ellipsis-content'}
          className={clsx(className, 'flex')}
          style={style}
        >
          <div
            className={clsx('truncate flex-1')}
            ref={overflowingText}
            data-testid={dataTestId}
            onClick={handleClick}
          >
            {children}
          </div>
        </div>
      </Tooltip>
    </>
  );
};
