import { SingleLoanQuery } from 'generated/graphql';
import Highcharts from 'highcharts';
import HighchartsExport from 'highcharts/modules/exporting';
import HighchartsOfflineExport from 'highcharts/modules/offline-exporting';

import { getLoanMarginGraphOptions } from './graphOptions';

HighchartsExport(Highcharts);
HighchartsOfflineExport(Highcharts);

const replaceCssVariableColors = (svg: string) => {
  // extract and replace chart colors from css variables
  const regex = /(var\([\w-]+\))/g;
  const styles = getComputedStyle(document.documentElement);
  const svgWithColor = svg.replace(regex, (match) => {
    const colorVar = match.slice(4, -1); // extract the captured variable name
    return styles.getPropertyValue(colorVar);
  });
  return svgWithColor;
};

const getBlobFromRenderedChart = async (options: Highcharts.Options): Promise<string> => {
  const renderTo = document.createElement('div');

  //   render Highchart
  const renderedChart = Highcharts.chart({
    ...options,
    chart: {
      ...options.chart,
      width: 300,
      height: 150,
      renderTo,
    },
  });
  //   convert to svg
  const asSvg = renderedChart.getSVG({});
  const trimmedSvg = asSvg.replace(/\r?\n|\r/g, '').trim();
  const svgWithColor = replaceCssVariableColors(trimmedSvg);
  return `data:image/svg+xml;base64,${window.btoa(svgWithColor)}`;
};

export const getLoanMarginGraphAsBase64 = async (
  loan: NonNullable<SingleLoanQuery['loan']>
): Promise<string> => {
  if (!(loan.eligibleMarketValue && loan.topupThreshold && loan.selloutThreshold)) {
    return '';
  }

  const options = getLoanMarginGraphOptions({
    eligible: loan.eligibleMarketValue,
    sellOut: loan.selloutThreshold,
    topUp: loan.topupThreshold,
    topUpCushion: loan.topUpCushion,
  });

  const blob = await getBlobFromRenderedChart(options);
  return blob;
};
