import React from 'react';

import clsx from 'clsx';
import { NavIcon } from 'components';
import { useAtom } from 'jotai';
import { Trans, useTranslation } from 'react-i18next';

import customerIcon from 'assets/icons/customer.svg';
import customersIcon from 'assets/icons/customers.svg';
import uploadIcon from 'assets/icons/upload.svg';
import csvExample from 'assets/uploads/Sample Manually Upload Collateral Account.csv';

import {
  AccountSourceUI,
  chooseAccountSourceAtom,
} from 'modules/EvaluationV2/models/account';
import { useCustomisation } from 'modules/root/Settings';

interface SourceSelectBtnProps {
  selected: boolean;
  className: string;
  'data-testid'?: string;
  onClick: () => void;
}

const SourceSelectBtn: React.FC<React.PropsWithChildren<SourceSelectBtnProps>> = ({
  children,
  selected,
  className,
  onClick,
  'data-testid': dataTestId,
}) => {
  return (
    <div
      data-testid={dataTestId}
      className={clsx(
        className,
        'gap-4 border border-gray-200 rounded-xl p-4 cursor-pointer select-none',
        'focus:outline focus:outline-2 focus:shadow-[0_10px_15px_-3px_rgba(0,0,0,0.5)]',
        {
          'border-2 border-primary-600 font-bold': selected,
          'border-gray-100 border hover:border-primary-200 my-px': !selected,
        }
      )}
      role="button"
      onClick={onClick}
      tabIndex={0}
      onKeyDown={(e) => {
        const isTabKey = e.key === 'Tab';
        if (!isTabKey && (e.key === 'Enter' || ' ')) {
          e.preventDefault();
          onClick();
        }
      }}
    >
      {children}
    </div>
  );
};

export const AccountSourceSelector: React.FC = () => {
  const { t } = useTranslation();
  const { labels, evaluation } = useCustomisation();
  const [chooseSource, setChooseSource] = useAtom(chooseAccountSourceAtom);

  const handleSelectSource = (source: AccountSourceUI) => {
    if (chooseSource === source) {
      setChooseSource(AccountSourceUI.None);
    } else {
      setChooseSource(source);
    }
  };
  return (
    <div className="p-8 flex flex-col gap-5 focus:outline-none" tabIndex={0}>
      <div className="flex gap-4">
        {evaluation.showButtons.addCustomerAccounts && (
          <div className="w-full">
            <SourceSelectBtn
              data-testid="add-customer-accounts-btn"
              className="flex flex-col h-full"
              selected={chooseSource === AccountSourceUI.Acquire}
              onClick={() => handleSelectSource(AccountSourceUI.Acquire)}
            >
              <NavIcon
                source={customerIcon}
                alt={'Customer Account Icon'}
                className="w-4 h-4"
              />
              <div className="w-32 text-left text-base">
                {labels.acquireUnpledgedAccounts}
              </div>
            </SourceSelectBtn>
          </div>
        )}
        {evaluation.showButtons.addThirdPartyAccounts && (
          <div className="w-full">
            <SourceSelectBtn
              data-testid="add-third-party-accounts-btn"
              className="flex flex-col h-full"
              selected={chooseSource === AccountSourceUI.AddThirdParty}
              onClick={() => handleSelectSource(AccountSourceUI.AddThirdParty)}
            >
              <NavIcon
                source={customersIcon}
                alt={'Third-party Account Icon'}
                className="w-4 h-4"
              />
              <div className="w-32 text-left text-base">
                {t('evaluationV2.addThirdParty')}
              </div>
            </SourceSelectBtn>
          </div>
        )}
      </div>
      {(evaluation.showButtons.addCustomerAccounts ||
        evaluation.showButtons.addThirdPartyAccounts) &&
        evaluation.showButtons.importViaCSV && (
          <div className="flex gap-2 items-center">
            <div className="w-full border-b border-gray-300"></div>
            <div className="uppercase text-sm select-none text-gray-300">
              {t('common.or')}
            </div>
            <div className="w-full border-b border-gray-300"></div>
          </div>
        )}
      <div className="flex flex-col items-center gap-4">
        {evaluation.showButtons.importViaCSV && (
          <>
            <SourceSelectBtn
              data-testid="import-accoutns-btn"
              className="flex items-center justify-center w-full"
              selected={chooseSource === AccountSourceUI.Import}
              onClick={() => handleSelectSource(AccountSourceUI.Import)}
            >
              <NavIcon source={uploadIcon} alt={'Upload Icon'} className="w-5 h-5" />
              <div className="h-5">{labels.importAccountWithHoldings}</div>
            </SourceSelectBtn>
            <div className="text-sm">
              <Trans i18nKey="evaluationV2.downloadCsvTemplate">
                <a
                  href={csvExample}
                  download="Sample Manually Upload Collateral Account.csv"
                >
                  download csv example
                </a>
              </Trans>
            </div>
          </>
        )}
      </div>
      <input data-testid="choose-source-input" type="hidden" value={chooseSource} />
    </div>
  );
};
