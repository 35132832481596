import React from 'react';

import { BreadCrumb, BreadCrumbTheme } from 'components';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT_TIME, formatDate } from 'utils';

import { breadcrumbsAtom } from '../models/breadcrumb';
import { evaluationDetailsAtom } from '../models/evaluation';

interface BreadcrumbProps {}

export const EvaluationBreadcrumb: React.FC<BreadcrumbProps> = (props) => {
  const breadcrumbs = useAtomValue(breadcrumbsAtom);
  const { t } = useTranslation();
  const evaluationDetails = useAtomValue(evaluationDetailsAtom);
  return (
    <div className="flex h-12 w-full border-b-[1px] border-b-gray-200 items-center pl-10">
      <BreadCrumb list={breadcrumbs} theme={BreadCrumbTheme.light} />
      <div className="text-right"></div>
      {evaluationDetails && evaluationDetails.lastEvaluated && (
        <div className="text-right text-primary-600 grow pl-5 pr-4">{`${t(
          'evaluations.lastEvaluated'
        )}: ${formatDate(evaluationDetails.lastEvaluated, DATE_FORMAT_TIME)}`}</div>
      )}
    </div>
  );
};
