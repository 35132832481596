import React from 'react';

import { useTranslation } from 'react-i18next';

import { usePageTitle } from 'modules/root/Settings';

interface PageNotFoundProps {}

export const PageNotFound: React.FC<PageNotFoundProps> = (props) => {
  const { t } = useTranslation();
  usePageTitle(['404', t('common.error.pageNotFound')]);
  return (
    <div className="flex flex-col items-center justify-center h-full gap-16 select-none">
      <div className="text-[120px] font-extrabold opacity-20 text-primary-600 tracking-widest">
        {'404'}
      </div>
      <h1 className="text-primary-600 opacity-40">{t('common.error.pageNotFound')}</h1>
    </div>
  );
};
