import React from 'react';

import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontIcon } from 'components';
import { HighchartsGraph } from 'components';
import { useTranslation } from 'react-i18next';

import { useCustomisation } from 'modules/root/Settings';

import { ASSET_TYPES, AccountsCheck, AssetTotal } from './assetTotal';
import { getOptions } from './graphOptions';

interface TotalCollateralGraphProps {
  assetTotal: AssetTotal;
  accountsChecks: AccountsCheck;
  isDataIssue: boolean;
  className?: string;
  onChangeAccountChecks: (updates: AccountsCheck) => void;
}

export const TotalCollateralGraph: React.FC<TotalCollateralGraphProps> = ({
  className,
  assetTotal,
  accountsChecks,
  isDataIssue,
  onChangeAccountChecks,
}) => {
  const { t } = useTranslation();
  const isAllAccountChecksDisabled = Object.keys(accountsChecks).every(
    (key) => !accountsChecks[key]
  );
  const hasAnyAccount = Object.keys(accountsChecks).length > 0;
  const { labels } = useCustomisation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const updates = { ...accountsChecks, [event.target.name]: event.target.checked };
    onChangeAccountChecks(updates);
  };

  const options = React.useMemo(
    () => getOptions(labels, assetTotal, t),
    [labels, assetTotal, t]
  );
  return (
    <div className={className} data-testid="total-collateral-view">
      <div className={'ml-9'}>
        {Object.keys(accountsChecks).map((name, index) => (
          <label className={'caption text-xs h-5 mr-5 cursor-pointer'} key={index}>
            <input
              type="checkbox"
              name={name}
              checked={accountsChecks[name]}
              onChange={handleChange}
              className={'align-middle'}
            />{' '}
            {name}
          </label>
        ))}
      </div>
      {(() => {
        if (hasAnyAccount && isAllAccountChecksDisabled) {
          return (
            <div className="notification mt-5" data-testid="info-message">
              <FontIcon className="mr-2" icon={faTriangleExclamation} />{' '}
              {labels.selectAccountsCollateralGraph}
            </div>
          );
        }
        if (isDataIssue || ASSET_TYPES.every((key) => assetTotal[key] === 0)) {
          return (
            <div className="warning" data-testid="warning-message">
              <FontIcon className="mr-2" icon={faTriangleExclamation} />
              {labels.graphCannotBeDisplayed}
            </div>
          );
        }
        return (
          <figure
            className={'w-full flex justify-center'}
            data-testid="total-collateral-graph"
          >
            <HighchartsGraph options={options} />
          </figure>
        );
      })()}
    </div>
  );
};
