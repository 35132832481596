import React from 'react';

import { Button } from 'components';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

import {
  EvaluationState,
  evaluationStateAtom,
} from 'modules/EvaluationV2/models/evaluation';

import { useInputState } from '../../useInputState';
import { SaveEvaluationModal } from '../modals/SaveEvaluationModal';

export const SaveEvalutionButton: React.FC = () => {
  const [showSaveModal, setShowSaveModal] = React.useState(false);
  const { isDirty } = useInputState();
  const { t } = useTranslation();
  const evalState = useAtomValue(evaluationStateAtom);

  const handleSaveModalClose = () => {
    setShowSaveModal(false);
  };
  return (
    <>
      <Button
        className="ml-5"
        onClick={() => setShowSaveModal(true)}
        state="secondary"
        disabled={
          (evalState !== EvaluationState.Ran &&
            evalState !== EvaluationState.SaveFailed) ||
          isDirty
        }
        data-testid="save-evaluation-btn"
      >
        {t('evaluationV2.save')}
      </Button>

      {showSaveModal && <SaveEvaluationModal onClose={handleSaveModalClose} />}
    </>
  );
};
