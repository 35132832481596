import React from 'react';

import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';

import { useIsTouchDevice } from 'utils/helpers';

interface ModalProps {
  isOpen: boolean;
  children: React.ReactNode;
  footer?: React.ReactNode;
  title?: React.ReactNode;
  className?: string;
  'data-testid'?: string;
  onClose?: () => void;
}

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  title,
  footer,
  className,
  'data-testid': dataTestId,
}) => {
  const isTouchDevice = useIsTouchDevice();

  const handleClose = () => {
    if (!isTouchDevice && onClose) {
      onClose();
    }
  };

  return (
    <Transition appear={true} show={isOpen} as={React.Fragment}>
      <Dialog onClose={handleClose}>
        <div
          id="backdrop"
          data-testid={dataTestId}
          className={clsx(
            'z-20',
            'fixed',
            'inset-0',
            'backdrop-opacity-10',
            'backdrop-invert',
            'bg-black/30'
          )}
          aria-hidden="true"
        />

        <div className={clsx('fixed', 'inset-0', 'overflow-y-auto', 'z-30')}>
          <div className={clsx('flex', 'min-h-full', 'items-center', 'justify-center')}>
            <Transition.Child
              enter="duration-300 ease-out"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              as={React.Fragment}
            >
              <Dialog.Panel
                className={clsx(
                  className,
                  'rounded-lg',
                  'bg-white',
                  'shadow-xl',
                  'overflow-hidden'
                )}
              >
                {title && (
                  <Dialog.Title className="modal-title border-b border-gray-100" as="div">
                    {title}
                  </Dialog.Title>
                )}
                {children}
                {footer}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
