import React, { useContext } from 'react';

import { UserPermissions } from 'generated/graphql';

export type Permissions = NonNullable<UserPermissions>;
export type Permission = keyof Permissions;
interface PermissionContextProps {
  permissions: Permissions;
}

export const PermissionContext = React.createContext<PermissionContextProps>({
  permissions: {},
});

export const useAccess = () => useContext(PermissionContext);
