import React from 'react';

import clsx from 'clsx';
import {
  Content,
  LoadingOver,
  SearchQueryParams,
  ShrinkHeader,
  TableSkeleton,
  useLimitedPagination,
  useSearchQueryParams,
  useSorting,
} from 'components';
import {
  ActivityAuditType,
  CustomersListQuery,
  CustomersListQueryVariables,
  CustomersSearchQuery,
  CustomersSearchQueryVariables,
  useCustomersListQuery,
  useCustomersSearchQuery,
} from 'generated/graphql';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';
import { useTranslation } from 'react-i18next';
import { ResourceAccessed } from 'services/auditReport';
import { ArrayElement } from 'utils';

import { QuerySuspense } from 'modules/common/QuerySuspense/QuerySuspense';
import { useCustomisation, usePageTitle } from 'modules/root/Settings';
import { notEmpty } from 'utils/helpers';

import { CustomersTable } from './CustomersTable';
import { SearchBox } from './SearchBox/SearchBox';

const DEFAULT_PAGE_SIZE = 10;
const QUERY_LIMIT = 10000;

export type CustomerListItem = NonNullable<
  ArrayElement<CustomersSearchQuery['customersSearch']['items']>
>;

const useQueryResolver = (
  queryParams: SearchQueryParams,
  auditResourceAccessed?: string
) => {
  const {
    data: listData,
    isLoading: listIsLoading,
    refetch: refetchList,
    isFetched: listIsFetched,
    isFetching: listIsFetching,
    error: listError,
  } = useQueryFetch(useCustomersListQuery, {
    queryHookOptions: { enabled: false },
    queryHookParams: queryParams as CustomersListQueryVariables,
    extra: {
      auditReport: {
        activityType: ActivityAuditType.Read,
        resourceAccessed: auditResourceAccessed,
      },
    },
  });
  const {
    data: searchData,
    isLoading: searchIsLoading,
    refetch: refetchSearch,
    isFetched: searchIsFetched,
    isFetching: searchIsFetching,
    error: searchError,
  } = useQueryFetch(useCustomersSearchQuery, {
    queryHookOptions: { enabled: false },
    queryHookParams: queryParams as CustomersSearchQueryVariables,
    extra: {
      auditReport: {
        activityType: ActivityAuditType.Search,
        resourceAccessed: auditResourceAccessed,
      },
    },
  });

  if (queryParams.searchText) {
    return {
      data: searchData,
      isLoading: searchIsLoading,
      refetch: refetchSearch,
      isFetched: searchIsFetched,
      isFetching: searchIsFetching,
      error: searchError,
    };
  }

  return {
    data: listData,
    isLoading: listIsLoading,
    refetch: refetchList,
    isFetched: listIsFetched,
    isFetching: listIsFetching,
    error: listError,
  };
};

const isCustomersListQuery = (
  data: CustomersSearchQuery | CustomersListQuery
): data is CustomersListQuery => {
  return 'customersList' in data;
};

export const useCustomersQuery = (
  queryParams: SearchQueryParams,
  auditResourceAccessed?: ResourceAccessed
) => {
  const [total, setTotal] = React.useState(0);
  const [currentItems, setCurrentItems] = React.useState<null | CustomerListItem[]>(null);

  const { data, isLoading, error, isFetched, isFetching, refetch } = useQueryResolver(
    queryParams,
    auditResourceAccessed
  );
  React.useEffect(() => {
    if (refetch) {
      refetch();
    }
  }, [refetch, queryParams]);

  React.useEffect(() => {
    if (isFetched && data && !error) {
      const items = isCustomersListQuery(data)
        ? data?.customersList.items?.filter(notEmpty) || []
        : data?.customersSearch.items?.filter(notEmpty) || [];
      setCurrentItems(items);
      setTotal(
        isCustomersListQuery(data)
          ? data.customersList.totalHits
          : data.customersSearch.totalHits
      );
    }
  }, [data, error, isFetched, queryParams.searchText]);

  return {
    total,
    items: currentItems,
    isLoading: currentItems === null && isLoading,
    isUpdating: isFetching,
    error,
  };
};

export const Customers: React.FC = () => {
  const { handlePageChange, isLimitReached, pagination } = useLimitedPagination(
    DEFAULT_PAGE_SIZE,
    QUERY_LIMIT
  );
  const { handleSortChange, sorting } = useSorting();
  const [searchText, setSearchText] = React.useState<string>('');
  const queryParams = useSearchQueryParams({ sorting, pagination, searchText });
  const handleSearch = React.useCallback(
    (value: string) => {
      handlePageChange({ ...pagination, pageIndex: 0 });
      setSearchText(value.trim());
    },
    [handlePageChange, pagination]
  );
  const { total, items, error, isLoading, isUpdating } = useCustomersQuery(queryParams);

  const { labels } = useCustomisation();
  usePageTitle(labels.customers);
  const { t } = useTranslation();

  return (
    <Content
      shrinkHeader={
        <ShrinkHeader
          title={labels.customers}
          buttons={
            <>
              <SearchBox
                onChange={handleSearch}
                placeholder={`${t('common.searchBox.find')} ${labels.customers}`}
              />
            </>
          }
        />
      }
    >
      <div className="px-8 lg:px-14 xl:px-20 py-6 lg:py-10 xl:py-14">
        <QuerySuspense
          error={error}
          isLoading={isLoading}
          noData={Boolean(items && items.length === 0 && !isLoading && !isUpdating)}
          noDataMessage={searchText ? t('common.searchBox.noRecords') : undefined}
          loadingRenderer={() => <TableSkeleton />}
        >
          {searchText && total > QUERY_LIMIT && (
            <div className={clsx('warning', 'mt-4')}>
              {t('common.searchBox.manyRecords')}
            </div>
          )}
          {items && (
            <LoadingOver show={isUpdating}>
              <CustomersTable
                customers={items}
                total={total}
                pagination={pagination}
                onPageChange={handlePageChange}
                sorting={sorting}
                onSortChange={handleSortChange}
              />
            </LoadingOver>
          )}
        </QuerySuspense>
        {isLimitReached && (
          <div className={clsx('float-right', 'alert', 'mt-4')}>
            {t('common.pagination.limitRecords')}
          </div>
        )}
      </div>
    </Content>
  );
};
