import React, { useRef, useState } from 'react';

import {
  autoUpdate,
  offset,
  useFloating,
  useHover,
  useInteractions,
} from '@floating-ui/react';
import { arrow, flip, shift } from '@floating-ui/react';
import clsx from 'clsx';

export interface TooltipProps {
  children?: string | JSX.Element | JSX.Element[] | null;
  text?: string | JSX.Element | JSX.Element[] | null;
  active?: boolean;
  className?: string;
  'data-testid'?: string;
}

export const Tooltip: React.FC<TooltipProps> = ({
  children,
  text,
  active = true,
  className,
  'data-testid': dataTestId,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const arrowRef = useRef(null);
  const { x, y, strategy, refs, context, placement } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(10),
      flip(),
      shift(),
      arrow({
        element: arrowRef,
      }),
    ],
  });
  const handleClick = React.useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
  }, []);
  const hover = useHover(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);
  const { x: arrowX, y: arrowY } = context.middlewareData.arrow ?? {};
  const staticSide =
    {
      top: 'bottom',
      right: 'left',
      bottom: 'top',
      left: 'right',
    }[placement.split('-')[0]] ?? '';

  return (
    <>
      <div
        ref={refs.setReference}
        {...getReferenceProps()}
        data-testid={dataTestId}
        className={className}
      >
        {children}
      </div>
      {active && isOpen && (
        <div
          onClick={handleClick}
          className={clsx(
            'max-w-[300px]',
            'drop-shadow-tooltip',
            'text-gray-700',
            'text-sm',
            'font-normal',
            'bg-white',
            'rounded',
            'px-3.5',
            'py-4',
            'whitespace-pre-line',
            'z-30',
            'break-words'
          )}
          ref={refs.setFloating}
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
          }}
          data-testid={`${dataTestId}-tooltip`}
          {...getFloatingProps()}
        >
          {text}
          <div
            ref={arrowRef}
            className={clsx('absolute', 'w-[10px]', 'h-[10px]', 'bg-white', 'rotate-45')}
            style={{
              left: arrowX != null ? `${arrowX}px` : '',
              top: arrowY != null ? `${arrowY}px` : '',
              [staticSide]: '-5px',
            }}
          ></div>
        </div>
      )}
    </>
  );
};
