import { t } from 'i18next';

import { ActionButtonType } from './DashboardCard';
import { AllAlertsModal } from './cards/Alert/AllAlertsModal';
import { CustomerAllActivitiesModal } from './cards/CustomerActivity/modals/CustomerAllActivitiesModal';
import { AllLoanApplicationActivitiesModal } from './cards/LoanApplicationActivities/modals/AllLoanApplicationActivitiesModal';

export const viewAllAlertsActionButton: ActionButtonType = {
  label: t('dashboard.alerts.actionButton.label'),
  ModalComponent: AllAlertsModal,
};

export const viewAllActivitiesActionButton: ActionButtonType = {
  label: t('dashboard.activities.actionButton.label'),
  ModalComponent: CustomerAllActivitiesModal,
};

export const viewAllLoanApplicationActivitiesActionButton: ActionButtonType = {
  label: t('dashboard.loanApplicationActivities.actionButton.label'),
  ModalComponent: AllLoanApplicationActivitiesModal,
};
