import React from 'react';

import { Transition } from '@headlessui/react';
import { Loading } from 'components';
import { useTranslation } from 'react-i18next';

interface QuerySuspenseProps {
  children: React.ReactNode;
  isLoading: boolean;
  error: any;
  noData?: boolean;
  className?: string;
  noDataMessage?: string;
  errorRenderer?: (error: any) => React.ReactElement;
  loadingRenderer?: () => React.ReactElement;
}

export const QuerySuspense: React.FC<QuerySuspenseProps> = ({
  children,
  isLoading,
  noData,
  className,
  error,
  noDataMessage,
  errorRenderer,
  loadingRenderer,
}) => {
  const { t } = useTranslation();
  if (isLoading) {
    return loadingRenderer ? (
      loadingRenderer()
    ) : (
      <div className="h-full flex items-center justify-center">
        <Loading />
      </div>
    );
  }

  if (error) {
    return errorRenderer ? errorRenderer(error) : <DefaultErrorComponent error={error} />;
  }

  if (noData) {
    return (
      <div data-testid="no-data" className="notification">
        {noDataMessage || t('common.noData')}
      </div>
    );
  }

  return (
    <Transition
      className={className}
      appear={true}
      show={!isLoading}
      enter="transition-opacity duration-500"
      enterFrom="opacity-50"
      enterTo="opacity-100"
      leave="transition-opacity duration-500"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      {children}
    </Transition>
  );
};

export interface QuerySuspenseErrorProps {
  error: any;
}

const DefaultErrorComponent: React.FC<QuerySuspenseErrorProps> = ({ error }) => {
  const { t } = useTranslation();

  const errorMessage =
    error?.response?.errors
      ?.map((e: any) => e?.message)
      ?.filter((e: string) => !!e)
      ?.join(',') || (typeof error === 'string' ? t(error) : t('common.error.default'));
  return <div className="warning">{errorMessage}</div>;
};
