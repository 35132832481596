import React from 'react';

import clsx from 'clsx';

import { CreditPolicy } from 'modules/EvaluationV2/types';

interface CreditPolicyItemProps {
  creditPolicy: CreditPolicy;
  selected?: boolean;
  onSelect: (creditPolicy: CreditPolicy) => void;
}

export const CreditPolicyItem: React.FC<CreditPolicyItemProps> = ({
  creditPolicy,
  selected = false,
  onSelect,
}) => {
  return (
    <label
      data-testid="evaluation-credit-policy-item"
      className={clsx('rounded-xl px-5 py-4 cursor-pointer', {
        'border-2 border-primary-600': selected,
        'border-gray-100 border hover:border-primary-200': !selected,
      })}
      onClick={() => onSelect(creditPolicy)}
    >
      <div className="flex items-center">
        <div className="font-bold">{creditPolicy.description.value}</div>
        <div className="flex grow justify-end">
          <input
            type="radio"
            name="credit-policy-item-radio"
            className="appearance-none peer"
          />
          <div
            className={clsx('w-5 h-5 rounded-full p-1 peer-focus-visible:outline', {
              'border border-gray-200': !selected,
              'border-2 border-primary-600': selected,
            })}
          >
            {selected && (
              <div className="bg-primary-600 rounded-full w-full h-full"></div>
            )}
          </div>
        </div>
      </div>
    </label>
  );
};
