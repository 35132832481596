import React, { useCallback, useEffect } from 'react';

import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontIcon, Modal } from 'components';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import {
  AccountSourceUI,
  accountSourceAtom,
  chooseAccountSourceAtom,
  showExtraStepAtom,
  thirdPartyCustomerIdsAtom,
} from 'modules/EvaluationV2/models/account';
import { useCustomisation } from 'modules/root/Settings/contexts/customisation';

import { AccountSourceSelector } from './sources/AccountSourceSelector';
import { AddAccountSelector } from './sources/AddAccountSelector';
import { AddAccountsControl } from './sources/AddAccountsControl';
import { ThirdPartyAccountsSelector } from './sources/ThirdPartyAccountsSelector';

interface AddAccountsProps {
  isOpen: boolean;
  onClose: () => void;
}

const AddAccountsModalTitle: React.FC = () => {
  const { t } = useTranslation();
  const [accountSource, setAccountSource] = useAtom(accountSourceAtom);
  const [showExtraStep, setShowExtraStep] = useAtom(showExtraStepAtom);
  const { labels } = useCustomisation();
  const handleBack = () => {
    if (accountSource === AccountSourceUI.AddThirdParty && !showExtraStep) {
      setShowExtraStep(true);
    } else {
      setAccountSource(AccountSourceUI.None);
      setShowExtraStep(false);
    }
  };

  return (
    <div className="flex justify-between px-10 py-6">
      {(() => {
        if (
          accountSource === AccountSourceUI.Acquire ||
          accountSource === AccountSourceUI.Import ||
          accountSource === AccountSourceUI.AddThirdParty
        ) {
          return (
            <div className="flex items-center gap-2">
              <FontIcon
                size="sm"
                className="mr-2"
                icon={faAngleLeft}
                onClick={handleBack}
              />
              {showExtraStep && accountSource === AccountSourceUI.AddThirdParty
                ? t('evaluationV2.addCustomerId')
                : labels.selectManualAccounts}
            </div>
          );
        }
        return <div>{`Add ${labels.accounts}`}</div>;
      })()}
    </div>
  );
};

export const AddAccountsModal: React.FC<AddAccountsProps> = ({ isOpen, onClose }) => {
  const source = useAtomValue(accountSourceAtom);
  const [showExtraStep, setShowExtraStep] = useAtom(showExtraStepAtom);
  const setChooseSource = useSetAtom(chooseAccountSourceAtom);
  const setAccountSource = useSetAtom(accountSourceAtom);
  const setThirdPartyCustomerIds = useSetAtom(thirdPartyCustomerIdsAtom);

  const handleClose = useCallback(() => {
    setAccountSource(AccountSourceUI.None);
    setChooseSource(AccountSourceUI.None);
    setShowExtraStep(false);
    setThirdPartyCustomerIds([]);
    onClose();
  }, [
    setAccountSource,
    setChooseSource,
    setShowExtraStep,
    setThirdPartyCustomerIds,
    onClose,
  ]);

  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscKey);
    }
    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [isOpen, handleClose]);

  if (!isOpen) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} className="w-[500px]" title={<AddAccountsModalTitle />}>
      {source === AccountSourceUI.None && <AccountSourceSelector />}
      {source === AccountSourceUI.AddThirdParty && showExtraStep && (
        <ThirdPartyAccountsSelector />
      )}
      {source !== AccountSourceUI.None && !showExtraStep && <AddAccountSelector />}
      <AddAccountsControl onClose={handleClose} />
    </Modal>
  );
};
