import React from 'react';

import { LoadingSpinner } from 'components';
import {
  ActivityAuditType,
  CreditPoliciesQuery,
  useCreditPoliciesQuery,
} from 'generated/graphql';
import { useAtom } from 'jotai';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';

import {
  creditPoliciesAtom,
  creditPolicyIdAtom,
} from 'modules/EvaluationV2/models/creditPolicy';
import { CreditPolicy } from 'modules/EvaluationV2/types';
import { QuerySuspense } from 'modules/common/QuerySuspense/QuerySuspense';
import { useCustomisation } from 'modules/root/Settings/contexts/customisation';

import { CreditPolicyItem } from './CreditPolicyItem';

interface ICreditPolicySelectorProps {}

export const CreditPolicySelector: React.FC<ICreditPolicySelectorProps> = (props) => {
  const [creditPolicyId, setCreditPolicyId] = useAtom(creditPolicyIdAtom);
  const [creditPolicies, setCreditPolicies] = useAtom(creditPoliciesAtom);
  const { labels } = useCustomisation();
  const { isLoading, isError } = useQueryFetch(useCreditPoliciesQuery, {
    queryHookOptions: {
      onSuccess: (data) => {
        const creditPolicies = (data as CreditPoliciesQuery).creditPolicies;
        setCreditPolicies(creditPolicies);
      },
    },
    extra: {
      auditReport: { activityType: ActivityAuditType.Read },
    },
  });

  const handleSelect = React.useCallback(
    (creditPolicy: CreditPolicy) => {
      setCreditPolicyId(creditPolicy.id);
    },
    [setCreditPolicyId]
  );

  return (
    <div className="w-full">
      <QuerySuspense
        error={isError}
        isLoading={isLoading}
        loadingRenderer={() => <LoadingSpinner className="mx-auto" />}
      >
        <div className="flex flex-col">
          <h2 className="text-base font-bold mb-8">{labels.chooseCreditPolicy}</h2>
          <div className="flex flex-col gap-3">
            {creditPolicies.map(
              (policy) =>
                policy && (
                  <CreditPolicyItem
                    key={policy.id}
                    creditPolicy={policy}
                    selected={creditPolicyId === policy.id}
                    onSelect={handleSelect}
                  />
                )
            )}
          </div>
        </div>
      </QuerySuspense>
    </div>
  );
};
