import React from 'react';
import { Navigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { usePaths } from 'modules/root/Settings';
import {
  Permission,
  useAccess,
} from 'modules/root/auth/Authenticated/Permission/permission.context';
import { useAuth } from 'modules/root/auth/auth.context';

interface AuthorizedProps {
  children: React.ReactNode;
  permissionList?: Permission[];
}

const Authorized: React.FC<AuthorizedProps> = React.memo(
  ({ children, permissionList = [] }) => {
    const { t } = useTranslation();
    const paths = usePaths();
    const { isAuthenticated, isLoading } = useAuth();
    const { permissions } = useAccess();
    const hasPermissions = permissionList.every((permission) => permissions[permission]);

    if (isLoading) {
      return null;
    }

    if (!isAuthenticated) {
      return <Navigate to={paths.auth.signIn} replace={true} />;
    }

    if (!hasPermissions) {
      return (
        <div className="warning m-2" data-testid="no-permissions-message">
          {t('auth.disallow')}
        </div>
      );
    }
    return <>{children}</>;
  }
);

Authorized.displayName = 'PureAuthorized';

export { Authorized };
