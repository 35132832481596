import React from 'react';
import { generatePath, useParams } from 'react-router-dom';

import { BreadCrumbItemType, Card, Content } from 'components';
import { useGetEvaluationQuery } from 'generated/graphql';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT_TIME, formatDate } from 'utils';

import { EvaluationHeader } from 'modules/common/EvaluationHeader/EvaluationHeader';
import { QuerySuspense } from 'modules/common/QuerySuspense/QuerySuspense';
import { useCustomisation, usePaths } from 'modules/root/Settings';

import { useEvaluationContext } from './Evaluation';
import { EvaluationActions, UIEvaluationStatus } from './NewEvaluation';
import { AccountTable } from './sections/AccountTable';
import { ExistingEvaluationSummary } from './sections/Summary/ExistingEvaluationSummary';
import {
  EvaluationErrorMessage,
  EvaluationLoading,
} from './sections/Summary/QueryHandlers';
import { Account } from './types';

export const ExistingEvaluation: React.FC = () => {
  const { customerId = 'customerId', evaluationId = 'evaluationId' } = useParams();
  const { accountList } = useEvaluationContext();
  const {
    data: evaluationData,
    error: evaluationError,
    isLoading: evaluationLoading,
    refetch: retryFetchEvaluation,
  } = useQueryFetch(useGetEvaluationQuery, {
    queryHookParams: { evaluationId },
  });
  const { t } = useTranslation();
  const paths = usePaths();

  const { labels, sideMenu } = useCustomisation();

  const breadcrumbList: BreadCrumbItemType[] = [];
  if (sideMenu.home.show) {
    breadcrumbList.push({ label: labels.home, link: generatePath(paths.home) });
  }
  if (sideMenu.originations.show) {
    breadcrumbList.push({
      label: labels.originations,
      link: generatePath(paths.origination.list),
    });
    breadcrumbList.push({
      label: t('evaluations.viewExistingEvaluation'),
      link: generatePath(paths.evaluations.view, { customerId, evaluationId }),
    });
  }

  const evaluatedAccounts: Account[] = React.useMemo(() => {
    return (
      evaluationData?.evaluation?.evaluationCollateralAccount.map<Account>((e) => ({
        id: e.id,
        collateralAccountId: e.externalAccountId,
        accountType: e.accountType,
        custodian: e.custodian,
        ownerDisplayName: e.ownerDisplayName,
        marketData: { marketValue: e.marketValue },
        pledged: e.pledged,
        source: e.source,
      })) || []
    );
  }, [evaluationData]);

  return (
    <Content header={<EvaluationHeader list={breadcrumbList} />}>
      <div className="my-6 lg:my-10 mx-8 lg:mx-12 xl:mx-20">
        {evaluationData && (
          <div className="flex flex-row gap-4 text-base lg:text-lg">
            <div className="my-4 basis-1/2">{`${t('evaluations.label')}: ${
              evaluationData.evaluation?.label
            }`}</div>
            <div className="my-4 basis-1/2">{`${t(
              'evaluations.lastEvaluated'
            )}:  ${formatDate(
              evaluationData.evaluation?.lastEvaluated || '',
              DATE_FORMAT_TIME
            )}`}</div>
          </div>
        )}

        <QuerySuspense
          error={accountList.error || evaluationError}
          isLoading={accountList.isLoading || evaluationLoading}
          loadingRenderer={() => <></>}
        >
          {accountList.data?.customer && (
            <Card className="p-6">
              <AccountTable
                collateralAccounts={evaluatedAccounts}
                evaluationStatus={UIEvaluationStatus.Existing}
                viewOnly={true}
                evaluationAction={EvaluationActions.View}
                evaluatedCreditPolicyId={
                  evaluationData?.evaluation?.creditPolicyId || undefined
                }
              />
            </Card>
          )}
        </QuerySuspense>
        <QuerySuspense
          isLoading={evaluationLoading}
          error={evaluationError}
          loadingRenderer={() => <EvaluationLoading />}
          errorRenderer={() => (
            <EvaluationErrorMessage rerunEvaluation={retryFetchEvaluation} />
          )}
        >
          {evaluationData && (
            <Card className="my-5 p-6">
              <ExistingEvaluationSummary evaluationData={evaluationData} />
            </Card>
          )}
        </QuerySuspense>
      </div>
    </Content>
  );
};
