import React from 'react';

import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontIcon } from 'components';
import { HighchartsGraph } from 'components';

import { useCustomisation } from 'modules/root/Settings';
import { getShortener } from 'utils/helpers';

import { CollAccount } from '../CollateralAccounts';
import { LoanValue, getAfterRender, getOptions } from './graphOptions';

interface CollateralApportionmentGraphProps {
  loanCollateral: NonNullable<CollAccount>['loanCollateral'];
  eligibleMarketValue: number;
  isDataIssue: boolean;
}

export const CollateralApportionmentGraph: React.FC<
  CollateralApportionmentGraphProps
> = ({ loanCollateral, eligibleMarketValue, isDataIssue }) => {
  const { labels } = useCustomisation();
  const shortener = getShortener(20);
  const loanValues: LoanValue[] = loanCollateral
    ? loanCollateral.map((loanColl) => {
        return {
          name: `${shortener(loanColl?.obligor || '')}-${shortener(
            loanColl?.obligation || ''
          )}`,
          value: loanColl?.apportionedAmount || 0,
        };
      })
    : [];
  if (!isDataIssue && loanValues.length > 0) {
    return (
      <figure className="mt-[-20px]" data-testid="collateral-apportionment-graph">
        <HighchartsGraph
          options={getOptions(loanValues, eligibleMarketValue)}
          callback={getAfterRender(loanValues.length)}
        />
      </figure>
    );
  }
  return (
    <div data-testid="collateral-apportionment-warning">
      <div data-testid="warning-message">
        <div className="text-alert">
          <FontIcon className="mr-2" icon={faTriangleExclamation} />{' '}
          {labels.graphCannotBeDisplayed}
        </div>
      </div>
    </div>
  );
};
