import {
  AccountSource,
  EvaluationCollateralAccountPayload,
  EvaluationInput,
  GetEvaluationQuery,
  RunEvaluationInput,
  RunEvaluationQuery,
  SaveEvaluationMutation,
} from 'generated/graphql';
import { atom } from 'jotai';

export enum EvaluationAction {
  None = 'NONE',
  CreateNew = 'NEW',
  View = 'VIEW',
  ReEvaluate = 'REEVALUATE',
}

export enum EvaluationState {
  Idle = 'IDLE',
  Processing = 'PROCESSING',
  RunFailed = 'RUN_FAILED',
  Ran = 'RAN',
  Saving = 'SAVING',
  Saved = 'SAVED',
  SaveFailed = 'SAVE_FAILED',
}

type EvaluationAtom =
  | RunEvaluationQuery['runEvaluation']
  | GetEvaluationQuery['evaluation']
  | null;
type SavedEvaluationAtom = SaveEvaluationMutation['saveEvaluation'] | null;

export type EvaluationDetails = {
  label?: string | null;
  lastEvaluated?: string | null;
} | null;

export type SaveDownloadState = {
  enableDownloadPdf?: boolean;
  enableApplyForLoan?: boolean;
  evaluationId: string | null; // needed for loanProposal and PdfDownload Urls
  externalEvalId: string | null;  // needed for loanproposal audit call
} | null;

export const evaluationDetailsAtom = atom<EvaluationDetails>(null);
export const evaluationActionAtom = atom<EvaluationAction>(EvaluationAction.None);
export const evaluationStateAtom = atom<EvaluationState>(EvaluationState.Idle);
export const payloadAtom = atom<RunEvaluationInput, [RunEvaluationInput], void>(
  {
    creditPolicyId: '',
    label: '',
    persist: false,
    evaluationCollateralAccounts: [],
  },
  (get, set, update: RunEvaluationInput) => {
    set(payloadAtom, {
      ...update,
      evaluationCollateralAccounts: update.evaluationCollateralAccounts.map((collAcc) => {
        if (collAcc) {
          const account: EvaluationCollateralAccountPayload = {
            ...collAcc,
            id: collAcc?.source === AccountSource.System ? collAcc?.id : null,
            pledged: collAcc?.source === AccountSource.System ? collAcc?.pledged : false,
            evaluationAssets:
              collAcc?.source === AccountSource.System ? [] : collAcc.evaluationAssets,
          };
          return account;
        }
        return collAcc;
      }),
    });
  }
);
export const payloadSnapshotAtom = atom<RunEvaluationInput>({
  creditPolicyId: '',
  label: '',
  persist: false,
  evaluationCollateralAccounts: [],
});
export const evaluationAtom = atom<EvaluationAtom>(null);
export const savedEvaluationAtom = atom<SavedEvaluationAtom>(null);
export const saveEvaluationInputAtom = atom<EvaluationInput>({
  id: '',
  label: '',
  compareTo: '',
  customerId: '',
  customerName: '',
  lastEvaluated: '',
  description: '',
  creditPolicyId: '',
  evaluationCollateralAccounts: [],
});

export const saveDownloadStateAtom = atom<SaveDownloadState>(null);
