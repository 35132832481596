import React from 'react';

import clsx from 'clsx';

import { ReactComponent as LegalSvg } from 'assets/icons/legal.svg';
import { ReactComponent as PersonSvg } from 'assets/icons/person.svg';

interface PartiesCardProps {
  relationship: string;
  name: string;
  isLegal: boolean;
}

export const PartiesCard: React.FC<PartiesCardProps> = ({
  relationship,
  name,
  isLegal,
}) => {
  return (
    <div className={clsx('flex', 'flex-row', 'my-6', 'px-4', 'align-center')}>
      <div className="mr-3 rounded-full overflow-hidden border-2 w-12 h-12">
        {isLegal ? (
          <LegalSvg className="bg-primary-50 p-3 fill-primary-300 w-full h-full stroke-primary-300" />
        ) : (
          <PersonSvg className="bg-primary-50 p-3 fill-primary-300 w-full h-full" />
        )}
      </div>
      <div className={clsx('flex', 'flex-col')}>
        <div className={clsx('text-gray-700', 'font-semibold', 'flex', 'items-center')}>
          <div className="flex">{name}</div>
        </div>
        <div className={clsx('text-disabled', 'text-sm')}>{relationship}</div>
      </div>
    </div>
  );
};
