import React from 'react';
import { Navigate } from 'react-router-dom';

import { LandingPage } from 'generated/graphql';

import { PageNotFound } from 'modules/Error/PageNotFound';
import { useCustomisation, usePaths } from 'modules/root/Settings';
import { useAuth } from 'modules/root/auth/auth.context';

import { Authorized } from '../auth/Authorized/Authorized';

interface ResolverProps {}

const Resolver: React.FC<ResolverProps> = React.memo(() => {
  const paths = usePaths();
  const { landing } = useCustomisation();
  const { isAuthenticated, isLoading, originPathKey } = useAuth();
  const originPath = localStorage.getItem(originPathKey);
  if (!isAuthenticated) {
    localStorage.setItem(originPathKey, window.location.pathname);
    return <Navigate to={paths.auth.signIn} replace={true} />;
  }
  if (originPath && isAuthenticated && !isLoading) {
    setTimeout(() => localStorage.removeItem(originPathKey), 1000);
    if (originPath !== paths.initial) {
      return <Navigate to={originPath} replace={true} />;
    }
  }

  if (landing.default === LandingPage.Dashboard) {
    return (
      <Authorized>
        <Navigate to={paths.home} />
      </Authorized>
    );
  } else if (landing.default === LandingPage.Customers) {
    return (
      <Authorized permissionList={['canViewCustomers']}>
        <Navigate to={paths.customer.list} />
      </Authorized>
    );
  } else if (landing.default === LandingPage.Evaluations) {
    return (
      <Authorized permissionList={['canViewEvaluations']}>
        <Navigate to={paths.origination.evaluations} />
      </Authorized>
    );
  } else if (landing.default === LandingPage.Applications) {
    return (
      <Authorized permissionList={['canViewApplications']}>
        <Navigate to={paths.origination.applications} />
      </Authorized>
    );
  }
  return <PageNotFound />;
});

Resolver.displayName = 'PureResolver';

export { Resolver };
