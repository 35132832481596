import React from 'react';

import { faXmark } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import {
  FontIcon,
  Modal,
  Pagination,
  SearchQueryParams,
  useLimitedPagination,
  useSearchQueryParams,
} from 'components';
import { LoadingOver } from 'components';
import {
  ActivityAuditType,
  CustomerActivitySearchQuery,
  DataBarrierFunctionality,
} from 'generated/graphql';
import { useCustomerActivitySearchQuery } from 'generated/graphql';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';
import { useTranslation } from 'react-i18next';
import { ResourceAccessed, useSendAuditReport } from 'services/auditReport';
import { ArrayElement } from 'utils';

import { SearchBox } from 'modules/Customers/SearchBox/SearchBox';
import { DashboardList } from 'modules/Home/components/DashboardList';
import { QuerySuspense } from 'modules/common/QuerySuspense/QuerySuspense';
import { useCustomisation } from 'modules/root/Settings';
import { notEmpty } from 'utils/helpers';

import { Activity, ModalState } from '../CustomerActivity';
import { CustomerActvityModal } from './CustomerActivityModal';

const DEFAULT_PAGE_SIZE = 5;
const QUERY_LIMIT = 10000;

export type ActivityItems = NonNullable<
  ArrayElement<CustomerActivitySearchQuery['customerActivitySearch']['items']>
>;
const useActivityQuery = (queryParams: SearchQueryParams) => {
  const [total, setTotal] = React.useState(0);
  const [currentItems, setCurrentItems] = React.useState<null | ActivityItems[]>(null);
  const { data, isLoading, error, isFetched, isFetching, refetch } = useQueryFetch(
    useCustomerActivitySearchQuery,
    {
      queryHookParams: {
        ...queryParams,
        dataBarrierFunctionality: DataBarrierFunctionality.Home,
      },
      queryHookOptions: { enabled: false },
    }
  );
  React.useEffect(() => {
    if (refetch) {
      refetch();
    }
  }, [refetch, queryParams]);

  React.useEffect(() => {
    if (isFetched && data && !error) {
      const items = data?.customerActivitySearch.items?.filter(notEmpty) || [];
      setCurrentItems(items);
      setTotal(data?.customerActivitySearch.totalHits);
    }
  }, [data, error, isFetched, queryParams]);

  return {
    total,
    items: currentItems,
    isFetched,
    isFetching,
    isLoading: data === null && isLoading,
    error,
  };
};
export interface CustomerAllActivitiesModalTitleProps {
  onClose: () => void;
  handleSearch: (value: string) => void;
}

export interface CustomerAllActivitiesModalProps {
  onClose: () => void;
}

export const CustomerAllActivitiesModalTitle: React.FC<
  CustomerAllActivitiesModalTitleProps
> = ({ onClose, handleSearch }) => {
  const { t } = useTranslation();
  const { dashboard } = useCustomisation();
  const section = dashboard.sections.find((section) => !!section.cards.customersActivity);
  return (
    <div className="bg-gray-50 px-10 py-6 rounded-t-lg">
      <div className="flex justify-between">
        <div className="uppercase text-sm text-gray-500 cursor-pointer font-normal  mb-2">
          {section?.cards.customersActivity?.label}
        </div>
        <FontIcon className="cursor-pointer" onClick={onClose} icon={faXmark} />
      </div>
      <SearchBox
        placeholder={`${t('common.searchBox.find')} ${t('dashboard.activities.title')}`}
        className="text-base w-full font-normal"
        onChange={handleSearch}
      />
    </div>
  );
};

export const CustomerAllActivitiesModal: React.FC<CustomerAllActivitiesModalProps> = ({
  onClose,
}) => {
  useSendAuditReport({
    sendOnMount: true,
    audit: {
      activityType: ActivityAuditType.Read,
      resourceAccessed: ResourceAccessed.ViewAllCustomerActivities,
    },
  });
  const { handlePageChange, pagination } = useLimitedPagination(
    DEFAULT_PAGE_SIZE,
    QUERY_LIMIT
  );
  const [searchText, setSearchText] = React.useState<string>('');
  const handleSearch = React.useCallback(
    (value: string) => {
      setSearchText(value.trim());
      handlePageChange({ ...pagination, pageIndex: 0 });
    },
    [handlePageChange, pagination]
  );

  const queryParams = useSearchQueryParams({ pagination, searchText });

  const { items, total, isLoading, isFetched, isFetching, error } =
    useActivityQuery(queryParams);

  const activities = items;
  const [modalState, setModalState] = React.useState<ModalState | null>(null);
  const sendAudit = useSendAuditReport();
  const showCustomerActivityModal = (state: ModalState) => () => {
    sendAudit({
      activityType: ActivityAuditType.Read,
      customerExternalId: state.customerExternalId,
      resourceAccessed: ResourceAccessed.ViewCustomerActivity,
    });
    setModalState(state);
  };
  const { t } = useTranslation();
  if (modalState) {
    return <CustomerActvityModal onClose={() => setModalState(null)} {...modalState} />;
  }

  return (
    <Modal
      className="w-[750px] lg:w-[850px] xl:w-[1050px]"
      isOpen={true}
      onClose={onClose}
      title={
        <CustomerAllActivitiesModalTitle onClose={onClose} handleSearch={handleSearch} />
      }
    >
      <div className="h-[500px]">
        <QuerySuspense
          isLoading={isLoading}
          noData={isFetched && items?.length === 0}
          error={error}
          noDataMessage={searchText ? t('common.searchBox.noRecords') : undefined}
          className="h-full"
        >
          <LoadingOver show={isFetching}>
            <div className="py-6 px-10 h-[424px] overflow-y-auto">
              {searchText && total > QUERY_LIMIT && (
                <div className={clsx('warning', 'my-4')}>
                  {t('common.searchBox.manyRecords')}
                </div>
              )}
              <DashboardList>
                {activities &&
                  activities.map((activity) => (
                    <DashboardList.Item key={activity?.customerId}>
                      <Activity
                        activityStat={activity}
                        showCustomerActivityModal={showCustomerActivityModal}
                      />
                    </DashboardList.Item>
                  ))}
              </DashboardList>
            </div>
            <Pagination
              className="absolute inset-x-0 bottom-0 px-10 py-6 bg-gray-50"
              currentPage={pagination.pageIndex + 1}
              pageRange={5}
              rowsPerPage={DEFAULT_PAGE_SIZE}
              totalRows={total}
              nextPage={() => {
                handlePageChange({
                  ...pagination,
                  pageIndex: pagination.pageIndex + 1,
                });
              }}
              previousPage={() => {
                handlePageChange({
                  ...pagination,
                  pageIndex: pagination.pageIndex - 1,
                });
              }}
              onChange={(page) => {
                handlePageChange({ ...pagination, pageIndex: page - 1 });
              }}
            />
          </LoadingOver>
        </QuerySuspense>
      </div>
    </Modal>
  );
};
