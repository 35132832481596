import React from 'react';

export interface ReactTableBodyProps {
  children?: string | JSX.Element | JSX.Element[];
  'data-testid'?: string;
}

export const ReactTableBody = ({
  children,
  'data-testid': testid,
}: ReactTableBodyProps) => {
  return <tbody data-testid={testid}>{children}</tbody>;
};
