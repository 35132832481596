import React from 'react';
import { generatePath, useNavigate } from 'react-router';

import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { Row, createColumnHelper } from '@tanstack/table-core';
import {
  Ellipsis,
  FontIcon,
  LoadingOver,
  Modal,
  ReactTable,
  TableSkeleton,
  useSearchQueryParams,
} from 'components';
import { ActivityAuditType, SortableFieldEnum } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { ResourceAccessed, useSendAuditReport } from 'services/auditReport';
import { printPrimitive } from 'utils';

import { CustomerListItem, useCustomersQuery } from 'modules/Customers/Customers';
import { SearchBox } from 'modules/Customers/SearchBox/SearchBox';
import { QuerySuspense } from 'modules/common/QuerySuspense/QuerySuspense';
import { useCustomisation, usePaths } from 'modules/root/Settings';

import { INELIGIBLE_STATUSES } from '../constants/status';

interface NewEvaluationModalProps {
  onClose: () => void;
  'data-testid'?: string;
}

interface NewEvaluationModalTitleProps extends NewEvaluationModalProps {
  onSearch: (value: string) => void;
}

const PAGE_SIZE = 20;

export const NewEvaluationModalTitle: React.FC<NewEvaluationModalTitleProps> = ({
  onClose,
  onSearch,
}) => {
  const { t } = useTranslation();
  const { labels } = useCustomisation();
  return (
    <div className="bg-gray-50 px-10 py-6 rounded-t-lg">
      <div className="flex justify-between items-center gap-9">
        <div className="w-full text-base font-normal">
          <SearchBox
            onChange={onSearch}
            placeholder={`${t('common.searchBox.find')} ${labels.customers}`}
          />
        </div>
        <FontIcon className="cursor-pointer" onClick={onClose} icon={faXmark} />
      </div>
    </div>
  );
};

export const NewEvaluationModal: React.FC<NewEvaluationModalProps> = ({
  onClose,
  'data-testid': dataTestId,
}) => {
  const [searchText, setSearchText] = React.useState<string>('');
  const { t } = useTranslation();
  const queryParams = useSearchQueryParams({
    sorting: [{ id: SortableFieldEnum.ServiceStatusPriority, desc: true }],
    pagination: { pageIndex: 0, pageSize: PAGE_SIZE },
    searchText,
  });
  const sendAudit = useSendAuditReport({
    audit: { activityType: ActivityAuditType.Read },
  });
  const { total, items, error, isLoading, isUpdating } = useCustomersQuery(
    queryParams,
    ResourceAccessed.NewEvaluationSeachCustomer
  );
  const handleSearch = React.useCallback((value: string) => {
    setSearchText(value.trim());
  }, []);

  const { labels } = useCustomisation();
  const columnHelper = createColumnHelper<CustomerListItem>();
  const columns = React.useMemo(() => {
    const headerCellsFromConfig = [
      columnHelper.accessor('displayName', {
        header: labels.customer,
        id: SortableFieldEnum.DisplayNamePlain,
        cell: ({ getValue }) => (
          <Ellipsis data-testid="customer-name" className="font-bold">
            {printPrimitive(getValue())}
          </Ellipsis>
        ),
      }),
      columnHelper.accessor('customerId', {
        header: labels.customersTableId,
        id: SortableFieldEnum.CustomerId,
        cell: ({ getValue }) => (
          <Ellipsis data-testid="customer-id">{printPrimitive(getValue())}</Ellipsis>
        ),
      }),
      columnHelper.accessor('serviceStatus', {
        id: SortableFieldEnum.ServiceStatusPriority,
        header: labels.eligibleForSBL,
        cell: ({ getValue }) => {
          const value = getValue();
          if (value && INELIGIBLE_STATUSES.includes(value)) {
            return t('common.no');
          }
          return t('common.yes');
        },
      }),
    ];
    return headerCellsFromConfig;
  }, [labels, columnHelper, t]);

  const paths = usePaths();
  const navigate = useNavigate();
  const handleClick = React.useCallback(
    (row: Row<CustomerListItem>) => {
      const path = generatePath(paths.evaluations.create, {
        customerId: row.original.id,
      });
      sendAudit({
        customerExternalId: row.original.customerId,
        resourceAccessed: ResourceAccessed.NewEvaluationSelectCustomer,
      });
      navigate(path);
    },
    [navigate, paths.evaluations.create, sendAudit]
  );

  return (
    <Modal
      className="w-[700px] lg:w-[850px] xl:w-[1050px]"
      isOpen={true}
      onClose={onClose}
      title={<NewEvaluationModalTitle onClose={onClose} onSearch={handleSearch} />}
      data-testid={dataTestId}
    >
      <div className="h-[500px] px-10 pb-6 overflow-auto">
        <QuerySuspense
          error={error}
          isLoading={isLoading}
          noData={Boolean(items && items.length === 0 && !isLoading && !isUpdating)}
          noDataMessage={searchText ? t('common.searchBox.noRecords') : undefined}
          loadingRenderer={() => <TableSkeleton />}
        >
          <>
            {items && (
              <LoadingOver show={isUpdating}>
                <ReactTable
                  onClick={handleClick}
                  data-testid="new-evaluation-customers-table"
                  stickyFromTopPx={0}
                  data={items}
                  columns={columns}
                />
              </LoadingOver>
            )}
            {searchText && total > PAGE_SIZE && (
              <div className="warning mt-4">{t('common.searchBox.manyRecords')}</div>
            )}
          </>
        </QuerySuspense>
      </div>
    </Modal>
  );
};
