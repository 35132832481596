import React from 'react';

import { HelpIcon, Table, TableBody, TableCell, TableHead, TableRow } from 'components';
import { GetEvaluationQuery, RunEvaluationQuery } from 'generated/graphql';
import { printMoney, printPercent, printPrimitive } from 'utils';

import { useCustomisation } from 'modules/root/Settings';

import EvaluationGraph from '../EvaluationGraph/EvaluationGraph';

type Evaluation = NonNullable<
  GetEvaluationQuery['evaluation'] | RunEvaluationQuery['runEvaluation']
>;
interface SummaryTableProps {
  evaluation: Evaluation;
}

export const SummaryTable: React.FC<SummaryTableProps> = ({ evaluation }) => {
  const { labels, helpText } = useCustomisation();
  return (
    <div className="flex flex-wrap justify-around items-center gap-5">
      <div className="grow">
        <Table className="max-w-[700px]">
          <TableBody>
            <TableRow>
              <TableCell>
                <div className="flex flex-nowrap items-center gap-1">
                  {labels.maxCommitmentAmount}{' '}
                  <HelpIcon text={helpText.maxCommitmentAmount} />
                </div>
              </TableCell>
              <TableCell data-testid="summary-maxCommitmentAmount">
                {printMoney(evaluation.commitmentAmount)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <div className="flex flex-nowrap items-center gap-1">
                  {labels.topUpThreshold} <HelpIcon text={helpText.topUpThreshold} />
                </div>
              </TableCell>
              <TableCell data-testid="summary-topUpThreshold">
                {printMoney(evaluation.topupThreshold)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <div className="flex flex-nowrap items-center gap-1">
                  {labels.sellOutThreshold} <HelpIcon text={helpText.sellOutThreshold} />
                </div>
              </TableCell>
              <TableCell data-testid="summary-sellOutThreshold">
                {printMoney(evaluation.selloutThreshold)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <div className="my-8 border-t-2 pt-8">
          <Table data-testid="summary-asset-table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="flex flex-nowrap items-center gap-1">
                    {labels.assetType} <HelpIcon text={helpText.assetType} />
                  </div>
                </TableCell>
                <TableCell>
                  <div className="flex flex-nowrap items-center gap-1">
                    {labels.marketValue} <HelpIcon text={helpText.marketValue} />
                  </div>
                </TableCell>
                <TableCell>
                  <div className="flex flex-nowrap items-center gap-1">
                    {labels.collateralValue} <HelpIcon text={helpText.collateralValue} />
                  </div>
                </TableCell>
                <TableCell>
                  <div className="flex flex-nowrap items-center gap-1">
                    {labels.rate} <HelpIcon text={helpText.rate} />
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {evaluation.evaluationAssetSummary?.map((asset, index) => (
                <TableRow key={index} data-testid="asset-record">
                  <TableCell>{printPrimitive(asset?.assetType)}</TableCell>
                  <TableCell>{printMoney(asset?.marketValue)}</TableCell>
                  <TableCell>{printMoney(asset?.collateralValue)}</TableCell>
                  <TableCell>{printPercent(asset?.advanceRate)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
      <EvaluationGraph evaluationAssetSummaryValues={evaluation.evaluationAssetSummary} />
    </div>
  );
};
