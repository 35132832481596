import React from 'react';

import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Button, FontIcon, TextInput } from 'components';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { thirdPartyCustomerIdsAtom } from 'modules/EvaluationV2/models/account';

import { ThirdPartyAccountSearchComponent } from './ThirdPartyAccountsSearch';

interface ThirdPartyAccountsSelectorProps {}

export const ThirdPartyAccountsSelector: React.FC<ThirdPartyAccountsSelectorProps> = (
  props
) => {
  const { t } = useTranslation();
  const [newCustomerId, setNewCustomerId] = React.useState<string>('');
  const [inputError, setInputError] = React.useState<string>('');
  const [customerIds, setCustomerIds] = useAtom(thirdPartyCustomerIdsAtom);
  const parentRef = React.useRef<HTMLDivElement>(null);

  const handleChange = (value: string) => {
    setNewCustomerId(value);
    setInputError('');
  };

  const handleAdd = () => {
    if (!newCustomerId) {
      return;
    }
    if (customerIds.includes(newCustomerId)) {
      setInputError('evaluationV2.customerIdAlreadlyAdded');
      return;
    } else {
      setInputError('');
    }

    setCustomerIds([...customerIds, newCustomerId]);
    setNewCustomerId('');
  };

  const handleRemove = (customerId: string) => {
    setCustomerIds(customerIds.filter((id) => id !== customerId));
  };

  const filterInput = (value: string) => {
    return value.replace(/[^0-9a-zA-Z-]/g, '');
  };

  return (
    <div className="p-8 flex flex-col gap-8 overflow-auto h-80" ref={parentRef}>
      <div>{t('evaluationV2.addCustomers')}</div>
      <ThirdPartyAccountSearchComponent parentRef={parentRef} />
      <div className="flex gap-3">
        <TextInput
          placeholder={t('evaluationV2.typeInCustomerId')}
          className="grow"
          onChange={handleChange}
          text={newCustomerId}
          errorMessage={t(inputError)}
          filterFn={filterInput}
          autocomplete={'off'}
          data-testid="new-customer-id"
        />
        <div className="mt-1 mb-4 ">
          <Button
            state="secondary"
            onClick={handleAdd}
            data-testid="add-customer-id-button"
          >
            <FontIcon aria-label="add" size="lg" icon={faPlus} />
          </Button>
        </div>
      </div>
      <div className="flex flex-col gap-3">
        {customerIds.map((customerId) => (
          <div className="flex gap-3" key={customerId}>
            <div
              className="grow border rounded-sm border-gray-300 bg-white px-4 py-3"
              data-testid={`customer-name-${customerId}`}
            >
              {customerId}
            </div>
            <Button
              state="secondary"
              onClick={() => handleRemove(customerId)}
              data-testid={`customer-delete-${customerId}`}
            >
              <FontIcon aria-label="delete" size="lg" icon={faTrashCan} />
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};
