import React from 'react';

import { HelpIcon } from 'components';
import { LoanSummaryQuery } from 'generated/graphql';

import { CustomisationConfig } from 'modules/root/Settings';
import {
  printForAllStatuses,
  printMoney,
  printPercent,
  printPrimitive,
} from 'utils/print';

export const useSummaryFields = (
  config: CustomisationConfig,
  customer?: LoanSummaryQuery['customer']
) => {
  return React.useMemo(
    () =>
      [
        {
          key: 'number-of-loans',
          show: config.portfolioSummary.showFields.numberOfLoans,
          label: config.labels.numberOfLoans,
          showDash: true,
          value: printPrimitive(customer?.portfolioSummary?.numberOfLoans || 0),
        },
        {
          key: 'utilization',
          show: config.portfolioSummary.showFields.utilization,
          label: config.labels.utilization,
          showDash: true,
          value: printPercent(customer?.portfolioSummary?.utilizationRate),
        },
        {
          key: 'total-commitment',
          show: config.portfolioSummary.showFields.totalCommitment,
          label: (
            <div className="flex flex-nowrap items-center gap-1">
              {config.labels.totalCommitment}{' '}
              <HelpIcon text={config.helpText.totalCommitmentAmount} />
            </div>
          ),
          showDash: true,
          value: printMoney(customer?.portfolioSummary?.totalCommitment),
        },
        {
          key: 'total-outstanding-balance',
          show: config.portfolioSummary.showFields.totalOutstandingBalance,
          label: config.labels.totalOutstandingBalance,
          showDash: true,
          value: printMoney(customer?.portfolioSummary?.totalOutstandingBalance),
        },
        {
          key: 'availability',
          show: config.portfolioSummary.showFields.availability,
          label: config.labels.availability,
          showDash: true,
          value: printMoney(customer?.portfolioSummary?.availability),
        },
        {
          key: 'weighted-interest-rate',
          show: config.portfolioSummary.showFields.weightedInterestRate,
          label: config.labels.weightedInterestRate,
          showDash: true,
          value: printPercent(customer?.weightedInterestRate),
        },
      ]
        .filter((field) => field.show)
        .map((field) => {
          field.value = printForAllStatuses(
            field.value,
            field.showDash,
            customer?.loans?.map((loan) => loan?.status) || []
          );
          return field;
        }),
    [config, customer]
  );
};
