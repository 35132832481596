import React from 'react';

import { PartiesSection, PartiesSectionType } from 'generated/graphql';
import { isArray as _isArray } from 'lodash';

import { useCustomisation } from 'modules/root/Settings';

import { PartiesState } from '../../Parties';
import { getSectionName } from '../../PartyItem';
import { PartiesListCustomer } from '../PartiesModalBody';
import { PartiesTreeItem } from './PartiesTreeItem/PartiesTreeItem';

interface Props {
  sections: PartiesSection[];
  parties: PartiesState;
  count: number;
}

export const NodeRelationItem: React.FC<Props> = ({ sections, parties, count }) => {
  const treeSections = sections.filter(
    ({ type }) => type !== PartiesSectionType.Borrower
  );
  const { labels } = useCustomisation();

  let renderedCount = 0;
  return (
    <div className="grow" data-testid="party-node-relation">
      {treeSections.map(({ type, fields }, sectionIdx: number) => {
        const results = parties[type];
        if (fields && results && _isArray(results)) {
          return results.map((result: PartiesListCustomer, idx: number) => {
            renderedCount++;
            return (
              <PartiesTreeItem
                key={result?.displayName}
                party={result}
                title={getSectionName(labels, type)}
                buttonName={labels.seeProfile}
                fields={fields}
                isLast={renderedCount === count}
              />
            );
          });
        }
        return null;
      })}
    </div>
  );
};
