import React from 'react';
import { Outlet } from 'react-router';

import { Provider as JotaProvider, createStore } from 'jotai';

interface EvaluationProviderProps {}

interface Context {
  resetStore: () => void;
}

const EvaluationContext = React.createContext<Context>({
  resetStore: () => {},
});

export const EvaluationRoot: React.FC<EvaluationProviderProps> = (props) => {
  const store = createStore();
  const [reset, setReset] = React.useState(0);
  const resetStore = React.useCallback(() => {
    setReset(Math.random());
  }, []);
  return (
    <EvaluationContext.Provider value={{ resetStore }}>
      <JotaProvider store={store}>
        <Outlet key={reset} />
      </JotaProvider>
    </EvaluationContext.Provider>
  );
};

export const useEvaluationContext = () => React.useContext(EvaluationContext);
