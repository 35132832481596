import React from 'react';

export interface ReactTableHeaderRowProps {
  children?: string | JSX.Element | JSX.Element[];
  'data-testid'?: string;
}

export const ReactTableHeaderRow = ({
  children,
  'data-testid': testid,
}: ReactTableHeaderRowProps) => {
  return <tr data-testid={testid}>{children}</tr>;
};
