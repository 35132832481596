import Highcharts, { SeriesColumnOptions } from 'highcharts';

import { palette } from 'modules/root/theme/visualization';
import { printMoney } from 'utils/print';

import { ChartSeriesData } from './EvaluationGraph';

const evaluationGraphOptions = (
  data: ChartSeriesData[],
  categoriesArr: string[]
): Highcharts.Options => ({
  accessibility: { enabled: false },
  title: {
    text: '',
  },
  xAxis: {
    categories: categoriesArr,
    labels: {
      style: {
        fontSize: '1rem',
      },
    },
  },
  yAxis: {
    gridLineWidth: 0,
    minorGridLineWidth: 0,
    min: 0,
    title: {
      text: '',
    },
    labels: {
      formatter(): string {
        return printMoney(Number(this.value));
      },
      style: {
        fontSize: '1rem',
      },
    },
  },
  tooltip: {
    formatter(): string | false {
      if (this.series.type === 'spline') {
        return false;
      }
      return `<span style="color: ${palette.gray700}">${this.series.name}: ${printMoney(
        // @ts-ignore realY property is added in EvaluationGraphtsx
        this.point.realY
      )}</span>`;
    },
    backgroundColor: palette.white,
    borderColor: palette.gray200,
    borderRadius: 6,
  },
  credits: {
    enabled: false,
  },
  legend: {
    y: 40,
    itemMarginBottom: 22,
    itemDistance: 40,
    useHTML: true,
    align: 'center',
    verticalAlign: 'bottom',
    itemStyle: {
      cursor: 'default',
      fontWeight: 'normal',
      fontFamily: "'OpenSans'",
      fontSize: '1rem',
      color: palette.gray700,
    },
    itemHoverStyle: {
      color: palette.gray700,
    },
    squareSymbol: true,
    symbolHeight: 10,
    symbolRadius: 5,
    symbolPadding: 30,
  },
  plotOptions: {
    column: {
      stacking: 'normal',
      showInLegend: true,
      pointWidth: 60,
      events: {
        legendItemClick(): boolean {
          return false;
        },
      },
    },
    spline: {
      connectNulls: false,
      showInLegend: false,
    },
  },
  series: data as SeriesColumnOptions[],
  chart: {
    width: 550,
    height: 450,
    events: {
      load(event: Event) {
        // Customize the width of the legend symbol
        if (event.target) {
          const points = (this as unknown as Highcharts.Chart).legend.allItems;
          points.forEach((point) => {
            if (point.legendSymbol) {
              point.legendSymbol.element.setAttribute('width', '30');
            }
          });
        }
      },
      redraw() {
        const points = (this as unknown as Highcharts.Chart).legend.allItems;
        points.forEach((point) => {
          if (point.legendSymbol) {
            point.legendSymbol.element.setAttribute('width', '30');
          }
        });
      },
    },
  },
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
});

export default evaluationGraphOptions;
