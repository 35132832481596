import React from 'react';

import { useTranslation } from 'react-i18next';

import { usePageTitle } from 'modules/root/Settings';

interface AccessDeniedProps {}

export const AccessDenied: React.FC<AccessDeniedProps> = (props) => {
  const { t } = useTranslation();
  usePageTitle([t('common.error.accessDenied')]);
  return (
    <div className="flex flex-col items-center justify-center h-full gap-4 select-none">
      <h1>{t('common.error.accessDenied')}</h1>
      <div>{t('auth.disallow')}</div>
    </div>
  );
};
