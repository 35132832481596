import React from 'react';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import MaskCopyProhibitedText from 'components/MaskCopyProhibitedText/MaskCopyProhibitedText';
import { printPrimitive } from 'utils/print';

interface CustomSectionProps {
  field: string;
  values: string[];
  className?: string;
  showMask?: boolean;
}

export const CustomSection: React.FC<CustomSectionProps> = ({
  field,
  values,
  className,
  showMask,
}) => {
  const { t } = useTranslation();
  return (
    <div data-testid="party-section" className={clsx(className, 'grow')}>
      <div className="text-gray-700">{t(field)}</div>
      {values.map((value) => (
        <div key={value} className="text-grayColor font-semibold">
          {showMask && value ? (
            <MaskCopyProhibitedText text={value} />
          ) : (
            printPrimitive(value)
          )}
        </div>
      ))}
    </div>
  );
};

interface RelationshipSectionProps {
  relationship: string;
  displayName: string;
  className?: string;
}

export const RelationshipSection: React.FC<RelationshipSectionProps> = ({
  relationship,
  displayName,
  className,
}) => {
  return (
    <CustomSection
      field={relationship}
      values={[displayName]}
      className={clsx(className, 'flex', 'flex-col', 'ml-4')}
    />
  );
};
