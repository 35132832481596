import React from 'react';

import { useAtomValue } from 'jotai';

import { EvaluationAction, evaluationActionAtom } from '../models/evaluation';
import { EvaluationInput } from './Summary/EvaluationInput';
import { Review } from './Summary/Review';
import { Summary } from './Summary/Summary';

interface EvaluationProps {}

export const Evaluation: React.FC<EvaluationProps> = (props) => {
  const action = useAtomValue(evaluationActionAtom);
  return (
    <div className="flex">
      <div className="basis-1/2 max-w-[50%]">
        {action === EvaluationAction.CreateNew ? <Review /> : <EvaluationInput />}
      </div>
      <div className="basis-1/2 max-w-[50%]">
        <Summary />
      </div>
    </div>
  );
};
