import React from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import 'assets/js/highcharts/rounded-corners';
import 'assets/js/highcharts/threshold';

interface HighchartsGraphProps {
  options: Highcharts.Options;
  callback?: Highcharts.ChartCallbackFunction;
}

export const HighchartsGraph: React.FC<HighchartsGraphProps> = ({
  options,
  callback,
}) => {
  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      immutable={true}
      callback={callback}
    />
  );
};
