import React from 'react';

import { LoanProposalRates } from 'generated/graphql';
import { useFormContext, useWatch } from 'react-hook-form';
import { getNumberValue } from 'utils';

import { useCustomisation } from 'modules/root/Settings';

import { getInterestRate } from '../util';
import { LoanProposalFormValues } from './types';

interface InterestRateProps {
  ratesConfig: LoanProposalRates[];
}

export const InterestRate: React.FC<InterestRateProps> = ({ ratesConfig }) => {
  const { labels } = useCustomisation();
  const { control } = useFormContext<LoanProposalFormValues>();
  const [interestRate, setInterestRate] = React.useState<string | null>(null);
  const loanAmount = useWatch({ control, name: 'loanAmount' });
  const proposedPricing = useWatch({ control, name: 'proposedPricing' });
  const interestRateType = useWatch({ control, name: 'interestRateType' });
  const loanType = useWatch({ control, name: 'loanType' });
  const loanTerm = useWatch({ control, name: 'loanTerm' });

  React.useEffect(() => {
    if (ratesConfig && loanType && loanAmount && interestRateType) {
      setInterestRate(
        getInterestRate(
          ratesConfig,
          getNumberValue(loanAmount),
          loanTerm,
          loanType,
          proposedPricing,
          interestRateType
        )
      );
    } else {
      setInterestRate(null);
    }
  }, [ratesConfig, loanTerm, loanType, proposedPricing, loanAmount, interestRateType]);

  return (
    <>
      {interestRate && (
        <div className="notification mb-4 mt-1 p-4 font-semibold text-gray-600">
          {`${labels.interestRate}: `}
          <span className="font-normal">{interestRate}</span>
        </div>
      )}
    </>
  );
};
