import React from 'react';

import { PageLayout } from 'components';
import { usePermissionQuery } from 'generated/graphql';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';

import { AccessDenied } from 'modules/Error/AccessDenied';
import { CommonError } from 'modules/common/errors/CommonError';

import { PermissionContext } from './permission.context';

interface PermissionProviderProps {
  children: React.ReactNode;
}

export const PermissionProvider: React.FC<PermissionProviderProps> = ({ children }) => {
  const { data, isFetching } = useQueryFetch(usePermissionQuery);
  const hasPermissions = React.useMemo(() => {
    if (
      !isFetching &&
      data &&
      Object.values(data.userPermissions).some((value) => value)
    ) {
      return true;
    }
    return false;
  }, [data, isFetching]);

  if (isFetching) {
    return null;
  }

  if (!data) {
    throw new CommonError('PermissionProviderError', 'data from query is not provided');
  }

  if (!hasPermissions) {
    return (
      <PageLayout>
        <AccessDenied />
      </PageLayout>
    );
  }
  return (
    <PermissionContext.Provider value={{ permissions: data.userPermissions }}>
      {children}
    </PermissionContext.Provider>
  );
};
