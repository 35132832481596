import React from 'react';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

interface ShowingLatestFiveProps {
  'data-testid'?: string;
}

export const ShowingLatestFive: React.FC<ShowingLatestFiveProps> = ({
  'data-testid': dataTestId,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={clsx('text-gray-600', 'text-sm', 'leading-4', 'fontFamily-nav', 'mb-4')}
      data-testid={dataTestId}
    >
      {t('dashboard.loanApplicationActivities.showingLatestFive')}
    </div>
  );
};
