import React from 'react';
import { Outlet, useOutletContext, useParams } from 'react-router';

import { Content, TabItem } from 'components';
import { ActivityAuditType, LoanStatus, useCustomerHeaderQuery } from 'generated/graphql';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';

import { QuerySuspense } from 'modules/common/QuerySuspense/QuerySuspense';
import { useCustomisation, usePaths } from 'modules/root/Settings';

import { CustomerHeader } from './CustomerHeader/CustomerHeader';

type CustomerProfileContext = {
  customerName: string;
  customerExternalId: string;
  isDataIssue: boolean;
};

export const CustomerProfile: React.FC = () => {
  const paths = usePaths();
  const { customerId } = useParams();
  const { labels } = useCustomisation();

  const tabElements: TabItem[] = React.useMemo(
    () => [
      {
        key: 'loans',
        label: labels.loans,
        path: [paths.customer.loans.main, paths.customer.loans.details],
        show: true,
      },
      {
        key: 'accounts',
        label: labels.accounts,
        path: paths.customer.accounts,
        show: true,
      },
      {
        key: 'details',
        label: labels.customerDetails,
        path: paths.customer.details,
        show: true,
      },
      {
        key: 'internal-contacts',
        label: labels.internalContacts,
        path: paths.customer.contacts,
        show: true,
      },
      {
        key: 'household',
        label: labels.household,
        path: paths.customer.household,
        show: false,
      },
    ],
    [paths.customer, labels]
  );

  const { data, error, isLoading } = useQueryFetch(useCustomerHeaderQuery, {
    queryHookParams: {
      customerID: customerId || '',
    },
    extra: {
      auditReport: { activityType: ActivityAuditType.Read },
    },
  });

  return (
    <QuerySuspense error={error} isLoading={isLoading} noData={!data?.customer}>
      {data?.customer && (
        <Content header={<CustomerHeader customer={data.customer} tabs={tabElements} />}>
          <Outlet
            context={{
              isDataIssue: data.customer.serviceStatus?.status === LoanStatus.DataIssue,
              customerName: data.customer.displayName,
              customerExternalId: data.customer.customerId,
            }}
          />
        </Content>
      )}
    </QuerySuspense>
  );
};

export const useCustomerProfileContext = () => {
  return useOutletContext<CustomerProfileContext>();
};
