import React from 'react';

import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import clsx from 'clsx';

interface NavIconProps {
  source: string;
  className?: string;
  size?: SizeProp;
  alt?: string;
  'data-testid'?: string;
}
export const NavIcon = React.forwardRef<HTMLImageElement, NavIconProps>(
  ({ source, className: classname, size, alt, 'data-testid': dataTestId }, ref) => {
    const sizes: { [key in SizeProp]: string } = {
      xs: 'w-3',
      sm: 'w-[14px]',
      lg: 'w-5',
      '1x': 'w-[1rem]',
      '2x': 'w-[2rem]',
      '3x': 'w-[3rem]',
      '4x': 'w-[4rem]',
      '5x': 'w-[5rem]',
      '6x': 'w-[6rem]',
      '7x': 'w-[7rem]',
      '8x': 'w-[8rem]',
      '9x': 'w-[9rem]',
      '10x': 'w-[10rem]',
    };
    return (
      <img
        data-testid={dataTestId}
        ref={ref}
        alt={alt}
        src={source}
        className={clsx(classname, 'select-none', size ? sizes[size] : '')}
      />
    );
  }
);
