import { palette } from 'modules/root/theme/visualization';
import { printPercent } from 'utils/print';

import { ChartSeriesData } from './EvaluationGraph';

type SplineChartData = {
  x?: number;
  y?: number;
  dataLabels?: {
    enabled: boolean;
    format: string;
    align: string;
    allowOverlap: boolean;
  };
};

const LEFT_POINT_POSITION_OFFSET = 0.147;
const RIGHT_POINT_POSITION_OFFSET = 0.853;

const getSplineChart = (
  marketValue: number,
  collateralValue: number,
  rate: number
): ChartSeriesData => {
  const splineChartData: SplineChartData[] = [];

  splineChartData.push({
    y: marketValue,
    x: LEFT_POINT_POSITION_OFFSET,
  });
  splineChartData.push({
    y: collateralValue,
    x: RIGHT_POINT_POSITION_OFFSET,
    dataLabels: {
      enabled: true,
      format: printPercent(rate),
      align: 'right',
      allowOverlap: true,
    },
  });

  return {
    name: 'spline',
    type: 'spline',
    color: palette.gray200,
    dashStyle: 'ShortDash',
    zIndex: -1,
    marker: {
      lineColor: palette.gray200,
      fillColor: palette.gray200,
    },
    data: splineChartData,
  };
};

export default getSplineChart;
