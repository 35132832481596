import React from 'react';
import { generatePath, useParams } from 'react-router-dom';

import { BreadCrumbItemType, Content } from 'components';
import { useLoanProposalQuery } from 'generated/graphql';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';
import { useTranslation } from 'react-i18next';

import { EvaluationHeader } from 'modules/common/EvaluationHeader/EvaluationHeader';
import { QuerySuspense } from 'modules/common/QuerySuspense/QuerySuspense';
import { useCustomisation, usePaths } from 'modules/root/Settings';

import { LoanProposalForm } from './LoanProposalForm/LoanProposalForm';

export const LoanProposal: React.FC = () => {
  const { customerId, evaluationId = 'evaluationId' } = useParams();

  const { t } = useTranslation();
  const paths = usePaths();

  const { data, error, isLoading } = useQueryFetch(useLoanProposalQuery, {
    queryHookParams: { evaluationId },
  });

  const { labels, sideMenu } = useCustomisation();

  const breadcrumbList: BreadCrumbItemType[] = [];
  if (sideMenu.home.show) {
    breadcrumbList.push({ label: labels.home, link: generatePath(paths.home) });
  }
  if (sideMenu.originations.show) {
    breadcrumbList.push({
      label: labels.originations,
      link: generatePath(paths.origination.list),
    });
  }
  breadcrumbList.push({
    label: labels.loanProposal,
    link: generatePath(paths.evaluations.startapplication, {
      customerId,
      evaluationId,
    }),
  });

  return (
    <Content header={<EvaluationHeader list={breadcrumbList} />}>
      <div className="my-6 lg:my-10 mx-8 lg:mx-20">
        <h2 data-testid="loan-proposal">{labels.loanProposal}</h2>
        <QuerySuspense
          error={error}
          isLoading={isLoading}
          errorRenderer={() => (
            <div className="alert" data-testid="borrowing-power-fetch-error-message">
              <div>{t('loanProposal.errorFetchingBorrowingPower')}</div>
            </div>
          )}
        >
          {data && <LoanProposalForm loanProposal={data} />}
        </QuerySuspense>
      </div>
    </Content>
  );
};
