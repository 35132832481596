import React from 'react';
import ReactDOM from 'react-dom/client';

import 'wdyr';

import App from 'modules/root/App/App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
