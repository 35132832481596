import React from 'react';

import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import {
  ActivityAuditType,
  CustomerActivityType,
  DataBarrierFunctionality,
  useCustomerActivitySearchQuery,
} from 'generated/graphql';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';
import { useTranslation } from 'react-i18next';
import { ResourceAccessed, useSendAuditReport } from 'services/auditReport';

import { FontIcon } from 'components/FontIcon/FontIcon';
import { ShowingLatestFive } from 'modules/Home/components/ShowingLatestFive';
import { QuerySuspense } from 'modules/common/QuerySuspense/QuerySuspense';

import { CustomerIcon } from '../../../../common/CustomerIcon/CustomerIcon';
import { DashboardList } from '../../../components/DashboardList';
import { CustomerActvityModal } from './modals/CustomerActivityModal';
import { ActivityItems } from './modals/CustomerAllActivitiesModal';

export type ModalState = {
  customerId: string;
  customerName: string;
  customerExternalId: string;
};

export const CustomerActivity: React.FC = () => {
  const { data, isLoading, error } = useQueryFetch(useCustomerActivitySearchQuery, {
    queryHookParams: {
      fromIndex: 0,
      numResults: 5,
      dataBarrierFunctionality: DataBarrierFunctionality.Home,
    },
  });

  const activities = data?.customerActivitySearch?.items;
  const [modalState, setModalState] = React.useState<ModalState | null>(null);
  const sendAudit = useSendAuditReport();
  const showCustomerActivityModal = (state: ModalState) => () => {
    sendAudit({
      activityType: ActivityAuditType.Read,
      customerExternalId: state.customerExternalId,
      resourceAccessed: ResourceAccessed.ViewCustomerActivity,
    });
    setModalState(state);
  };
  return (
    <QuerySuspense
      error={error}
      isLoading={isLoading}
      noData={!activities || activities.length === 0}
    >
      <ShowingLatestFive data-testid="show-latest-five" />
      <DashboardList>
        {activities &&
          activities.map(
            (activity) =>
              activity && (
                <DashboardList.Item key={activity?.customerId}>
                  <Activity
                    activityStat={activity}
                    showCustomerActivityModal={showCustomerActivityModal}
                  />
                </DashboardList.Item>
              )
          )}
      </DashboardList>
      {modalState && (
        <CustomerActvityModal onClose={() => setModalState(null)} {...modalState} />
      )}
    </QuerySuspense>
  );
};

interface CustomerActivityProps {
  activityStat: ActivityItems;
  showCustomerActivityModal: (state: ModalState) => () => void;
}

interface ActivityRecordProps {
  title: string;
  count: number;
}

export const ActivityRecord: React.FC<ActivityRecordProps> = ({ title, count }) => {
  return (
    <div
      data-testid="activity-type"
      className={clsx(
        'flex items-center text-2xs font-semibold leading-none px-2 py-0 mr-2 rounded-sm',
        count > 0
          ? 'bg-primary-50 text-primary-600 border border-primary-100'
          : 'bg-gray-50 border border-gray-100 text-gray-600'
      )}
    >
      <div className="py-1.5 uppercase">{title}</div>
      {count > 0 && (
        <div
          data-testid="activity-badge"
          className="rounded-full py-[4px] px-[6px] ml-2 bg-primary-600 text-white"
        >
          {count}
        </div>
      )}
    </div>
  );
};

export const Activity: React.FC<CustomerActivityProps> = ({
  activityStat: activity,
  showCustomerActivityModal,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className="flex cursor-pointer"
      onClick={showCustomerActivityModal({
        customerId: activity?.customerId || '',
        customerName: activity?.customerName || '',
        customerExternalId: activity?.customerExternalId || '',
      })}
    >
      <div className="mr-4">
        <CustomerIcon customerName={activity?.customerName || ''} />
      </div>
      <div className="grow">
        <div className="font-semibold leading-4 pb-2 mt-[-2px]">
          {activity?.customerName}
        </div>
        <div className="flex flex-wrap">
          <ActivityRecord
            count={activity?.activitiesStats.evaluation || 0}
            title={t('dashboard.activities.types.' + CustomerActivityType.Evaluation)}
          />
          <ActivityRecord
            count={activity?.activitiesStats.loanApplication || 0}
            title={t(
              'dashboard.activities.types.' + CustomerActivityType.LoanApplication
            )}
          />
          <ActivityRecord
            count={activity?.activitiesStats.loan || 0}
            title={t('dashboard.activities.types.' + CustomerActivityType.Loan)}
          />
        </div>
      </div>
      <div className="flex items-center">
        <FontIcon size="xs" className="text-gray-300 mr-2" icon={faAngleRight} />
      </div>
    </div>
  );
};
