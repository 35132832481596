import React from 'react';
import { useLocation } from 'react-router';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Footer } from './Footer/Footer';
import { SideMenu } from './SideMenu/SideMenu';

interface PageLayoutProps {
  children: React.ReactElement;
}

const PageLayout: React.FC<PageLayoutProps> = React.memo(({ children }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [collapsed, setCollapsed] = React.useState<boolean>(true);

  React.useEffect(() => {
    setCollapsed(true);
  }, [location]);

  const handleClickContent = () => {
    if (!collapsed) {
      setCollapsed(true);
    }
  };
  return (
    <div
      className={clsx([
        'grid',
        'max-w-[1436px] mx-auto',
        {
          'grid-cols-pageLayoutColumnCollapsed': collapsed,
          'grid-cols-pageLayoutColumn': !collapsed,
        },
      ])}
    >
      <a
        className="transition left-0 bg-primary-600 text-gray-100 absolute p-3 m-3 z-50 -translate-y-96 focus:translate-y-0"
        href="#main-content"
      >
        {t('common.skipLink')}
      </a>
      <div className="bg-main-secondary z-20">
        <SideMenu setCollapsed={setCollapsed} collapsed={collapsed} title="Navigator" />
      </div>

      <div
        onClick={handleClickContent}
        className={clsx('grid grid-rows-pageLayoutContent ml-1 max-w-[1356px] h-screen', {
          'ml-[84px]': !collapsed,
        })}
      >
        <div className={clsx('bg-white')}>{children}</div>
        <Footer text={`© All rights reserved ${new Date().getFullYear()}`} />
      </div>
    </div>
  );
});

PageLayout.displayName = 'PurePageLayout';

export { PageLayout };
