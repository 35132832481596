import React from 'react';

import { faAngleLeft, faXmark } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { FontIcon } from 'components';
import { useTranslation } from 'react-i18next';

export interface ModalBackToTitleProps {
  onClose: () => void;
  backTo?: { title: string; onClick?: () => void };
}

export const ModalBackToTitle: React.FC<ModalBackToTitleProps> = ({
  onClose,
  backTo,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <button
        data-testid="back-to-title"
        onClick={backTo?.onClick}
        className={clsx(
          'uppercase',
          'text-sm',
          'text-gray-500',
          'cursor-pointer',
          'font-normal',
          backTo ? '' : 'hidden'
        )}
      >
        <FontIcon size="sm" className="mr-2" icon={faAngleLeft} />
        {`${t('common.backTo')} ${backTo?.title || ''}`}
      </button>

      <FontIcon
        className={clsx('cursor-pointer', backTo ? '' : 'right-10 absolute')}
        onClick={onClose}
        icon={faXmark}
      />
    </>
  );
};
