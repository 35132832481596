import React from 'react';

interface CustomerIconProps {
  customerName: string;
}

export const CustomerIcon: React.FC<CustomerIconProps> = ({ customerName }) => {
  return (
    <div
      data-testid="customer-icon"
      className="bg-gray-50 border border-gray-100 text-gray-500 text-sm font-semibold 
    rounded p-1 w-10 h-10 flex justify-center items-center shrink-0"
    >
      {resolveAbbrev(customerName)}
    </div>
  );
};

const resolveAbbrev = (name: string) => {
  if (!name || name.length === 0) {
    return '';
  } else if (name?.length === 1) {
    return name.toUpperCase();
  }
  const nameParts = name.split(' ');
  if (nameParts.length > 1) {
    return (nameParts[0].slice(0, 1) + nameParts[1].slice(0, 1)).toUpperCase();
  } else {
    return nameParts[0].slice(0, 2).toUpperCase();
  }
};
