import React from 'react';

interface ThemeReviewProps {}

export const ThemeReview: React.FC<ThemeReviewProps> = (props) => {
  const className =
    'w-44 h-16 flex items-center justify-center text-text-primary text-xl rounded-lg';

  const colClass = 'flex gap-2 flex-col mb-4';
  const hClass = 'box-border bg-lime-100';
  return (
    <div className="bg-white w-full">
      <div className="w-[1200px] m-auto flex flex-row flex-wrap gap-4 pt-5 justify-around">
        <div className="w-full flex flex-col gap-1">
          <h1 className={hClass}>{'The quick brown fox'}</h1>
          <h2 className={hClass}>{'The quick brown fox'}</h2>
          <h3 className={hClass}>{'The quick brown fox'}</h3>
          <h4 className={hClass}>{'The quick brown fox'}</h4>
          <h5 className={hClass}>{'The quick brown fox'}</h5>
          <h6 className={hClass}>{'The quick brown fox'}</h6>
        </div>

        <div className={colClass}>
          <div className={`${className} bg-primary-700`}>{'bg-primary-700'}</div>
          <div className={`${className} bg-primary-600`}>{'bg-primary-600'}</div>
          <div className={`${className} bg-primary-500`}>{'bg-primary-500'}</div>
          <div className={`${className} bg-primary-400`}>{'bg-primary-400'}</div>
          <div className={`${className} bg-primary-300`}>{'bg-primary-300'}</div>
          <div className={`${className} bg-primary-200`}>{'bg-primary-200'}</div>
          <div className={`${className} bg-primary-100`}>{'bg-primary-100'}</div>
          <div className={`${className} bg-primary-50`}>{'bg-primary-50'}</div>
        </div>
        <div className={colClass}>
          <div className={`${className} bg-secondary-700`}>{'bg-secondary-700'}</div>
          <div className={`${className} bg-secondary-600`}>{'bg-secondary-600'}</div>
          <div className={`${className} bg-secondary-500`}>{'bg-secondary-500'}</div>
          <div className={`${className} bg-secondary-400`}>{'bg-secondary-400'}</div>
          <div className={`${className} bg-secondary-300`}>{'bg-secondary-300'}</div>
          <div className={`${className} bg-secondary-200`}>{'bg-secondary-200'}</div>
          <div className={`${className} bg-secondary-100`}>{'bg-secondary-100'}</div>
          <div className={`${className} bg-secondary-50`}>{'bg-secondary-50'}</div>
        </div>
        <div className={colClass}>
          <div className={`${className} bg-gray-700`}>{'bg-gray-700'}</div>
          <div className={`${className} bg-gray-600`}>{'bg-gray-600'}</div>
          <div className={`${className} bg-gray-500`}>{'bg-gray-500'}</div>
          <div className={`${className} bg-gray-400`}>{'bg-gray-400'}</div>
          <div className={`${className} bg-gray-300`}>{'bg-gray-300'}</div>
          <div className={`${className} bg-gray-200`}>{'bg-gray-200'}</div>
          <div className={`${className} bg-gray-100`}>{'bg-gray-100'}</div>
          <div className={`${className} bg-gray-50`}>{'bg-gray-50'}</div>
        </div>
        <div className={colClass}>
          <div className={`${className} bg-green-700`}>{'bg-green-700'}</div>
          <div className={`${className} bg-green-600`}>{'bg-green-600'}</div>
          <div className={`${className} bg-green-500`}>{'bg-green-500'}</div>
          <div className={`${className} bg-green-400`}>{'bg-green-400'}</div>
          <div className={`${className} bg-green-300`}>{'bg-green-300'}</div>
          <div className={`${className} bg-green-200`}>{'bg-green-200'}</div>
          <div className={`${className} bg-green-100`}>{'bg-green-100'}</div>
          <div className={`${className} bg-green-50`}>{'bg-green-50'}</div>
        </div>
        <div className={colClass}>
          <div className={`${className} bg-red-700`}>{'bg-red-700'}</div>
          <div className={`${className} bg-red-600`}>{'bg-red-600'}</div>
          <div className={`${className} bg-red-500`}>{'bg-red-500'}</div>
          <div className={`${className} bg-red-400`}>{'bg-red-400'}</div>
          <div className={`${className} bg-red-300`}>{'bg-red-300'}</div>
          <div className={`${className} bg-red-200`}>{'bg-red-200'}</div>
          <div className={`${className} bg-red-100`}>{'bg-red-100'}</div>
          <div className={`${className} bg-red-50`}>{'bg-red-50'}</div>
        </div>
        <div className={colClass}>
          <div className={`${className} bg-pink-700`}>{'bg-pink-700'}</div>
          <div className={`${className} bg-pink-600`}>{'bg-pink-600'}</div>
          <div className={`${className} bg-pink-500`}>{'bg-pink-500'}</div>
          <div className={`${className} bg-pink-400`}>{'bg-pink-400'}</div>
          <div className={`${className} bg-pink-300`}>{'bg-pink-300'}</div>
          <div className={`${className} bg-pink-200`}>{'bg-pink-200'}</div>
          <div className={`${className} bg-pink-100`}>{'bg-pink-100'}</div>
          <div className={`${className} bg-pink-50`}>{'bg-pink-50'}</div>
        </div>
        <div className={colClass}>
          <div className={`${className} bg-purple-700`}>{'bg-purple-700'}</div>
          <div className={`${className} bg-purple-600`}>{'bg-purple-600'}</div>
          <div className={`${className} bg-purple-500`}>{'bg-purple-500'}</div>
          <div className={`${className} bg-purple-400`}>{'bg-purple-400'}</div>
          <div className={`${className} bg-purple-300`}>{'bg-purple-300'}</div>
          <div className={`${className} bg-purple-200`}>{'bg-purple-200'}</div>
          <div className={`${className} bg-purple-100`}>{'bg-purple-100'}</div>
          <div className={`${className} bg-purple-50`}>{'bg-purple-50'}</div>
        </div>
        <div className={colClass}>
          <div className={`${className} bg-indigo-700`}>{'bg-indigo-700'}</div>
          <div className={`${className} bg-indigo-600`}>{'bg-indigo-600'}</div>
          <div className={`${className} bg-indigo-500`}>{'bg-indigo-500'}</div>
          <div className={`${className} bg-indigo-400`}>{'bg-indigo-400'}</div>
          <div className={`${className} bg-indigo-300`}>{'bg-indigo-300'}</div>
          <div className={`${className} bg-indigo-200`}>{'bg-indigo-200'}</div>
          <div className={`${className} bg-indigo-100`}>{'bg-indigo-100'}</div>
          <div className={`${className} bg-indigo-50`}>{'bg-indigo-50'}</div>
        </div>
        <div className={colClass}>
          <div className={`${className} bg-cyan-700`}>{'bg-cyan-700'}</div>
          <div className={`${className} bg-cyan-600`}>{'bg-cyan-600'}</div>
          <div className={`${className} bg-cyan-500`}>{'bg-cyan-500'}</div>
          <div className={`${className} bg-cyan-400`}>{'bg-cyan-400'}</div>
          <div className={`${className} bg-cyan-300`}>{'bg-cyan-300'}</div>
          <div className={`${className} bg-cyan-200`}>{'bg-cyan-200'}</div>
          <div className={`${className} bg-cyan-100`}>{'bg-cyan-100'}</div>
          <div className={`${className} bg-cyan-50`}>{'bg-cyan-50'}</div>
        </div>
        <div className={colClass}>
          <div className={`${className} bg-lime-700`}>{'bg-lime-700'}</div>
          <div className={`${className} bg-lime-600`}>{'bg-lime-600'}</div>
          <div className={`${className} bg-lime-500`}>{'bg-lime-500'}</div>
          <div className={`${className} bg-lime-400`}>{'bg-lime-400'}</div>
          <div className={`${className} bg-lime-300`}>{'bg-lime-300'}</div>
          <div className={`${className} bg-lime-200`}>{'bg-lime-200'}</div>
          <div className={`${className} bg-lime-100`}>{'bg-lime-100'}</div>
          <div className={`${className} bg-lime-50`}>{'bg-lime-50'}</div>
        </div>
        <div className={colClass}>
          <div className={`${className} bg-yellow-700`}>{'bg-yellow-700'}</div>
          <div className={`${className} bg-yellow-600`}>{'bg-yellow-600'}</div>
          <div className={`${className} bg-yellow-500`}>{'bg-yellow-500'}</div>
          <div className={`${className} bg-yellow-400`}>{'bg-yellow-400'}</div>
          <div className={`${className} bg-yellow-300`}>{'bg-yellow-300'}</div>
          <div className={`${className} bg-yellow-200`}>{'bg-yellow-200'}</div>
          <div className={`${className} bg-yellow-100`}>{'bg-yellow-100'}</div>
          <div className={`${className} bg-yellow-50`}>{'bg-yellow-50'}</div>
        </div>
        <div className={colClass}>
          <div className={`${className} bg-orange-700`}>{'bg-orange-700'}</div>
          <div className={`${className} bg-orange-600`}>{'bg-orange-600'}</div>
          <div className={`${className} bg-orange-500`}>{'bg-orange-500'}</div>
          <div className={`${className} bg-orange-400`}>{'bg-orange-400'}</div>
          <div className={`${className} bg-orange-300`}>{'bg-orange-300'}</div>
          <div className={`${className} bg-orange-200`}>{'bg-orange-200'}</div>
          <div className={`${className} bg-orange-100`}>{'bg-orange-100'}</div>
          <div className={`${className} bg-orange-50`}>{'bg-orange-50'}</div>
        </div>
      </div>
    </div>
  );
};
