import React from 'react';

import { Card } from 'components';
import { PartiesQuery, PartiesSection } from 'generated/graphql';

import { useCustomisation } from 'modules/root/Settings';
import { ArrayElement } from 'utils/genericType';

import { NodeRelationItem, PartiesState, RootBorrowerItem } from '../../Parties';

export type PartiesListCustomer = NonNullable<ArrayElement<PartiesQuery['loanParties']>>;

interface PartiesModalBodyProps {
  parties: PartiesState;
  sections: PartiesSection[];
}

export const PartiesModalBody: React.FC<PartiesModalBodyProps> = ({
  parties,
  sections,
}) => {
  const {
    labels: { partiesInvolved },
  } = useCustomisation();
  const count =
    parties?.coBorrowers.length +
    parties?.accountOwnerDetails.length +
    parties?.guarantors.length;

  return (
    <div className="flex flex-col px-10 py-8">
      <div className="text-gray-500 mb-6 tracking-[2px] text-sm uppercase font-semibold">
        {partiesInvolved}
      </div>
      {parties.borrower && (
        <Card className="mb-2 w-[700px]">
          <RootBorrowerItem
            sections={sections}
            borrower={parties.borrower}
          ></RootBorrowerItem>
        </Card>
      )}
      {count > 0 && (
        <div className="flex pl-5">
          <div className="flex flex-col w-[1px]"></div>
          <NodeRelationItem sections={sections} parties={parties} count={count} />
        </div>
      )}
    </div>
  );
};
