import React from 'react';

import { Badge } from 'components';
import { ApplicationStatus } from 'generated/graphql';

import { STATUS_BACKGROUND } from '../enums/statusBackground';

const STATUS_MAP: Record<ApplicationStatus, STATUS_BACKGROUND> = {
  [ApplicationStatus.Complete]: STATUS_BACKGROUND.GREEN,
  [ApplicationStatus.Expired]: STATUS_BACKGROUND.RED,
  [ApplicationStatus.Cancelled]: STATUS_BACKGROUND.RED_LIGHT,
  [ApplicationStatus.Created]: STATUS_BACKGROUND.PRIMARY,
  [ApplicationStatus.InWorkflow]: STATUS_BACKGROUND.YELLOW,
  [ApplicationStatus.InitWorkflow]: STATUS_BACKGROUND.YELLOW,
  [ApplicationStatus.Draft]: STATUS_BACKGROUND.PRIMARY,
  [ApplicationStatus.New]: STATUS_BACKGROUND.PRIMARY,
  [ApplicationStatus.Submitted]: STATUS_BACKGROUND.PRIMARY,
  [ApplicationStatus.UploadPending]: STATUS_BACKGROUND.PRIMARY,
};

interface ApplicationBadgeProps {
  status: ApplicationStatus;
  size?: 'sm' | 'md';
}

export const ApplicationBadge: React.FC<ApplicationBadgeProps> = ({
  status,
  size = 'md',
}) => {
  const color = STATUS_MAP[status];
  return <Badge size={size} label={status.replace('_', ' ')} color={color} />;
};
