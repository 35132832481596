import React from 'react';

import { useTranslation } from 'react-i18next';

import { AUTH0 } from 'modules/root/Settings/envVars';
import { Auth0Provider } from 'modules/root/auth/Auth0Wrapper/Auth0Provider/Auth0Provider';

interface Auth0WrapperProps {
  children: React.ReactNode;
}

const Auth0Wrapper: React.FC<Auth0WrapperProps> = React.memo(({ children }) => {
  const domain = AUTH0.DOMAIN;
  const clientId = AUTH0.CLIENT_ID;
  const audience = AUTH0.AUDIENCE;
  const enableIdPInitiatedLogin = AUTH0.ENABLE_IDP_INITIATED_LOGIN;
  const responseType = AUTH0.RESPONSE_TYPE;
  const { t } = useTranslation();

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      options={{
        container: 'container',
        theme: {
          logo: '',
          primaryColor: 'rgb(var(--color-primary-600))',
          hideMainScreenTitle: true,
        },
        languageDictionary: {
          emailInputPlaceholder: t('Routing.Auth0.emailInputPlaceholder'),
          title: t('common.navigator'),
        },
        closable: false,
        allowForgotPassword: false,
        allowSignUp: false,
        allowShowPassword: true,
        auth: {
          redirect: true,
          redirectUrl: window.location.origin,
          audience,
          responseType,
          autoParseHash: true,
          params: {
            state: 'idp',
          },
        },
        _enableIdPInitiatedLogin: enableIdPInitiatedLogin,
      }}
    >
      {children}
    </Auth0Provider>
  );
});

Auth0Wrapper.displayName = 'PureAuth0Wrapper';

export { Auth0Wrapper };
