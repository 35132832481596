import { atom } from 'jotai';

import { EvaluationState, evaluationStateAtom } from './evaluation';

export enum ID_STEPS {
  Customer = 'CUSTOMER',
  Accounts = 'ACCOUNTS',
  CreditPolicy = 'CREDIT_POLICY',
}

type StepAtom = {
  id: ID_STEPS;
  label: string;
  active: boolean; // active means it is current
  enable: boolean; // enable means user can go to this step
  valid: boolean; // valid means user completed all actions needed for this step (selecting a customer, accounts etc.)
};

export const stepsAtom = atom<StepAtom[]>([]);

export const currentStepAtom = atom(
  (get) => get(stepsAtom).find((step) => step.active),
  (get, set, newStep: StepAtom) => {
    const steps = get(stepsAtom);
    if (!newStep.enable) {
      return;
    }

    const newSteps: StepAtom[] = steps.map((step) => ({
      ...step,
      active: step.id === newStep.id,
    }));
    set(stepsAtom, newSteps);
  }
);

export const nextStepAtom = atom<StepAtom | null, unknown[], void>(
  (get) => {
    const steps = get(stepsAtom);
    const currentStep = get(currentStepAtom);
    const currentStepIndex = steps.findIndex((step) => step.id === currentStep?.id);
    if (currentStepIndex !== -1) {
      const nextStep = steps[currentStepIndex + 1] || null;
      return nextStep;
    }
    return null;
  },
  (get, set) => {
    const nextStep = get(nextStepAtom);
    if (nextStep) {
      set(currentStepAtom, nextStep);
    } else {
      const steps = get(stepsAtom);
      set(evaluationStateAtom, EvaluationState.Processing);
      set(
        stepsAtom,
        steps.map((step) => ({ ...step, active: false }))
      );
    }
  }
);
