import React from 'react';

import { useTranslation } from 'react-i18next';

import { usePageTitle } from 'modules/root/Settings';

import { useCustomerProfileContext } from '../../CustomerProfile';

export const Household: React.FC = () => {
  const { t } = useTranslation();
  const { customerName } = useCustomerProfileContext();
  usePageTitle([customerName, t('customer.loans.households')]);
  return <div>{t('customer.loans.households')}</div>;
};
