import React from 'react';

import clsx from 'clsx';

interface CardProps {
  children: React.ReactChild;
  footer?: React.ReactNode;
  title?: string | React.ReactNode;
  extra?: React.ReactNode;
  border?: boolean;
  className?: string;
  testid?: string;
}

export const Card = React.forwardRef<HTMLDivElement, CardProps>(
  ({ children, footer, title, extra, border = true, className, testid }, ref) => {
    return (
      <div
        className={clsx(className, 'border', 'rounded-lg', 'w-full')}
        data-testid={testid}
        ref={ref}
      >
        {title && (
          <div className={clsx('flex', 'py-4', 'px-5', border && 'border-b')}>
            {extra ? (
              <>
                <div className="basis-1/2 font-semibold" data-testid="title">
                  {title}
                </div>
                <div className="basis-1/2 text-right" data-testid="extra">
                  {extra}
                </div>
              </>
            ) : (
              <div className="font-semibold" data-testid="title">
                {title}
              </div>
            )}
          </div>
        )}
        {children}
        {footer}
      </div>
    );
  }
);
