import React from 'react';

import { Badge } from 'components';
import { EvaluationStatus } from 'generated/graphql';

import { STATUS_BACKGROUND } from '../enums/statusBackground';

const STATUS_MAP: Record<EvaluationStatus, STATUS_BACKGROUND> = {
  [EvaluationStatus.Active]: STATUS_BACKGROUND.GREEN,
  [EvaluationStatus.Expired]: STATUS_BACKGROUND.RED,
  [EvaluationStatus.Failed]: STATUS_BACKGROUND.RED_LIGHT,
  [EvaluationStatus.Processing]: STATUS_BACKGROUND.PRIMARY,
};

interface EvaluationBadgeProps {
  status: EvaluationStatus;
  size?: 'sm' | 'md';
}

export const EvaluationBadge: React.FC<EvaluationBadgeProps> = ({
  status,
  size = 'md',
}) => {
  const color = STATUS_MAP[status];
  return <Badge size={size} label={status} color={color} />;
};
