import React from 'react';

import { Card } from 'components';
import { LoanSummaryQuery } from 'generated/graphql';
import { useTranslation } from 'react-i18next';

import { TwoColBodyCard } from 'components/Card/Content/TwoColBodyCard';
import { CustomisationConfig, useCustomisation } from 'modules/root/Settings';
import { ArrayElement } from 'utils/genericType';

import { useLoanAccountsFields } from './fields';

interface LoanAccountsProps {
  customer: LoanSummaryQuery['customer'];
}

interface AccountProps {
  loan: ArrayElement<LoanSummaryQuery['customer']['loans']>;
  collateralAccounts: LoanSummaryQuery['customer']['collateralAccounts'];
  config: CustomisationConfig;
}

const Account: React.FC<AccountProps> = ({ loan, config, collateralAccounts }) => {
  const loanAccountsFields = useLoanAccountsFields(config, loan, collateralAccounts);
  return <TwoColBodyCard items={loanAccountsFields} />;
};

export const LoanAccounts: React.FC<LoanAccountsProps> = ({ customer }) => {
  const config = useCustomisation();
  const { t } = useTranslation();
  return (
    <Card title={config.labels.loans} border={true} testid="loan-accounts">
      {customer.loans && customer.loans.length > 0 ? (
        <div className="flex flex-col gap-2 p-2 text-sm">
          {customer?.loans?.map((loan) => (
            <Account
              collateralAccounts={customer.collateralAccounts}
              config={config}
              loan={loan}
              key={loan?.id}
            />
          ))}
        </div>
      ) : (
        <div className="warning m-2" data-testid="no-loans-message">
          {t('customer.loans.accounts.noActiveLoans')}
        </div>
      )}
    </Card>
  );
};
