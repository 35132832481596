import React from 'react';

import clsx from 'clsx';

import { ListItem } from './ListBodyCard';

interface TwoColBodyCardProps {
  items: ListItem[];
}

interface ItemProps {
  item: ListItem;
}

const Item: React.FC<ItemProps> = ({ item }) => {
  return (
    <dl className="basis-1/3 grow py-2" data-testid={item.key}>
      <dt className="font-semibold text-gray-500" data-testid="label">
        {item.label}
      </dt>
      <dd className="font-normal text-gray-700" data-testid="value">
        {item.value}
      </dd>
    </dl>
  );
};

export const TwoColBodyCard: React.FC<TwoColBodyCardProps> = ({ items }) => {
  return (
    <div className={clsx('p-4', 'gap-x-2', 'flex', 'flex-wrap')}>
      {items.map((item: ListItem) => {
        return <Item key={item.key} item={item} />;
      })}
    </div>
  );
};
