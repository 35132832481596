import React from 'react';
import {
  Outlet,
  generatePath,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';

import clsx from 'clsx';
import { CustomerLoansQuery, useCustomerLoansQuery } from 'generated/graphql';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';
import { ArrayElement } from 'utils';

import { QuerySuspense } from 'modules/common/QuerySuspense/QuerySuspense';
import { useCustomisation, usePageTitle, usePaths } from 'modules/root/Settings';

import { LoanTabs } from './LoanTabs';

export type CustomerLoanItem = ArrayElement<CustomerLoansQuery['customer']['loans']>;
type LoansContext = {
  clientNumberOfLoans: number;
};

export const Loans: React.FC = () => {
  const { labels } = useCustomisation();
  const [loan, setLoan] = React.useState<CustomerLoanItem>(null);
  const { customerId } = useParams();
  const navigate = useNavigate();
  const paths = usePaths();

  const location = useLocation();

  const { data, error, isLoading } = useQueryFetch(useCustomerLoansQuery, {
    queryHookParams: { customerID: customerId || '' },
  });
  const clientNumberOfLoans = data?.customer.loans?.length || 0;
  const customer = data?.customer;

  usePageTitle([customer?.displayName || '', labels.loans]);

  React.useEffect(() => {
    if (customer?.loans && clientNumberOfLoans === 1) {
      setLoan(customer.loans[0]);
    }
  }, [data, customer, clientNumberOfLoans]);

  React.useEffect(() => {
    if (clientNumberOfLoans === 1 && loan) {
      const path = generatePath(paths.customer.loans.details, {
        customerId,
        loanId: loan.id,
      });
      if (location.pathname !== path) {
        navigate(path, { replace: true });
      }
    }
  }, [clientNumberOfLoans, loan, customerId, navigate, paths, location.pathname]);

  return (
    <QuerySuspense error={error} isLoading={isLoading} noData={!data?.customer}>
      <div className={clsx('mx-8 lg:mx-12 xl:mx-16', 'mt-6', 'mb-14')}>
        <LoanTabs loans={data?.customer.loans} />
        <Outlet
          context={{
            clientNumberOfLoans,
          }}
        />
      </div>
    </QuerySuspense>
  );
};

export const useLoansContext = () => {
  return useOutletContext<LoansContext>();
};
