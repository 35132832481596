import React, { ReactNode } from 'react';

import clsx from 'clsx';

export interface ReactTableCellProps {
  children?: string | JSX.Element | JSX.Element[] | ReactNode;
  'data-testid'?: string;
}

export const ReactTableCell = ({
  children,
  'data-testid': testid,
}: ReactTableCellProps) => {
  return (
    <td
      className={clsx(
        'max-w-[400px] overflow-hidden',
        'px-3 py-4',
        'bg-white nontouchable:group-hover:bg-primary-400/20',
        'border-y border-gray-100',
        'first:rounded-l-lg first:border-l',
        'last:rounded-r-lg last:border-r'
      )}
      data-testid={testid}
    >
      {children}
    </td>
  );
};
