import fetch from 'cross-fetch';
import { GraphQLClient } from 'graphql-request';

import { AuthError } from 'modules/common/errors/AuthError';
import { SCHEMA_HOST } from 'modules/root/Settings/envVars';
import { useAuth } from 'modules/root/auth/auth.context';

const PATH = '/navigator';
const SCHEMA_URL = SCHEMA_HOST ? `${SCHEMA_HOST}${PATH}` : `http://127.0.0.1:4000${PATH}`;

export const useAuthClient = (client: GraphQLClient, withAUth: boolean = true) => {
  const { idToken } = useAuth();
  if (!idToken && withAUth) {
    throw new AuthError('idToken is not provided in useQueryFetch');
  }
  client.setHeaders({
    Authorization: `Bearer ${idToken}`,
  });
  return client;
};

export const getUniqueUrl = (url: string, body?: BodyInit | null) => {
  let param, val;
  try {
    let regex = /"query":"[^"]*?(\w+)\s+(\w+)/;
    if (typeof body === 'string') {
      const cleanedBody = body.replace(/\\n/g, '');
      const match = cleanedBody.match(regex);
      param = match?.[1];
      val = match?.[2];
    }
  } catch {
    // do nothing
  }
  const urlObj = new URL(url);
  if (param && val) {
    urlObj.searchParams.append(param, val);
  }
  return urlObj.toString();
};

export const graphqlClient = new GraphQLClient(SCHEMA_URL, {
  fetch: (url: string, options: RequestInit) => {
    const newUrl = getUniqueUrl(url, options.body);
    return fetch(newUrl, options);
  },
});
