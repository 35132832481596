import React from 'react';

export interface Branding {
  sideMenuLogo: string;
  loginLogo: string;
  footerLogo: string;
  owner: string;
}

export const BrandingContext = React.createContext<Branding>({} as Branding);

export const useBranding = (): Branding => React.useContext(BrandingContext);
