import { PartiesQuery } from 'generated/graphql';
import { cloneDeep as _cloneDeep } from 'lodash';

import { PartiesState } from './Parties';

export const isLegalEntity = (entity: {
  governmentFederalTaxId?: string | null;
}): boolean => {
  return !!entity?.governmentFederalTaxId;
};

export const partiesTransform = (
  loanParties: PartiesQuery['loanParties']
): PartiesState => {
  const emtpyParties = { coBorrowers: [], guarantors: [], accountOwnerDetails: [] };

  return loanParties.reduce<PartiesState>((acc, curr) => {
    if (curr?.relationship === 'PRIMARY_BORROWER') {
      acc.borrower = curr;
    } else if (curr?.relationship === 'CO_BORROWER') {
      acc.coBorrowers.push(curr);
    } else if (curr?.relationship === 'GUARANTOR') {
      acc.guarantors.push(curr);
    } else if (curr?.relationship === 'ACCOUNT_OWNER') {
      acc.accountOwnerDetails.push(curr);
    }
    return acc;
  }, _cloneDeep(emtpyParties));
};
