import { LoanStatus } from 'generated/graphql';
import { t } from 'i18next';

import { money } from 'utils/currency';
import { DATE_FORMAT, formatDate, formatDateWithoutTimezone } from 'utils/date';

export const EMPTY_DASH = '—';

export const printPrimitive = (value: null | undefined | string | number): string => {
  if (value === null || typeof value === 'undefined') {
    return EMPTY_DASH;
  }
  if (typeof value === 'string' && !value.trim()) {
    return EMPTY_DASH;
  }
  return value.toString();
};

export const printMoneyInLetters = (value: null | undefined | number): string => {
  let formatted;
  if (typeof value === 'number') {
    formatted = money.formatInLetters(value);
  }
  return formatted || printPrimitive(value);
};

export const printMoney = (value: null | undefined | number): string => {
  let formatted;
  if (typeof value === 'number') {
    formatted = money.format(value);
  }
  return formatted || printPrimitive(value);
};

export const printPercent = (value: null | undefined | number): string => {
  if (typeof value === 'number') {
    return `${money.toFixed(value)}%`;
  }
  return printPrimitive(value);
};

export const printDate = (value: null | undefined | string): string => {
  if (value) {
    return formatDate(value, DATE_FORMAT);
  }
  return printPrimitive(value);
};

export const printDateWithoutTimezone = (value: null | undefined | string): string => {
  if (value) {
    return formatDateWithoutTimezone(value, DATE_FORMAT);
  }
  return printPrimitive(value);
};

export const printBoolean = (value: null | undefined | boolean): string => {
  if (typeof value === 'boolean') {
    return value ? t('common.yesShortcut') : t('common.noShortcut');
  }
  return EMPTY_DASH;
};
export const printBooleanFull = (value: null | undefined | boolean): string => {
  if (typeof value === 'boolean') {
    return value ? t('common.yes') : t('common.no');
  }
  return EMPTY_DASH;
};
type StatusArg = LoanStatus | null | undefined;

export const printForStatus = (
  value: any,
  showDashOnDataIssue: boolean | null | undefined,
  status: StatusArg
) => {
  if (showDashOnDataIssue && status === LoanStatus.DataIssue) {
    return EMPTY_DASH;
  }
  return value;
};

type PrintForAllStatuses = <T>(
  value: T,
  showDashOnDataIssue: boolean | null | undefined,
  statuses: StatusArg[]
) => T | typeof EMPTY_DASH;

export const printForAllStatuses: PrintForAllStatuses = (
  value,
  showDashOnDataIssue,
  statuses
) => {
  const isEveryStatusIsDataIssued =
    statuses.length > 0
      ? statuses.every((status) => status === LoanStatus.DataIssue)
      : false;
  if (showDashOnDataIssue && isEveryStatusIsDataIssued) {
    return EMPTY_DASH;
  }
  return value;
};
