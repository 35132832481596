import { useAtomValue } from 'jotai';
import { isEqual } from 'lodash';

import { selAccountIdsAtom } from '../models/account';
import { creditPolicyIdAtom } from '../models/creditPolicy';
import { payloadSnapshotAtom } from '../models/evaluation';

export const useInputState = () => {
  const creditPolicyId = useAtomValue(creditPolicyIdAtom);
  const selAccountIds = useAtomValue(selAccountIdsAtom);
  const payloadSnapshot = useAtomValue(payloadSnapshotAtom);

  // This is the review control for updating the evaluation
  const isValid = !!(selAccountIds.size && creditPolicyId);

  const ids1 = payloadSnapshot?.evaluationCollateralAccounts
    .map((account) => account?.externalAccountId)
    .sort();
  const ids2 = Array.from(selAccountIds).sort();
  const isDirty =
    payloadSnapshot?.creditPolicyId !== creditPolicyId || !isEqual(ids1, ids2);

  return { isValid, isDirty };
};
