import React from 'react';
import { useParams } from 'react-router';

import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { FontIcon } from 'components';
import { ActivityAuditType, LoanStatus, useLoanSummaryQuery } from 'generated/graphql';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';
import { useTranslation } from 'react-i18next';

import { QuerySuspense } from 'modules/common/QuerySuspense/QuerySuspense';
import { useCustomisation } from 'modules/root/Settings';

import { LoanSection } from '../LoanSection/LoanSection';
import { LoanAccounts } from './LoanAccounts';
import {
  usePledgedAccountSummary,
  useSummaryFields,
  useSummaryOpportunityFields,
} from './fields';

interface Props {}

const loanColClass = clsx('flex', 'flex-col', 'basis-1/3', 'grow', 'gap-4');

export const LoanSummary: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { customerId } = useParams();
  const { data, error, isLoading } = useQueryFetch(useLoanSummaryQuery, {
    queryHookParams: { customerID: customerId! },
    extra: {
      auditReport: { activityType: ActivityAuditType.Read },
    },
  });
  const customer = data?.customer;
  const loanStatus = customer?.serviceStatus?.status;
  const {
    labels,
    portfolioSummary: loanSummaryConfig,
    portfolioOpportinity: opportunityConfig,
    pledgedAccountsSummary: pledgedAccountsSummaryConfig,
    loanAccounts: loanAccountsConfig,
  } = useCustomisation();
  const config = useCustomisation();
  const isCrossCollateralised =
    data?.customer.loans?.some((loan) => loan?.isCrossCollateralised) || false;
  const isSomeLoanIsDataIssued = customer?.loans?.some(
    (loan) => loan?.status === LoanStatus.DataIssue
  );

  const summaryFields = useSummaryFields(config, customer);

  const opportunityFieds = useSummaryOpportunityFields(
    config,
    customer,
    loanStatus,
    isCrossCollateralised
  );

  const pledgedAccountsSummaryFieds = usePledgedAccountSummary(
    config,
    customer,
    isCrossCollateralised
  );

  const showOpportunity =
    customer?.collateralAccounts &&
    customer.collateralAccounts.length > 0 &&
    loanStatus !== LoanStatus.SellOut &&
    loanStatus !== LoanStatus.TopUp &&
    loanStatus !== LoanStatus.OverAdvanced;

  return (
    <QuerySuspense error={error} isLoading={isLoading} noData={!data?.customer.loans}>
      {isSomeLoanIsDataIssued && (
        <div className="warning my-5 text-center" data-testid="data-issue-message">
          <FontIcon size="lg" icon={faTriangleExclamation} className="mr-2" />
          {t('customer.loans.dataIssueWarning')}
        </div>
      )}
      <div
        className={clsx(
          'flex',
          'flex-col lg:flex-row',
          'w-full',
          'lg:flex-wrap',
          'gap-4',
          'mt-5'
        )}
        data-testid="loan-summary-container"
      >
        <div className={loanColClass}>
          {loanSummaryConfig.show && summaryFields.length > 0 && (
            <LoanSection
              title={labels.portfolioSummary}
              fields={summaryFields}
              testid="loan-summary"
            />
          )}
          {opportunityConfig.show && opportunityFieds.length > 0 && showOpportunity && (
            <LoanSection
              title={labels.opportunity}
              fields={opportunityFieds}
              testid="portfolio-opportinity"
            />
          )}
          {pledgedAccountsSummaryConfig.show &&
            pledgedAccountsSummaryFieds.length > 0 && (
              <LoanSection
                title={labels.pledgedAccountsSummary}
                fields={pledgedAccountsSummaryFieds}
                testid="pledged-accounts-summary"
              />
            )}
        </div>
        <div className={loanColClass}>
          {loanAccountsConfig.show && customer && <LoanAccounts customer={customer} />}
        </div>
      </div>
    </QuerySuspense>
  );
};
