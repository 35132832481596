/* eslint-disable i18next/no-literal-string */
import { ApplicationConfig, LabelsConfig } from 'generated/graphql';
import Highcharts from 'highcharts';
import { TFunction } from 'i18next';

import { palette, visualization } from 'modules/root/theme/visualization';
import { money } from 'utils/currency';

import { ASSET_TYPES, ASSET_TYPE_TITLES, AssetTotal, isAssetType } from './assetTotal';

export const getOptions = (
  labels: ApplicationConfig['labels'],
  assetTotal: AssetTotal,
  t: TFunction
): Highcharts.Options => {
  const data: Highcharts.PointOptionsObject[] = [];

  ASSET_TYPES.forEach((assetType, index) => {
    if (assetType !== 'ineligibles' || assetTotal[assetType] > 0) {
      data.push({
        name: assetType,
        y: assetTotal[assetType] || null,
        dataLabels: {
          y: 7 - 10 * index,
        },
      });
    }
  });
  return {
    credits: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    chart: {
      backgroundColor: 'transparent',
      plotBorderWidth: 0,
      plotShadow: false,
      height: 600,
      width: 478,
    },
    colors: [
      visualization.optionA.colorE,
      visualization.optionC.colorB,
      visualization.optionC.colorC,
      visualization.optionC.colorD,
      visualization.optionC.colorE,
      visualization.optionD.colorA,
      visualization.optionD.colorC,
      visualization.optionD.colorD,
      visualization.optionD.colorE,
    ],
    plotOptions: {
      pie: {
        showInLegend: true,
        dataLabels: {
          verticalAlign: 'top',
          enabled: true,
          color: palette.white,
          distance: -30,
          padding: 20,
          style: {
            textOutline: '0px',
            fontSize: '12px',
            fontWeight: 'normal',
          },
          formatter() {
            if (this.percentage) {
              const percentage = this.percentage < 0.01 ? 0.01 : this.percentage;
              return percentage < 1
                ? `${+percentage.toFixed(2)}%`
                : `${Math.round(percentage)}%`;
            }
            return '';
          },
        },
        states: {
          inactive: {
            opacity: 1,
          },
          hover: {
            enabled: false,
          },
        },
        point: {
          events: {
            legendItemClick: (e) => e.preventDefault(),
          },
        },
        size: 314,
        borderWidth: 1,
        borderColor: '',
      },
    },
    title: {
      text: '',
    },
    series: [
      {
        type: 'pie',
        innerSize: 197,
        data,
      },
    ],
    legend: {
      y: 40,
      itemWidth: 170,
      itemMarginBottom: 22,
      itemDistance: 0,
      labelFormatter() {
        const point = this as Highcharts.Point;

        if (typeof point.y !== 'undefined' && isAssetType(point.name)) {
          return `<span class="block ml-3 mt-[-2px] text-gray-500 text-sm">${
            labels[ASSET_TYPE_TITLES[point.name] as keyof LabelsConfig]
          }</span><span class="block font-bold text-sm">${money.format(
            point.y || 0
          )}</span>`;
        }
        return '';
      },
      useHTML: true,
      align: 'center',
      verticalAlign: 'bottom',
      itemStyle: {
        cursor: 'default',
        fontWeight: 'normal',
        fontSize: '12px',
        color: palette.gray700,
      },
      itemHoverStyle: {
        color: palette.gray700,
      },
      squareSymbol: true,
      symbolHeight: 10,
      symbolRadius: 5,
      symbolPadding: -10,
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
  };
};
