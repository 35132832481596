import { gql } from 'graphql-request';

export const auditReportMutation = gql`
  mutation auditReport(
    $activityType: ActivityAuditType!
    $resourceAccessed: String
    $customerExternalId: String
    $queryDetails: String
  ) {
    triggerActivityAudit(
      activityType: $activityType
      resourceAccessed: $resourceAccessed
      customerExternalId: $customerExternalId
      queryDetails: $queryDetails
    )
  }
`;
