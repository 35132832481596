import React from 'react';
import { generatePath, useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { FontIcon } from 'components';
import { CustomerLoansQuery } from 'generated/graphql';

import { useCustomisation, usePaths } from 'modules/root/Settings';
import { getShortener, useScreenWidth } from 'utils/helpers';

interface Props {
  loans: CustomerLoansQuery['customer']['loans'];
}

enum Direction {
  'Left',
  'Right',
}

const defaultClass = clsx([
  'bg-main-secondary',
  'rounded-lg',
  'm-1',
  'p-4',
  'text-xs xl:text-sm',
]);
const activeClass = clsx([
  'bg-main-secondary',
  'border',
  'border-solid',
  'border-gray-400',
]);

export const LoanTabs: React.FC<Props> = ({ loans }) => {
  const paths = usePaths();
  const { customerId, loanId } = useParams();
  const config = useCustomisation();
  const screenwidth = useScreenWidth();

  let loanTabsCount: number;

  if (screenwidth > 1024) {
    loanTabsCount = 3;
  } else if (screenwidth >= 768) {
    loanTabsCount = 2;
  } else {
    loanTabsCount = 1;
  }

  const [start, setStart] = React.useState(0);

  const shortNum = React.useMemo(() => {
    if (screenwidth < 820) {
      if (loanTabsCount >= 2) {
        return getShortener(8);
      } else {
        return getShortener(12);
      }
    }
    return getShortener(36);
  }, [loanTabsCount, screenwidth]);

  const handleArrowClick = (loansLength: number, direction: Direction) => {
    return () => {
      if (direction === Direction.Left) {
        setStart(start - loanTabsCount);
      } else {
        const restCount = loansLength - start - loanTabsCount;
        const newCount = restCount >= loanTabsCount ? loanTabsCount : restCount;
        setStart(start + newCount);
      }
    };
  };
  return (
    <div className={clsx('flex', 'flex-row', 'justify-between', 'items-center', 'h-14')}>
      <div className="flex flex-nowrap items-center">
        {loans && loans.length > loanTabsCount ? (
          <FontIcon
            aria-label="<"
            size="lg"
            onClick={handleArrowClick(loans.length, Direction.Left)}
            testid="left-btn"
            icon={faCaretLeft}
            disabled={start === 0}
            className={clsx(
              start === 0 ? 'text-gray-100' : 'text-primary-600',
              'cursor-pointer',
              'select-none'
            )}
          />
        ) : null}
        {loans?.slice(start, start + loanTabsCount).map((loan, idx) => {
          return (
            loan && (
              <Link
                data-testid="loan-btn"
                key={loan.id}
                to={generatePath(paths.customer.loans.details, {
                  customerId,
                  loanId: loan.id,
                })}
                className={clsx(defaultClass, {
                  [activeClass]: loan?.id === loanId,
                })}
              >
                <span className="text-disabled">{config.labels.loan}</span>
                <span className="ml-1 text-disabled">{idx + start + 1}</span>
                <span className="mx-1 text-disabled">{'•'}</span>
                <span className="text-primary-600">
                  {shortNum(loan.obligor)}-{shortNum(loan.obligation)}
                </span>
              </Link>
            )
          );
        })}
        {loans && loans.length > loanTabsCount ? (
          <FontIcon
            aria-label=">"
            size="lg"
            onClick={handleArrowClick(loans.length, Direction.Right)}
            testid="right-btn"
            icon={faCaretRight}
            disabled={start + loanTabsCount === loans?.length}
            className={clsx(
              start + loanTabsCount === loans?.length
                ? 'text-gray-100'
                : 'text-primary-600',
              'cursor-pointer',
              'select-none'
            )}
          />
        ) : null}
      </div>
      {loanId && loans && loans.length > 1 && (
        <div>
          <Link to={generatePath(paths.customer.loans.main, { customerId })}>
            <span className="text-xs xl:text-sm text-primary-600">
              {config.labels.backToSummary}
            </span>
          </Link>
        </div>
      )}
    </div>
  );
};
