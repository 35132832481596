import React, { PropsWithChildren } from 'react';

import {
  ColumnDef,
  ExpandedState,
  PaginationState,
  Row,
  SortingState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import { Pagination } from 'components';

import { useIsTouchDevice } from 'utils/helpers';

import { ReactTableBody } from './ReactTableBody';
import { ReactTableCell } from './ReactTableCell';
import { ReactTableHeader } from './ReactTableHeader';
import { ReactTableHeaderCell } from './ReactTableHeaderCell';
import { ReactTableHeaderRow } from './ReactTableHeaderRow';
import { ReactTableRow } from './ReactTableRow';

export type PageChangeFn = (pageState: PaginationState) => void;
export type SortChangeFn = (sortState: SortingState) => void;
export interface TableProps<T extends {}> {
  data: T[];
  columns: ColumnDef<T, any>[];
  onClick?: (row: Row<T>) => void;
  pagination?: {
    total: number;
    state: PaginationState;
    onChange: PageChangeFn;
  };
  sorting?: {
    state: SortingState;
    onChange: SortChangeFn;
  };
  'data-testid'?: string;
  className?: string;
  stickyFromTopPx?: number;
  renderSubComponent?: (row: Row<T>) => React.ReactElement;
  getRowCanExpand?: (row: Row<T>) => boolean;
  expandedByDefault?: ExpandedState;
}

export const ReactTable = <T extends {}>({
  data,
  columns,
  onClick,
  pagination,
  sorting,
  className,
  stickyFromTopPx,
  renderSubComponent,
  getRowCanExpand,
  'data-testid': testid,
  expandedByDefault,
}: PropsWithChildren<TableProps<T>>) => {
  const dataMemo = React.useMemo(() => data, [data]);
  const columnsMemo = React.useMemo(() => columns, [columns]);

  const [tablePagination, setTablePagination] = React.useState<PaginationState>({
    pageIndex: pagination?.state.pageIndex || 0,
    pageSize: pagination?.state.pageSize || 0,
  });
  const [tableSorting, setTableSorting] = React.useState<SortingState>(
    sorting?.state || []
  );

  React.useEffect(() => {
    if (
      sorting &&
      sorting.state &&
      JSON.stringify(sorting.state) !== JSON.stringify(tableSorting)
    ) {
      setTablePagination({ ...tablePagination, pageIndex: 0 });
      sorting?.onChange(tableSorting);
    }
  }, [tableSorting, sorting, tablePagination]);

  const onChangePagination = pagination?.onChange;

  React.useEffect(() => {
    if (onChangePagination) {
      onChangePagination(tablePagination);
    }
  }, [tablePagination, onChangePagination]);

  const tableInstance = useReactTable({
    data: dataMemo,
    columns: columnsMemo,
    getCoreRowModel: getCoreRowModel(),
    getRowCanExpand,
    manualPagination: true,
    manualSorting: true,
    onPaginationChange: setTablePagination,
    onSortingChange: setTableSorting,
    enableSorting: Boolean(sorting),
    enableMultiSort: false,
    state: {
      pagination: tablePagination,
      sorting: tableSorting,
    },
    initialState: {
      expanded: expandedByDefault,
    },
  });
  const isTouchDevice = useIsTouchDevice();

  return (
    <>
      <div className={clsx(className)}>
        {/* Offset from top */}
        {typeof stickyFromTopPx === 'number' && (
          <div
            className="bg-white h-4 sticky z-5"
            style={{ top: `${isTouchDevice ? 0 : stickyFromTopPx}px` }}
          ></div>
        )}
        <div
          className={clsx(
            'max-w-[calc(100vw-148px)] lg:max-w-[calc(100vw-198px)] xl:max-w-[1500px]',
            { 'overflow-x-auto max-h-[calc(100vh-258px)]': isTouchDevice }
          )}
        >
          <table
            className="w-full border-separate border-spacing-x-0 border-spacing-y-2 text-xs lg:text-base"
            data-testid={testid}
          >
            <ReactTableHeader data-testid={`${testid}-header`}>
              {tableInstance.getHeaderGroups().map((headerGroup) => {
                return (
                  <ReactTableHeaderRow
                    key={headerGroup.id}
                    data-testid={`${testid}-header-row`}
                  >
                    {headerGroup.headers.map((header) => {
                      return (
                        <ReactTableHeaderCell
                          key={header.id}
                          colSpan={header.colSpan}
                          column={header.column}
                          data-testid={`${testid}-header-cell`}
                          stickyFromTopPx={isTouchDevice ? -10 : stickyFromTopPx}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </ReactTableHeaderCell>
                      );
                    })}
                  </ReactTableHeaderRow>
                );
              })}
            </ReactTableHeader>
            <ReactTableBody data-testid={`${testid}-body`}>
              {tableInstance.getRowModel().rows.map((row) => {
                const onRowClick = () => onClick?.(row);
                return (
                  <React.Fragment key={row.id}>
                    <ReactTableRow
                      onClick={onClick ? onRowClick : undefined}
                      data-testid={`${testid}-row`}
                    >
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <ReactTableCell key={cell.id} data-testid={`${testid}-cell`}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </ReactTableCell>
                        );
                      })}
                    </ReactTableRow>
                    {row.getIsExpanded() && (
                      <tr>
                        <td colSpan={row.getVisibleCells().length}>
                          {renderSubComponent && renderSubComponent(row)}
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })}
            </ReactTableBody>
          </table>
        </div>
      </div>
      {pagination && (
        <Pagination
          className="mt-8"
          pageRange={5}
          totalRows={pagination.total}
          rowsPerPage={pagination.state.pageSize}
          currentPage={pagination.state.pageIndex + 1}
          nextPage={tableInstance.nextPage}
          previousPage={tableInstance.previousPage}
          onChange={(page) => tableInstance.setPageIndex(page - 1)}
        />
      )}
    </>
  );
};
