import React from 'react';

import { Card, ListBodyCard, ListItem } from 'components';

interface LoanSectionProps {
  title: string;
  testid: string;
  fields: ListItem[];
}

export const LoanSection: React.FC<LoanSectionProps> = ({ title, fields, testid }) => {
  return (
    <Card title={title} border={true} testid={testid}>
      <ListBodyCard rows={fields} />
    </Card>
  );
};
