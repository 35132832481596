import React, { PropsWithChildren } from 'react';
import { useParams } from 'react-router';

import { ActivityAuditType, useCustomerDetailsQuery } from 'generated/graphql';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';

import MaskCopyProhibitedText from 'components/MaskCopyProhibitedText/MaskCopyProhibitedText';
import { QuerySuspense } from 'modules/common/QuerySuspense/QuerySuspense';
import {
  CustomisationConfig,
  useCustomisation,
  usePageTitle,
} from 'modules/root/Settings';
import { EMPTY_DASH, printDate, printPrimitive } from 'utils/print';

import { useCustomerProfileContext } from '../../CustomerProfile';

type DetailItemProps = PropsWithChildren<{
  label: string;
  show: boolean;
  testid: string;
}>;

const DetailItem: React.FC<DetailItemProps> = ({ label, show, children, testid }) => {
  if (!show) {
    return null;
  }
  return (
    <dl className="basis-1/2 lg:basis-1/3 pr-6 mb-6" data-testid={testid}>
      <dt className="label mb-1">{label}</dt>
      <dd className="detail font-semibold">{children}</dd>
    </dl>
  );
};

const resolveConfig = (
  config: CustomisationConfig['customerDetailsV2']['showFields'],
  labels: CustomisationConfig['labels'],
  isLegalEntity: boolean
) => {
  return {
    title: isLegalEntity
      ? { show: false, label: '' }
      : { show: config.title, label: labels.title },
    name: isLegalEntity
      ? { show: config.legalEntityName, label: labels.legalEntityName }
      : { show: config.name, label: labels.name },
    firstName: isLegalEntity
      ? { show: false, label: '' }
      : { show: config.firstName, label: labels.firstName },
    middleName: isLegalEntity
      ? { show: false, label: '' }
      : { show: config.middleName, label: labels.middleName },
    middleInitial: isLegalEntity
      ? { show: false, label: '' }
      : { show: config.middleInitial, label: labels.middleInitial },
    lastName: isLegalEntity
      ? { show: false, label: '' }
      : { show: config.lastName, label: labels.lastName },
    legalEntityType: isLegalEntity
      ? { show: config.legalEntityType, label: labels.legalEntityType }
      : { show: false, label: '' },
    address: isLegalEntity
      ? {
          show: config.legalEntityRegisteredAddress,
          label: labels.legalEntityRegisteredAddress,
        }
      : { show: config.address, label: labels.address },
    govId: isLegalEntity
      ? { show: config.governmentFederalTaxId, label: labels.governmentFederalTaxId }
      : { show: config.govId, label: labels.govId },
    dateOfBirth: isLegalEntity
      ? { show: false, label: '' }
      : { show: config.dateOfBirth, label: labels.dateOfBirth },
    city: { show: config.city, label: labels.city },
    state: { show: config.state, label: labels.state },
    zip: { show: config.zip, label: labels.zip },
    phone: { show: config.phone, label: labels.phone },
    email: { show: config.email, label: labels.email },
    extraInformation: { show: config.extraInformation, label: labels.extraInformation },
  };
};

export const CustomerDetails: React.FC = () => {
  const { customerDetailsV2, labels } = useCustomisation();
  const { customerName } = useCustomerProfileContext();
  usePageTitle([customerName, labels.borrowerDetails]);
  const { customerId } = useParams();
  const { data, error, isLoading } = useQueryFetch(useCustomerDetailsQuery, {
    queryHookParams: { customerID: customerId! },
    extra: {
      auditReport: { activityType: ActivityAuditType.Read },
    },
  });
  const customer = data?.customer;
  const metadata = customer?.metadata;
  const isLegalEntity = !!customer?.governmentFederalTaxId;
  const fields = resolveConfig(customerDetailsV2.showFields, labels, isLegalEntity);
  return (
    <div className="mx-8 lg:mx-20 my-6 lg:my-14">
      <h2 data-testid="details-header">{labels.borrowerDetails}</h2>
      <QuerySuspense error={error} isLoading={isLoading} noData={!customer?.id}>
        <div className="flex flex-row flex-wrap justify-between mb-12">
          <DetailItem testid={'title'} {...fields.title}>
            {printPrimitive(customer?.title)}
          </DetailItem>
          <DetailItem testid={'name'} {...fields.name}>
            {printPrimitive(customer?.displayName)}
          </DetailItem>
          <DetailItem testid={'type'} {...fields.legalEntityType}>
            {printPrimitive(customer?.type)}
          </DetailItem>
          <DetailItem testid={'first-name'} {...fields.firstName}>
            {printPrimitive(customer?.firstName)}
          </DetailItem>
          <DetailItem testid={'middle-name'} {...fields.middleName}>
            {printPrimitive(customer?.middleName)}
          </DetailItem>
          <DetailItem testid={'middle-initial'} {...fields.middleInitial}>
            {printPrimitive(customer?.middleInitial)}
          </DetailItem>
          <DetailItem testid={'last-name'} {...fields.lastName}>
            {printPrimitive(customer?.lastName)}
          </DetailItem>
          <DetailItem testid={'address'} {...fields.address}>
            <>
              <div>{printPrimitive(customer?.addressLine1)}</div>
              {customer?.addressLine2 && <div>{customer?.addressLine2}</div>}
            </>
          </DetailItem>
          <DetailItem testid={'city'} {...fields.city}>
            {printPrimitive(customer?.city)}
          </DetailItem>
          <DetailItem testid={'state'} {...fields.state}>
            {printPrimitive(customer?.state)}
          </DetailItem>
          <DetailItem testid={'zip'} {...fields.zip}>
            {printPrimitive(customer?.zip)}
          </DetailItem>
          <DetailItem testid={'gov-id'} {...fields.govId}>
            {customer?.governmentFederalTaxId || customer?.govId ? (
              <MaskCopyProhibitedText
                text={
                  (isLegalEntity ? customer?.governmentFederalTaxId : customer?.govId) ||
                  ''
                }
              />
            ) : (
              EMPTY_DASH
            )}
          </DetailItem>
          <DetailItem testid={'date-of-birth'} {...fields.dateOfBirth}>
            {printDate(customer?.dateOfBirth)}
          </DetailItem>
          <DetailItem testid={'phone'} {...fields.phone}>
            {printPrimitive(customer?.phone)}
          </DetailItem>
          <DetailItem testid={'email'} {...fields.email}>
            {printPrimitive(customer?.email)}
          </DetailItem>
        </div>
        {metadata && metadata.length > 0 && fields.extraInformation && (
          <>
            <h2 data-testid="details-extra-header">{labels.extraInformation}</h2>
            <div className="flex flex-row flex-wrap gap-6">
              {metadata?.map(
                (md, idx) =>
                  md && (
                    <DetailItem
                      testid={`extra-${md.fieldType}-${idx + 1}`}
                      show={true}
                      label={md.fieldName}
                    >
                      {printPrimitive(md.fieldValue)}
                    </DetailItem>
                  )
              )}
            </div>
          </>
        )}
      </QuerySuspense>
    </div>
  );
};
